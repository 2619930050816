import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField
} from '@mui/material';
import { FileUploader } from 'components/FileUploader';
import { If } from 'components/If';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import {
  OrganizationType,
  OrganizationTypeLabels
} from 'enums/OrganizationType.enum';

import styles from './CreateOrganizationDetails.module.scss';

const organizationTypes = Object.values(OrganizationType).map((value) => ({
  value,
  label: OrganizationTypeLabels[value]
}));

export const CreateOrganizationDetails: FC = () => {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <>
      <Controller
        control={control}
        name="image"
        render={({ field: { onChange, value } }) => {
          return (
            <FileUploader
              fileData={value}
              onChangeFile={onChange}
              label="Organization photo"
            />
          );
        }}
      />
      <div className={styles['create-organization-details']}>
        <Controller
          rules={{ required: true }}
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              type="text"
              variant="outlined"
              margin="normal"
              inputRef={field.ref}
              error={!!errors?.name}
              label="Organization name"
              aria-invalid={!!errors?.name}
              inputProps={{ maxLength: 30 }}
              helperText={errors?.name && ErrorMessages.FailedRequiredField}
              id="outlined-organization-name-input"
            />
          )}
        />
        <Controller
          rules={{ required: true }}
          name="address"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              type="text"
              label="Address"
              margin="normal"
              variant="outlined"
              error={!!errors?.address}
              id="outlined-address-input"
              aria-invalid={!!errors?.address}
              helperText={errors?.address && ErrorMessages.FailedRequiredField}
            />
          )}
        />
        <Controller
          name="type"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <FormControl className={styles.form} error={!!errors?.type}>
              <InputLabel htmlFor="organization-type-outlined-select-label">
                Type of organization
              </InputLabel>
              <Select
                {...field}
                data-testid="organization-type-select"
                input={
                  <OutlinedInput
                    label="Type of organization"
                    id="organization-type-outlined-select-label"
                  />
                }
              >
                {organizationTypes.map(({ value, label }) => (
                  <MenuItem
                    key={value}
                    value={value}
                    data-testid="organization-type-option"
                  >
                    {label}
                  </MenuItem>
                ))}
              </Select>
              <If condition={!!errors?.type}>
                <FormHelperText>
                  {ErrorMessages.FailedRequiredField}
                </FormHelperText>
              </If>
            </FormControl>
          )}
        />
        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              multiline
              minRows={2}
              maxRows={4}
              type="text"
              margin="normal"
              variant="outlined"
              label="Description"
              aria-label="Description"
              inputProps={{ maxLength: 500 }}
              id="outlined-description-input"
            />
          )}
        />
      </div>
    </>
  );
};
