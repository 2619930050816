import * as React from 'react';
import { FC, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { useHistory } from 'react-router-dom';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import { LoadingButton } from '@mui/lab';
import { Box, Grid, TextField } from '@mui/material';
import { fetchEmailFromToken, verifyEmail } from 'actions/User/userActions';
import logo from 'assets/logo.svg';
import mainLogo from 'assets/main-logo.svg';
import { If } from 'components/If';
import { InfoMessage, InfoMessageTheme } from 'components/InfoMessage';
import { ApiError } from 'entities/ApiError.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import * as queryString from 'query-string';
import { ACCESS_TOKEN_EXPIRATION } from 'utils/constants';
import { isUsernameValid } from 'utils/helpers/validators';

import styles from './VerifyEmailPage.module.scss';

export const VerifyEmailPage: FC = () => {
  const { location } = useHistory();

  const query = queryString.parse(location.search);

  const {
    error: fetchingError,
    isFetching,
    data: email
  } = useQuery<string, ApiError>(
    queryKeys.emailByToken(query.token as string),
    () => fetchEmailFromToken(query.token as string),
    {
      retry: 0,
      staleTime: ACCESS_TOKEN_EXPIRATION
    }
  );

  const [username, setUsername] = useState<string>('');
  const [usernameError, setUsernameError] = useState<string>('');
  const [isTyping, setIsTyping] = useState<boolean>(false);

  const { error, mutateAsync, isLoading, isSuccess } = useMutation<
    void,
    ApiError,
    {
      token: string;
      username: string;
    }
  >(verifyEmail);

  const validateUsername = () => {
    let errorMessage = '';

    if (!username) {
      errorMessage = ErrorMessages.FailedRequiredField;
    }
    if (!isUsernameValid(username)) {
      errorMessage = ErrorMessages.NotValidUsername;
    }

    setUsernameError(errorMessage);
  };

  return (
    <div className={styles.layout}>
      <header className={styles['navigation-header']}>
        <img
          src={mainLogo}
          alt="avail logo"
          data-testid="logo"
          className={styles.logo}
        />
      </header>
      <Grid
        container
        sx={{ pl: 2, pr: 2 }}
        direction="column"
        alignItems="center"
        justifyContent="center"
      >
        <Grid
          item
          className={styles['verify-email-page']}
          style={{ minWidth: '25rem', maxWidth: '25rem' }}
          sx={{ mb: 10, mt: 8 }}
        >
          <Box className={styles['logo-page']}>
            <img src={logo} alt="avail logo" />
          </Box>
          <If condition={!fetchingError && !isFetching && !isSuccess}>
            <p>
              Please enter your avail app username to validate that this is your
              correct email address:
            </p>
            <Box
              component="form"
              display="inline"
              flexDirection="column"
              className={styles['verify-email-form']}
            >
              {email && (
                <div>
                  <b>{email}</b>
                </div>
              )}
              <div>
                <TextField
                  required
                  type="text"
                  variant="outlined"
                  placeholder="Enter avail username"
                  margin="normal"
                  value={username}
                  className={styles.username}
                  disabled={isSuccess}
                  error={!!usernameError}
                  helperText={usernameError}
                  id="outlined-username-input"
                  onBlur={validateUsername}
                  onChange={({ target: { value } }) => {
                    setUsername(value);
                    setIsTyping(true);
                  }}
                />
              </div>
              <If condition={!!error && !isTyping}>
                <InfoMessage
                  theme={InfoMessageTheme.Error}
                  message={
                    error?.errorMessage || ErrorMessages.FailedPostRequest
                  }
                />
              </If>
              <div>
                <LoadingButton
                  variant="contained"
                  color="primary"
                  loading={isLoading}
                  className={styles.button}
                  disabled={!username || isSuccess || !!usernameError}
                  onClick={() => {
                    mutateAsync({
                      token: query.token as string,
                      username: username.trim()
                    });
                    setIsTyping(false);
                  }}
                >
                  Verify Account
                </LoadingButton>
              </div>
            </Box>
          </If>
          <If condition={isSuccess}>
            <p className={styles.success}>
              Thank you for verifying your account.
            </p>
            <div className={styles['verified-message']}>
              <CheckCircleOutlineIcon />
            </div>
          </If>
        </Grid>
        <Grid item className={styles.contact}>
          <If condition={!!fetchingError}>
            <InfoMessage
              theme={InfoMessageTheme.Error}
              message={
                fetchingError?.errorMessage || ErrorMessages.FailedGetRequest
              }
            />
          </If>
          <If condition={!isLoading}>
            <Box>
              If you have any questions or need help, please contact your
              organization admin.
            </Box>
          </If>
        </Grid>
      </Grid>
    </div>
  );
};
