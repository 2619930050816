import { AdminDTO } from 'entities/DTO/AdminDTO.entity';
import { OrganizationDTO } from 'entities/DTO/OrganizationDTO.entity';
import { UserDTO } from 'entities/DTO/UserDTO.entity';
import { Organization } from 'entities/Organization.entity';
import { User } from 'entities/User.entity';
import { EntityStatus } from 'enums/EntityStatus.enum';
import * as queryString from 'query-string';

import { ApiBase } from '../ApiBase';

export type OrganizationsQuery = {
  sort: string[];
  limit?: number;
  offset?: number;
  search?: string;
  statusEq?: EntityStatus;
};

export type OrganizationResponse = Omit<
  typeof Organization,
  'slug' | 'formattedLicenses' | 'formattedCreatedAt' | 'formattedLastActive'
>;

export interface OrganizationActivateResponse {
  modifiedCount: number;
}

class OrganizationApi extends ApiBase {
  createOrganization(organizationCreateDTO: OrganizationDTO) {
    return this.client.post<OrganizationResponse>(
      '',
      OrganizationDTO.serialize(organizationCreateDTO)
    );
  }

  fetchOrganizations(organizationsQuery: OrganizationsQuery) {
    const query = queryString.stringify(organizationsQuery, {
      arrayFormat: 'comma'
    });

    return this.client.get<OrganizationResponse[]>(
      `${process.env.REACT_APP_BASE_URL}/organizations?${query}`,
      { baseURL: '' }
    );
  }

  fetchUserOrganization(userId: User['id']) {
    const query = queryString.stringify({
      userId
    });

    return this.client.get<OrganizationResponse>(
      `/get-user-organization?${query}`
    );
  }

  inviteUser(organizationId: Organization['id'], userCreateDTO: UserDTO) {
    return this.client.post<OrganizationResponse>(
      `/${organizationId}/invite`,
      UserDTO.serialize(userCreateDTO)
    );
  }

  fetchOrganizationDetails(organizationId: Organization['id']) {
    return this.client.get<OrganizationResponse>(`/${organizationId}`);
  }

  fetchOrganizationBySlug(slugId: Organization['slugId']) {
    return this.client.get<OrganizationResponse>(`/by-slug/${slugId}`);
  }

  updateOrganizationDetails(
    organizationId: Organization['id'],
    organizationUpdateDTO: Partial<OrganizationDTO>
  ) {
    return this.client.patch<OrganizationResponse>(
      `/${organizationId}`,
      OrganizationDTO.serialize(organizationUpdateDTO)
    );
  }

  changeOrganizationAdmin(
    organizationId: Organization['id'],
    existingUserId: User['id'],
    newAdmin: AdminDTO
  ) {
    return this.client.post<OrganizationResponse>(
      `/${organizationId}/change-admin-user`,
      {
        organizationId,
        existingUserId,
        newAdmin: AdminDTO.serialize(newAdmin)
      }
    );
  }

  activateOrganizations(organizationIds: Array<Organization['id']>) {
    const query = queryString.stringify({ idIn: organizationIds });

    return this.client.post<OrganizationActivateResponse>(`/activate?${query}`);
  }

  deactivateOrganizations(organizationIds: Array<Organization['id']>) {
    const query = queryString.stringify({ idIn: organizationIds });

    return this.client.post<OrganizationActivateResponse>(
      `/deactivate?${query}`
    );
  }

  requestMoreLicenses(organizationId: Organization['id']) {
    return this.client.post<null>(`/${organizationId}/request-more-licenses`);
  }
}

const instance = new OrganizationApi('/organizations');

export { instance as OrganizationApi };
