/* eslint-disable */
import React, { FC, useCallback, useMemo, useState } from 'react';
import FullCalendar, { DatesSetArg } from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import { EventApi } from '@fullcalendar/common';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin, { EventReceiveArg } from '@fullcalendar/interaction';
/* eslint-enable */
import { Grid } from '@mui/material';
import { isEqual, setHours, startOfToday } from 'date-fns';
import { useQuery } from 'react-query';

import { Task } from 'entities/Task.entity';
import { ApiError } from 'entities/ApiError.entity';
import { Learner } from 'entities/Learner.entity';
import { Schedule } from 'entities/Schedule.entity';
import { queryKeys } from 'enums/QueryKeys.enum';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { ScheduleRepeatType } from 'enums/ScheduleRepeatType.enum';
import { fetchTaskSchedules } from 'actions/Task/taskActions';
import { ACCESS_TOKEN_EXPIRATION } from 'utils/constants';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { EventsList } from 'views/People/Scheduler/EventsList';
import { EditEventModal } from 'views/People/Scheduler/EditEventModal';
import { CreateEventModal } from 'views/People/Scheduler/CreateEventModal';

import 'scss/calendar.scss';
import globalStyles from 'scss/core.module.scss';
import styles from './SchedulerPage.module.scss';

interface Props {
  learnerId: Learner['id'];
}

export const SchedulerPage: FC<Props> = ({ learnerId }) => {
  const [activeDates, setActiveDates] = useState<{
    start: Date | null;
    end: Date | null;
  } | null>(null);
  const [isCreateEventModalOpen, setIsCreateEventModalOpen] = useState(false);
  const [isEditEventModalOpen, setIsEditEventModalOpen] = useState(false);

  const [activeEvent, setActiveEvent] = useState<{
    scheduledAt: Date;
    taskId: Task['id'];
    taskTitle?: Task['title'];
    eventId?: Schedule['id'];
    revert?: () => void;
    notify: boolean;
    repeat: ScheduleRepeatType;
  }>({
    scheduledAt: new Date(),
    eventId: '',
    taskId: '',
    taskTitle: '',
    notify: false,
    repeat: ScheduleRepeatType.Never
  });

  const {
    data: schedulesData,
    isLoading: isSchedulesLoading,
    isError: isSchedulesError
  } = useQuery<Schedule[], ApiError>(
    queryKeys.filteredSchedules({
      fromTime: activeDates?.start || null,
      toTime: activeDates?.end || null,
      learnerId
    }),
    () =>
      fetchTaskSchedules({
        fromTime: activeDates!.start!.toISOString(),
        toTime: activeDates!.end!.toISOString(),
        learnerId,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }),
    {
      retry: 0,
      enabled: !!activeDates?.start && !!activeDates?.end,
      staleTime: ACCESS_TOKEN_EXPIRATION
    }
  );

  const events = useMemo(
    () =>
      (schedulesData || []).map(
        ({ task, scheduledAt, id, repeat, notify, originalScheduleId }) => ({
          id: task.id,
          title: task.title,
          start: scheduledAt,
          extendedProps: {
            id,
            repeat,
            notify,
            originalScheduleId
          }
        })
      ),
    [schedulesData]
  );

  const onToggleCreateEventModal = useCallback(() => {
    setIsCreateEventModalOpen(
      (prevIsCreateEventModalOpen) => !prevIsCreateEventModalOpen
    );
  }, []);

  const onToggleEditEventModal = useCallback(() => {
    setIsEditEventModalOpen(
      (prevIsEditEventModalOpen) => !prevIsEditEventModalOpen
    );
  }, []);

  const onCreateEvent = ({ event, revert, view }: EventReceiveArg) => {
    const startDate = event.start ? new Date(event.start) : new Date();
    const scheduledAt =
      view.type === 'dayGridMonth' ? setHours(startDate, 12) : startDate;

    setActiveEvent({
      taskId: event.id,
      notify: false,
      repeat: ScheduleRepeatType.Never,
      scheduledAt,
      revert
    });

    onToggleCreateEventModal();
  };

  const renderEvent = useCallback(
    ({ timeText, event }) => (
      <div className={styles.event}>
        {timeText.toUpperCase()} / {event.title}
      </div>
    ),
    []
  );

  const onEditEvent = ({
    event: {
      id,
      start,
      title,
      extendedProps: { id: eventId, repeat, notify, originalScheduleId }
    },
    revert
  }: {
    event: EventApi;
    revert?: () => void;
  }) => {
    setActiveEvent({
      taskId: id,
      notify,
      repeat,
      revert,
      taskTitle: title,
      scheduledAt: start || new Date(),
      eventId:
        repeat !== ScheduleRepeatType.Never ? originalScheduleId : eventId
    });

    onToggleEditEventModal();
  };

  const onChangeCalendarView = ({
    view: { activeStart, activeEnd }
  }: DatesSetArg) => {
    if (
      !activeDates?.start ||
      !activeDates?.end ||
      !isEqual(activeStart, activeDates?.start) ||
      !isEqual(activeEnd, activeDates?.end)
    ) {
      setActiveDates({
        start: activeStart,
        end: activeEnd
      });
    }
  };

  const { taskId, revert, scheduledAt, repeat, notify, eventId, taskTitle } =
    activeEvent;

  return (
    <>
      <EditEventModal
        taskTitle={taskTitle}
        learnerId={learnerId}
        revertEvent={revert}
        eventId={eventId || ''}
        isOpen={isEditEventModalOpen}
        onCloseModal={onToggleEditEventModal}
        eventData={{ scheduledAt, notify, repeat }}
      />
      <CreateEventModal
        taskId={taskId}
        revertEvent={revert}
        learnerId={learnerId}
        isOpen={isCreateEventModalOpen}
        onCloseModal={onToggleCreateEventModal}
        eventData={{ scheduledAt, notify, repeat }}
      />
      <StatusSnackBar
        isError={isSchedulesError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      <LoadingOverlay loading={isSchedulesLoading}>
        <Grid container justifyContent="space-between" spacing={4}>
          <Grid item xs={12} md={9}>
            <FullCalendar
              editable
              droppable
              eventDisplay="block"
              eventTimeFormat={{
                hour: 'numeric',
                minute: '2-digit',
                meridiem: 'short'
              }}
              eventConstraint={{
                start: startOfToday(),
                end: '2121-01-01'
              }}
              eventContent={renderEvent}
              events={events}
              rerenderDelay={10}
              eventClick={onEditEvent}
              eventDurationEditable={false}
              height="calc(100vh - 289px)"
              eventBorderColor="transparent"
              eventTextColor={globalStyles.Black}
              eventBackgroundColor={globalStyles.LightBlue}
              plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
              initialView="dayGridMonth"
              datesSet={onChangeCalendarView}
              eventDrop={onEditEvent}
              eventReceive={onCreateEvent}
              headerToolbar={{
                center: 'title',
                right: 'prev,today,next',
                left: 'timeGridDay,timeGridWeek,dayGridMonth'
              }}
            />
          </Grid>
          <Grid item xs={12} md={3}>
            <EventsList learnerId={learnerId} />
          </Grid>
        </Grid>
      </LoadingOverlay>
    </>
  );
};
