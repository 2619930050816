import { LearnerReport } from 'entities/LearnerReport.entity';
import { Organization } from 'entities/Organization.entity';
import { OrganizationReport } from 'entities/OrganizationReport.entity';
import { StaffReport } from 'entities/StaffReport.entity';
import { User } from 'entities/User.entity';
import * as queryString from 'query-string';
import { ApiBase } from 'services/API/ApiBase';

class ReportApi extends ApiBase {
  fetchOrganizationReport(organizationId: Organization['id']) {
    const query = queryString.stringify({
      organizationId
    });

    return this.client.get<OrganizationReport>(
      `/manager/organization-report?${query}`
    );
  }

  fetchStaffReport(organizationId: Organization['id']) {
    const query = queryString.stringify({
      organizationId
    });

    return this.client.get<StaffReport[]>(`/manager/staff?${query}`);
  }

  fetchLearnersReport(organizationId: Organization['id']) {
    const query = queryString.stringify({
      organizationId
    });

    return this.client.get<LearnerReport[]>(`/manager/learners?${query}`);
  }

  fetchStaffReportCSV(organizationId: Organization['id']) {
    const query = queryString.stringify({
      organizationId
    });

    return this.client.get(`/manager/csv?${query}`);
  }

  fetchLearnerReportCSV(userId: User['id']) {
    const query = queryString.stringify({
      userId
    });

    return this.client.get(`/learner/csv?${query}`);
  }
}

const instance = new ReportApi('/reports');

export { instance as ReportApi };
