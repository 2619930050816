import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { fetchOrganizationReport } from 'actions/Report/reportActions';
import { EmptyState } from 'components/EmptyState';
import { If } from 'components/If';
import { AbsoluteSpinner } from 'components/LoadingOverlay';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Organization } from 'entities/Organization.entity';
import { OrganizationReport } from 'entities/OrganizationReport.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { ACCESS_TOKEN_EXPIRATION } from 'utils/constants';

import styles from './OrganizationOverviewSection.module.scss';

interface Props {
  organizationId: Organization['id'];
}

export const OrganizationOverviewSection: FC<Props> = ({ organizationId }) => {
  const { slugId } = useParams<{ slugId: string }>();

  const { data, isError, isLoading } = useQuery<OrganizationReport, ApiError>(
    queryKeys.organizationReportOverview(slugId),
    () => fetchOrganizationReport(organizationId),
    {
      retry: 0,
      staleTime: ACCESS_TOKEN_EXPIRATION
    }
  );

  const renderOverviewCard = (title: string, value = 0) => (
    <div className={styles.card}>
      <h5 className={styles.value}>{value}</h5>
      <span className={styles.title}>{title}</span>
    </div>
  );

  return (
    <div className={styles['organization-overview-section']}>
      <StatusSnackBar
        isError={isError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      <AbsoluteSpinner loading={isLoading} />
      <If condition={!data && !isLoading}>
        <EmptyState />
      </If>
      <If condition={!!data}>
        <div className={styles.container}>
          <div className={styles.section}>
            {renderOverviewCard('Number of Staff', data?.numberOfStaff)}
            {renderOverviewCard('Number of Learners', data?.numberOfLearners)}
          </div>
          <div className={styles.section}>
            {renderOverviewCard(
              'Number of tasks assigned',
              data?.numberOfTasksAssigned
            )}
            {renderOverviewCard('Number of sessions', data?.numberOfSessions)}
            {renderOverviewCard(
              'Number of task completions',
              data?.numberOfTaskCompletions
            )}
            {renderOverviewCard(
              'Number of assessments',
              data?.numberOfAssessments
            )}
            {renderOverviewCard(
              'Number of task mastered',
              data?.numberOfTasksMastered
            )}
          </div>
        </div>
      </If>
    </div>
  );
};
