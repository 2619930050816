import { OnMoveActionProps } from 'components/DataTable/Row';
import { RowMoveOptions } from 'enums/RowMoveOptions.enum';

interface Props {
  rowId: string;
  index: number;
  parentId?: string | null;
  onMove?: (value: OnMoveActionProps) => void;
  recordsLength: number;
}

interface Result {
  onMoveUp?: () => void;
  onMoveDown?: () => void;
}

type PrepareOnMoveRowActions = (props: Props) => Result;

export const prepareOnMoveRowActions: PrepareOnMoveRowActions = ({
  rowId,
  index,
  parentId = null,
  onMove,
  recordsLength
}) => {
  const onMoveUp =
    onMove && index
      ? () =>
          onMove({
            rowId,
            index,
            parentId,
            action: RowMoveOptions.Up
          })
      : undefined;

  const onMoveDown =
    onMove && index !== recordsLength - 1
      ? () =>
          onMove({
            rowId,
            index,
            parentId,
            action: RowMoveOptions.Down
          })
      : undefined;

  return { onMoveUp, onMoveDown };
};
