import React, { FC } from 'react';
import { useInfiniteQuery } from 'react-query';
import { Backdrop, Box, Fade, Modal as MuiModal } from '@mui/material';
import { fetchTasks } from 'actions/Task/taskActions';
import { If } from 'components/If';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Task } from 'entities/Task.entity';
import { EmptyStateMessages } from 'enums/EmptyStateMessages.enum';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { TaskStatus } from 'enums/TaskStatus.enum';
import { useSharedLibraryContext } from 'hooks/Task/useSharedLibraryContext';
import { useDebounce } from 'use-debounce';
import { ACCESS_TOKEN_EXPIRATION, DEFAULT_PAGE_LIMIT } from 'utils/constants';
import { ModalHeader } from 'views/SharedLibrary/ModalHeader';
import { RepertoireAccordion } from 'views/SharedLibrary/RepertoireAccordion';
import { SharedFolderDetails } from 'views/SharedLibrary/SharedFolderDetails';
import { SharedTaskDetails } from 'views/SharedLibrary/SharedTaskDetails';
import { TaskCardGrid } from 'views/SharedLibrary/TaskCardGrid';

import styles from './SharedLibraryModal.module.scss';

interface Props {
  onCloseModal: () => void;
  isOpen: boolean;
  onAddToSharedLibrary?: (taskId: Task['id']) => void;
  onAddToMyTasks?: (taskId: Task['id']) => void;
}

export const SharedLibraryModal: FC<Props> = ({
  isOpen,
  onCloseModal,
  onAddToSharedLibrary,
  onAddToMyTasks
}) => {
  const { search, activeRepertoire, activeTask } = useSharedLibraryContext();

  const [debouncedSearch] = useDebounce(search, 1000);

  const {
    data,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isError,
    isFetchingNextPage
  } = useInfiniteQuery<Task[], ApiError>(
    queryKeys.filteredTasks({
      status: TaskStatus.Published,
      search: debouncedSearch,
      repertoireIdEq: activeRepertoire?.id || undefined,
      parentIdIsNull: true,
      organizationIdIsNull: true,
      parentIdEq: undefined
    }),
    ({ pageParam }): Promise<Task[]> => {
      return fetchTasks({
        sort: ['title:ASC'],
        publishStatusEq: TaskStatus.Published,
        limit: DEFAULT_PAGE_LIMIT,
        offset: pageParam?.offset || 0,
        search: debouncedSearch === '' ? undefined : debouncedSearch,
        repertoireIdEq: activeRepertoire?.id || undefined,
        organizationIdIsNull: true,
        parentIdIsNull: true,
        exclude: ['steps'],
        parentIdEq: undefined
      });
    },
    {
      staleTime: ACCESS_TOKEN_EXPIRATION,
      retry: 0,
      keepPreviousData: true,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length < DEFAULT_PAGE_LIMIT) {
          return undefined;
        }

        return {
          offset: allPages.flat().length
        };
      }
    }
  );

  return (
    <>
      <StatusSnackBar
        isError={isError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      <MuiModal
        open={isOpen}
        onClose={onCloseModal}
        aria-labelledby="Avail library"
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={isOpen}>
          <Box className={styles.modal}>
            <LoadingOverlay loading={isFetching && !isFetchingNextPage}>
              <ModalHeader onCloseModal={onCloseModal} />

              <div className={styles.content}>
                <RepertoireAccordion />

                <If condition={!!activeTask}>
                  {activeTask?.isFolder ? (
                    <SharedFolderDetails />
                  ) : (
                    <SharedTaskDetails task={activeTask!} />
                  )}
                </If>

                <If condition={!activeTask}>
                  <TaskCardGrid
                    data={data?.pages.flat() || []}
                    hasNextPage={hasNextPage}
                    isLoading={isFetching}
                    isInitialLoading={isFetching && !isFetchingNextPage}
                    onLoadMore={fetchNextPage}
                    emptyMessage={
                      debouncedSearch
                        ? EmptyStateMessages.Search
                        : EmptyStateMessages.Default
                    }
                    onAddToSharedLibrary={onAddToSharedLibrary}
                    onAddToMyTasks={onAddToMyTasks}
                  />
                </If>
              </div>
            </LoadingOverlay>
          </Box>
        </Fade>
      </MuiModal>
    </>
  );
};
