import { AuthToken } from 'entities/AuthToken.entity';

import { ApiBase } from '../ApiBase';
import { UserResponse } from '../User/UserApi';

class AuthApi extends ApiBase {
  login(login: string, password: string, shortSession: boolean) {
    return this.client.post<AuthToken>('/authenticate', {
      login,
      password,
      shortSession
    });
  }

  logout() {
    return this.client.post<null>('/logout');
  }

  loadMe() {
    return this.client.get<UserResponse>('/authenticated-user-data');
  }

  resetPassword(email: string) {
    return this.client.post<null>('/request-to-change-password', {
      email
    });
  }

  submitResetPassword(password: string, token: string) {
    return this.client.post<null>(
      `/request-to-change-password/${token}/submit`,
      {
        password
      }
    );
  }
}

const instance = new AuthApi('/auth');

export { instance as AuthApi };
