import { FC, useState } from 'react';
import * as React from 'react';
import { Controller, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { Checkbox, FormControlLabel, Link, TextField } from '@mui/material';
import { If } from 'components/If';
import { InfoMessage, InfoMessageTheme } from 'components/InfoMessage';
import { PasswordAdornment } from 'components/PasswordAdornment';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { Routes } from 'enums/Routes.enum';
import { useAuthContext, UserData } from 'hooks/Auth/useAuthContext';

import styles from './LoginForm.module.scss';

export const LoginForm: FC = () => {
  const {
    control,
    handleSubmit,
    formState: { errors }
  } = useForm<UserData>({
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false
  });

  const { logIn, error, isLoading } = useAuthContext();

  const [showPassword, setShowPassword] = useState<boolean>(false);

  return (
    <>
      <If condition={error !== undefined}>
        <InfoMessage
          theme={InfoMessageTheme.Error}
          message={error || ErrorMessages.FailedPostRequest}
        />
      </If>

      <form
        className={styles['login-form']}
        onSubmit={handleSubmit((formData) => logIn(formData))}
      >
        <Controller
          rules={{ required: true }}
          name="email"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              type="text"
              variant="outlined"
              label="Login"
              margin="normal"
              error={!!errors?.email}
              aria-invalid={!!errors?.email}
              helperText={errors?.email && ErrorMessages.FailedRequiredField}
              id="outlined-login-input"
            />
          )}
        />
        <Controller
          rules={{
            required: true
          }}
          name="password"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              variant="outlined"
              label="Password"
              margin="normal"
              inputRef={field.ref}
              error={!!errors?.password}
              helperText={errors?.password && ErrorMessages.FailedRequiredField}
              id="password-outlined-input"
              type={showPassword ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <PasswordAdornment
                    isPasswordVisible={showPassword}
                    onShowPassword={() => setShowPassword(!showPassword)}
                  />
                )
              }}
            />
          )}
        />
        <Link
          href={Routes.ForgotPassword}
          underline="hover"
          className={styles.link}
        >
          Forgot password?
        </Link>
        <Controller
          control={control}
          name="rememberMe"
          render={({ field: { onChange, value } }) => (
            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={value}
                  onChange={({ target: { checked } }) => onChange(checked)}
                  inputProps={{
                    'aria-label': 'Remember me'
                  }}
                />
              }
              label="Remember me"
              className={styles.checkbox}
            />
          )}
        />
        <LoadingButton
          type="submit"
          color="primary"
          variant="contained"
          loading={isLoading}
        >
          Log in
        </LoadingButton>
      </form>
      <p>
        By logging in you&apos;re agreeing to the&nbsp;
        <Link
          href="https://centralreach.com/legal/terms-of-service/"
          underline="always"
          target="_blank"
        >
          terms of service.
        </Link>
      </p>
    </>
  );
};
