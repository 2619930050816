import React, { FC } from 'react';
import { useMutation } from 'react-query';
import { createTaskSchedule } from 'actions/Task/taskActions';
import { Modal } from 'components/Modal';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Learner } from 'entities/Learner.entity';
import { Schedule } from 'entities/Schedule.entity';
import { Task } from 'entities/Task.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { useLoadAuthUserData } from 'hooks/Auth/useLoadAuthUserData';
import { queryClient } from 'index';
import { EventForm, FormData } from 'views/People/Scheduler/EventForm';

interface Props {
  isOpen: boolean;
  revertEvent?: () => void;
  taskId: Task['id'];
  learnerId: Learner['id'];
  eventData: FormData;
  onCloseModal: () => void;
}

export const CreateEventModal: FC<Props> = ({
  isOpen,
  learnerId,
  revertEvent,
  eventData,
  onCloseModal,
  taskId
}) => {
  const { data } = useLoadAuthUserData();
  const { isError, isSuccess, isLoading, error, mutateAsync } = useMutation<
    Schedule,
    ApiError,
    FormData
  >(
    ({ repeat, notify, scheduledAt }: FormData) =>
      createTaskSchedule({
        repeat,
        notify,
        learnerId,
        scheduledAt,
        taskId: taskId!,
        createdById: data!.id,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          queryKeys.userSchedulesList(learnerId)
        );
        onCloseModal();
      },
      onSettled: () => {
        if (revertEvent) {
          revertEvent();
        }
      }
    }
  );

  const closeModal = () => {
    if (revertEvent) {
      revertEvent();
    }
    onCloseModal();
  };

  return (
    <>
      <StatusSnackBar
        isError={isError}
        isSuccess={isSuccess}
        successMessage="Task successfully scheduled"
        errorMessage={error?.errorMessage || ErrorMessages.FailedPostRequest}
      />
      <Modal title="Schedule task" isOpen={isOpen} onCloseModal={closeModal}>
        <EventForm
          isLoading={isLoading}
          onSubmit={mutateAsync}
          defaultValues={eventData}
          onCloseModal={closeModal}
          saveButtonText="Schedule task"
        />
      </Modal>
    </>
  );
};
