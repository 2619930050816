import { ApiError } from 'entities/ApiError.entity';
import { AuthToken } from 'entities/AuthToken.entity';
import { User } from 'entities/User.entity';
import { UserData } from 'hooks/Auth/useAuthContext';
import { AuthApi } from 'services/API/Auth/AuthApi';
import { AuthData } from 'types/commonTypes';

export const login = async ({
  email,
  password,
  rememberMe
}: UserData): Promise<AuthData> => {
  try {
    const response = await AuthApi.login(email, password, !rememberMe);

    return {
      ...AuthToken.deserialize(response.data),
      rememberMe
    };
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const loadMe = async (): Promise<User> => {
  try {
    const response = await AuthApi.loadMe();

    return User.deserialize(response.data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const logout = async (): Promise<void> => {
  try {
    await AuthApi.logout();
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const resetPassword = async (email: string): Promise<void> => {
  try {
    await AuthApi.resetPassword(email);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const submitResetPassword = async ({
  password,
  token
}: {
  password: string;
  token: string;
}): Promise<void> => {
  try {
    await AuthApi.submitResetPassword(password, token);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};
