import { intervalToDuration } from 'date-fns';

export const getPeriodByMilliseconds = (milliseconds: number): string => {
  const { minutes, seconds } = intervalToDuration({
    start: 0,
    end: milliseconds
  });

  switch (0) {
    case milliseconds:
      return '0 minutes';
    case seconds:
      return `${minutes} minutes`;
    case minutes:
      return `${seconds} seconds`;
    default:
      return `${minutes} minutes ${seconds} seconds`;
  }
};
