import React, { FC } from 'react';
import classNames from 'classnames';

import { InfoMessageTheme } from './InfoMessage.enum';

import styles from './InfoMessage.module.scss';

interface Props {
  theme: InfoMessageTheme;
  message: string;
}

export const InfoMessage: FC<Props> = ({ theme, message }) => (
  <p className={classNames(styles['info-message'], styles[theme])}>{message}</p>
);
