import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { Step } from 'entities/Step.entity';
import {
  StepContentType,
  StepContentTypeIcon,
  StepContentTypeTitles
} from 'enums/StepContentType.enum';

export const getStepContentTypes = (step: Step, separator = ', '): string => {
  const contentType = Object.entries(StepContentTypeTitles)
    .reduce((acc: string[], [key, value]) => {
      return step[key] ? [...acc, value] : acc;
    }, [])
    .join(separator);

  return contentType || 'No content';
};

export const getStepContentIcon = (
  step: Step
): OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string } => {
  const contentType = Object.keys(StepContentType).find((key) => step[key]);

  const stepIcon = contentType && StepContentTypeIcon[contentType];
  return stepIcon || BrokenImageIcon;
};
