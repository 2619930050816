import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { generatePath, useParams } from 'react-router-dom';
import { fetchAssociations } from 'actions/User/userActions';
import { EmptyState } from 'components/EmptyState';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Association } from 'entities/Association.entity';
import { User } from 'entities/User.entity';
import { EmptyStateMessages } from 'enums/EmptyStateMessages.enum';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { Routes } from 'enums/Routes.enum';
import { useOrganizationBySlug } from 'hooks/Organization/useOrganizationBySlug';
import { ACCESS_TOKEN_EXPIRATION } from 'utils/constants';
import { AssociationRow } from 'views/People/Associations/AsociationRow';
import { AssociationSection } from 'views/People/Associations/AssociationSection';

interface Props {
  userId: User['id'];
}

export const StaffAssociations: FC<Props> = ({ userId }) => {
  const { data: organizationData } = useOrganizationBySlug();
  const { slug, slugId } = useParams<{
    slugId: string;
    slug: string;
  }>();

  const { data, isError, isLoading } = useQuery<Association[], ApiError>(
    queryKeys.userAssociations(organizationData!.id, userId),
    () => fetchAssociations(userId),
    {
      retry: 0,
      staleTime: ACCESS_TOKEN_EXPIRATION
    }
  );

  if (!data?.length) {
    return (
      <LoadingOverlay loading={isLoading}>
        <StatusSnackBar
          isError={isError}
          errorMessage={ErrorMessages.FailedGetRequest}
        />
        {!isLoading && <EmptyState title={EmptyStateMessages.Associations} />}
      </LoadingOverlay>
    );
  }

  return (
    <AssociationSection label="Learners">
      <>
        {(data || []).map(({ fullName, avatar, id }) => (
          <AssociationRow
            key={fullName}
            label={fullName}
            image={avatar?.url}
            navTo={generatePath(Routes.LearnerDetails, {
              slug,
              slugId,
              id
            })}
          />
        ))}
      </>
    </AssociationSection>
  );
};
