import React, { FC } from 'react';
import { useMutation } from 'react-query';
import { createTaskStep } from 'actions/Task/taskActions';
import { Modal } from 'components/Modal';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Step } from 'entities/Step.entity';
import { Task } from 'entities/Task.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { queryClient } from 'index';
import { getMillisecondsFromDate } from 'utils/helpers/timeHelpers';
import { FormData, StepForm } from 'views/Task/StepForm';

interface Props {
  isOpen: boolean;
  taskId: Task['id'];
  onCloseModal: () => void;
}

export const CreateStepModal: FC<Props> = ({
  isOpen,
  onCloseModal,
  taskId
}) => {
  const { isError, isSuccess, isLoading, error, mutateAsync } = useMutation<
    Step,
    ApiError,
    FormData
  >(
    (formData: FormData) =>
      createTaskStep(taskId, {
        title: formData.title,
        timer: formData.timer
          ? getMillisecondsFromDate(formData.timer)
          : undefined,
        text: formData.text,
        image: formData.image,
        video: formData.video,
        audio: formData.audio,
        active: true
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeys.taskSteps(taskId));
        await queryClient.invalidateQueries(queryKeys.taskDetails(taskId));
        await queryClient.invalidateQueries(queryKeys.assessmentsList);
        await queryClient.invalidateQueries(queryKeys.tasksList);
        await queryClient.invalidateQueries(
          queryKeys.taskAssetHistoryDetails(taskId)
        );

        onCloseModal();
      }
    }
  );

  return (
    <>
      <StatusSnackBar
        isError={isError}
        isSuccess={isSuccess}
        successMessage="Step successfully created"
        errorMessage={error?.errorMessage || ErrorMessages.FailedPostRequest}
      />
      <Modal title="Create step" isOpen={isOpen} onCloseModal={onCloseModal}>
        <StepForm
          isLoading={isLoading}
          onSubmit={mutateAsync}
          defaultValues={{
            timer: null
          }}
          onCloseModal={onCloseModal}
          saveButtonText="Create step"
        />
      </Modal>
    </>
  );
};
