import React, { FC } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, TextField } from '@mui/material';
import { ErrorMessages } from 'enums/ErrorMessages.enum';

import styles from './RepertoireForm.module.scss';

interface Props {
  defaultValues?: Partial<FormData>;
  onCloseModal: () => void;
  onSubmit: (repertoire: FormData) => void;
  isLoading: boolean;
  saveButtonText: string;
}

export interface FormData {
  title: string;
}

export const RepertoireForm: FC<Props> = ({
  defaultValues,
  onCloseModal,
  isLoading,
  onSubmit,
  saveButtonText
}) => {
  const methods = useForm<FormData>({
    defaultValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty }
  } = methods;

  return (
    <FormProvider {...methods}>
      <form
        className={styles['repertoire-form']}
        onSubmit={handleSubmit((formData: FormData) => onSubmit(formData))}
      >
        <Controller
          rules={{ required: true }}
          name="title"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              type="text"
              margin="normal"
              variant="outlined"
              label="Repertoire title"
              inputRef={field.ref}
              error={!!errors?.title}
              id="outlined-title-input"
              className={styles.input}
              inputProps={{ maxLength: 30 }}
              helperText={errors?.title && ErrorMessages.FailedRequiredField}
            />
          )}
        />

        <div className={styles.footer}>
          <Grid container alignItems="stretch" spacing={2}>
            <Grid item xs={6}>
              <LoadingButton
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
                loading={isLoading}
                disabled={!isDirty}
              >
                {saveButtonText}
              </LoadingButton>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                onClick={onCloseModal}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    </FormProvider>
  );
};
