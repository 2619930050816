export enum ActivityStatus {
  Completed = 'completed',
  NotCompleted = 'not_completed'
}

export const ActivityStatusLabel = Object.freeze<
  Record<ActivityStatus, string>
>({
  [ActivityStatus.Completed]: 'Completed',
  [ActivityStatus.NotCompleted]: 'Not Completed'
});
