import React, { FC } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { TextField } from '@mui/material';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { isEmailValid } from 'utils/helpers/validators';

import styles from './CreateOrganizationAdmin.module.scss';

export const CreateOrganizationAdmin: FC = () => {
  const {
    control,
    formState: { errors }
  } = useFormContext();

  return (
    <div className={styles['create-organization-admin']}>
      <Controller
        rules={{ required: true }}
        name="admin.firstName"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="text"
            variant="outlined"
            margin="normal"
            inputRef={field.ref}
            error={!!errors?.admin?.firstName}
            label="First name"
            aria-invalid={!!errors?.admin?.firstName}
            helperText={
              errors?.admin?.firstName && ErrorMessages.FailedRequiredField
            }
            id="outlined-organization-first-name-input"
          />
        )}
      />
      <Controller
        rules={{ required: true }}
        name="admin.lastName"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="text"
            label="Last name"
            margin="normal"
            variant="outlined"
            error={!!errors?.admin?.lastName}
            id="outlined-last-name-input"
            aria-invalid={!!errors?.admin?.lastName}
            helperText={
              errors?.admin?.lastName && ErrorMessages.FailedRequiredField
            }
          />
        )}
      />
      <Controller
        rules={{
          validate: (value) => {
            if (!value) {
              return ErrorMessages.FailedRequiredField;
            }
            if (!isEmailValid(value)) {
              return ErrorMessages.NotValidEmail;
            }

            return true;
          }
        }}
        name="admin.email"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="text"
            label="Email address"
            margin="normal"
            variant="outlined"
            error={!!errors?.admin?.email}
            id="outlined-email-input"
            aria-invalid={!!errors?.admin?.email}
            helperText={errors?.admin?.email?.message}
          />
        )}
      />
    </div>
  );
};
