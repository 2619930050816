import { useQuery, UseQueryResult } from 'react-query';
import { UseQueryOptions } from 'react-query/types/react/types';
import { fetchTasks } from 'actions/Task/taskActions';
import { ApiError } from 'entities/ApiError.entity';
import { Task } from 'entities/Task.entity';
import { queryKeys } from 'enums/QueryKeys.enum';
import { TaskSort } from 'enums/TaskSort.enum';
import { TaskType } from 'enums/TaskType.enum';
import { TasksQuery } from 'services/API/Task/TaskApi';
import { ACCESS_TOKEN_EXPIRATION, MAX_PAGE_LIMIT } from 'utils/constants';

interface Props {
  filters?: Partial<TasksQuery>;
  options?: Partial<UseQueryOptions<Task[], ApiError>>;
}

type UseFolders = (props?: Props) => UseQueryResult<Task[], ApiError>;

export const useFolders: UseFolders = (props) =>
  useQuery<Task[], ApiError>(
    queryKeys.filteredFolders({
      typeEq: TaskType.Group,
      limit: MAX_PAGE_LIMIT,
      sort: [TaskSort.ByName],
      ...props?.filters
    }),
    () =>
      fetchTasks({
        typeEq: TaskType.Group,
        sort: [TaskSort.ByName],
        exclude: ['steps'],
        limit: MAX_PAGE_LIMIT,
        ...props?.filters
      }),
    {
      retry: 0,
      staleTime: ACCESS_TOKEN_EXPIRATION,
      ...props?.options
    }
  );
