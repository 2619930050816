import { Column } from 'react-table';
import { format } from 'date-fns';
import { Assessment } from 'entities/Assessment.entity';

export const columns: Column<Assessment>[] = [
  {
    id: 'date',
    Header: 'Date',
    accessor: ({ createdAt }) => format(createdAt, 'd MMMM yyyy')
  },
  {
    id: 'score',
    Header: 'Score',
    accessor: ({ scorePercent }) => `${scorePercent}%`
  },
  {
    id: 'steps',
    Header: 'Steps',
    accessor: ({ independentStepsAmount, stepsAmount }) =>
      `${independentStepsAmount}/${stepsAmount}`
  },
  {
    id: 'comments',
    Header: 'Comments',
    accessor: ({ notes }) => notes || '-'
  }
];
