import { RefObject, useCallback, useEffect, useState } from 'react';
import { useInfiniteQuery } from 'react-query';
import { UseInfiniteQueryResult } from 'react-query/types/react/types';
import { fetchTasks } from 'actions/Task/taskActions';
import { fetchUsers } from 'actions/User/userActions';
import { DataTableHandle } from 'components/DataTable';
import { ApiError } from 'entities/ApiError.entity';
import { Task } from 'entities/Task.entity';
import { User } from 'entities/User.entity';
import { queryKeys } from 'enums/QueryKeys.enum';
import { TaskSort } from 'enums/TaskSort.enum';
import { useRepertoires } from 'hooks/Task/useRepertoires';
import { TaskColumn } from 'hooks/Task/useTasksContext';
import { TasksQuery } from 'services/API/Task/TaskApi';
import { ACCESS_TOKEN_EXPIRATION, DEFAULT_PAGE_LIMIT } from 'utils/constants';
import { getEntityName } from 'utils/helpers/commonHelpers';

interface Props {
  taskQuery: Partial<TasksQuery>;
  taskSort: TaskSort;
  activeFolder: TaskColumn | null;
  tableRef: RefObject<DataTableHandle>;
}

interface AdditionalReturnData {
  formattedActiveFolder: TaskColumn | null;
  tableData: TaskColumn[];
  isRepertoiresError: boolean;
  isDataUpdating: boolean;
}

type UseOrganizationLibrary = (
  props: Props
) => UseInfiniteQueryResult<TaskColumn[], ApiError> & AdditionalReturnData;

export const useOrganizationLibrary: UseOrganizationLibrary = ({
  taskQuery,
  taskSort,
  activeFolder,
  tableRef
}) => {
  const [formattedActiveFolder, setFormattedActiveFolder] =
    useState<TaskColumn | null>(null);

  const {
    data: repertoiresData,
    isFetching: isRepertoiresFetching,
    isSuccess: isRepertoiresSuccess,
    isError: isRepertoiresError
  } = useRepertoires();

  const formatTasks = useCallback(
    async (tasks: Task[]) => {
      const usersData = {
        createdByUserIds: new Set<string>(),
        assignedToUserIds: new Set<string>()
      };

      tasks.forEach((task) => {
        usersData.createdByUserIds.add(task.createdById);
        if (task.assignedToId) {
          usersData.assignedToUserIds.add(task.assignedToId);
        }
      });

      const createdByUser: User[] = await fetchUsers({
        sort: ['firstName:ASC'],
        limit: DEFAULT_PAGE_LIMIT,
        idIn: [...new Set(usersData.createdByUserIds)]
      });

      const assignedToUser: User[] = await fetchUsers({
        sort: ['firstName:ASC'],
        limit: DEFAULT_PAGE_LIMIT,
        idIn: [...new Set(usersData.assignedToUserIds)]
      });

      const getUsersMap: (users: User[]) => Map<string, User> = (
        users: User[]
      ) =>
        users.reduce((acc, user) => {
          acc.set(user.id, user);

          return acc;
        }, new Map());

      return tasks.map((task) => ({
        ...task,
        createdByUserList: getUsersMap(createdByUser).get(task.createdById),
        assignedTo: getUsersMap(assignedToUser).get(task?.assignedToId || ''),
        repertoireName: getEntityName(task.repertoireId, repertoiresData),
        imageUrl: task.imageUrl,
        isFolder: task.isFolder
      }));
    },
    [repertoiresData]
  );

  const infiniteQueryData = useInfiniteQuery<TaskColumn[], ApiError>(
    queryKeys.filteredTasks(taskQuery),
    async ({ pageParam }): Promise<TaskColumn[]> => {
      const tasks: Task[] = await fetchTasks({
        ...taskQuery,
        sort: [taskSort],
        exclude: ['steps'],
        limit: DEFAULT_PAGE_LIMIT,
        offset: pageParam?.offset || 0
      });

      const formattedTasks = await formatTasks(tasks);

      if (!pageParam?.offset) {
        tableRef.current?.reset();
      }

      return formattedTasks;
    },
    {
      staleTime: ACCESS_TOKEN_EXPIRATION,
      retry: 0,
      enabled: isRepertoiresSuccess,
      keepPreviousData: true,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length < DEFAULT_PAGE_LIMIT) {
          return undefined;
        }

        return {
          offset: allPages.flat().length
        };
      }
    }
  );

  useEffect(() => {
    if (activeFolder) {
      const updateActiveFolder = async () => {
        const updatedActiveFolder = await formatTasks([activeFolder]);

        setFormattedActiveFolder(updatedActiveFolder[0]);
      };

      updateActiveFolder();
    } else {
      setFormattedActiveFolder(null);
    }

    tableRef.current?.reset();
  }, [activeFolder, formatTasks, tableRef]);

  const { data, isFetching, isFetchingNextPage, isFetchingPreviousPage } =
    infiniteQueryData;

  const tableData: TaskColumn[] = data?.pages.flat() || [];
  const isDataUpdating =
    isFetching && !isFetchingNextPage && !isFetchingPreviousPage;

  return {
    ...infiniteQueryData,
    formattedActiveFolder,
    tableData,
    isRepertoiresError,
    isDataUpdating,
    ...(isRepertoiresFetching && { isFetching: true })
  };
};
