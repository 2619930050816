import { serializable, serialize } from 'serializr';

export class LearnerDTO {
  @serializable
  firstName = '';

  @serializable
  lastName = '';

  @serializable
  email?: string;

  @serializable
  username = '';

  @serializable
  organizationId = '';

  @serializable
  password = '';

  @serializable
  facilitatorId?: string;

  @serializable
  avatar?: string | null;

  static serialize(json: Object | string): LearnerDTO {
    return serialize(LearnerDTO, json);
  }
}
