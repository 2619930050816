import { serializable, serialize } from 'serializr';

export class AdminDTO {
  @serializable
  firstName = '';

  @serializable
  lastName = '';

  @serializable
  email = '';

  static serialize(json: Object | string): AdminDTO {
    return serialize(AdminDTO, json);
  }
}
