import { File } from 'entities/File.entity';
import { User } from 'entities/User.entity';
import { EntityStatus } from 'enums/EntityStatus.enum';
import { UserRoles } from 'enums/UserRoles.enum';
import { custom, date, deserialize, object, serializable } from 'serializr';

export class Association {
  @serializable
  id = '';

  @serializable(date())
  createdAt: Date = new Date();

  @serializable(date())
  updatedAt: Date = new Date();

  @serializable
  firstName = '';

  @serializable
  lastName = '';

  @serializable
  email = '';

  @serializable
  role: UserRoles = UserRoles.Learner;

  @serializable
  phoneNumber? = '';

  @serializable
  username = '';

  @serializable(object(File))
  avatar?: File | null;

  @serializable
  status: EntityStatus = EntityStatus.Active;

  @serializable(date())
  lastSessionDate: Date = new Date();

  @serializable
  organizationId: string | null = null;

  @serializable
  tasksCount = 0;

  @serializable(
    custom(
      (sourcePropertyValue: string) => {
        return sourcePropertyValue;
      },
      (jsonValue: Array<User['id']> | null) => {
        return jsonValue || null;
      }
    )
  )
  facilitatorIds?: string[] | null = null;

  get fullName(): string {
    return `${this.firstName} ${this.lastName}`;
  }

  static deserialize(json: Object | string): Association {
    return deserialize(Association, json);
  }

  static deserializeAsList(list: Association[]): Association[] {
    return list.map(Association.deserialize);
  }
}
