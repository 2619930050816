import React, { ComponentProps, ElementType, ReactElement } from 'react';
import cn from 'classnames';

import styles from './NavTab.module.scss';

interface Props<T> {
  component: T;
  label: string;
  className?: string;
  active?: boolean;
}

export const NavTab = <T extends ElementType>({
  component,
  className,
  label,
  active,
  ...rest
}: Props<T> & ComponentProps<T>): ReactElement<Props<T>> => {
  const TabComponent = component;

  return (
    <TabComponent
      {...rest}
      className={cn(styles.tab, active && styles.active, className)}
    >
      {label}
    </TabComponent>
  );
};
