import React, { FC, useCallback, useState } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Avatar, Button } from '@mui/material';
import cn from 'classnames';
import { Step } from 'entities/Step.entity';
import { getPeriodByMilliseconds } from 'utils/helpers/getPeriodByMilliseconds';
import {
  getStepContentIcon,
  getStepContentTypes
} from 'utils/helpers/stepHelpers';
import { DeactivateStepModal } from 'views/Task/DeactivateStepModal';
import { EditStepModal } from 'views/Task/EditStepModal';

import styles from './StepCard.module.scss';

interface Props {
  step: Step;
  onUpdateStep: (step: Step) => void;
  index?: number;
  draggableProvided?: DraggableProvided;
  isDragging?: boolean;
}

export const StepCard: FC<Props> = ({
  step,
  index = 0,
  onUpdateStep,
  isDragging,
  draggableProvided
}) => {
  const [isEditStepModalOpen, setIsEditStepModalOpen] = useState(false);
  const [isDeactivateStepModalOpen, setIsDeactivateStepModalOpen] =
    useState(false);

  const onToggleDeactivateStepModal = useCallback(() => {
    setIsDeactivateStepModalOpen(
      (prevIsDeactivateStepModalOpen) => !prevIsDeactivateStepModalOpen
    );
  }, []);
  const { title, timer, active, imageUrl, videoThumbnail } = step;

  const onToggleEditStepModal = useCallback(() => {
    setIsEditStepModalOpen(
      (prevIsEditStepModalOpen) => !prevIsEditStepModalOpen
    );
  }, []);

  const Icon = getStepContentIcon(step);

  return (
    <div
      className={cn(
        styles['step-card'],
        !active && styles.disabled,
        isDragging && styles.dragging
      )}
      {...(draggableProvided && {
        ref: draggableProvided.innerRef,
        style: draggableProvided.draggableProps.style,
        ...draggableProvided.draggableProps
      })}
      data-testid="step-card"
    >
      <EditStepModal
        step={step}
        onUpdateStep={onUpdateStep}
        isOpen={isEditStepModalOpen}
        onCloseModal={onToggleEditStepModal}
      />
      <DeactivateStepModal
        data={{
          id: step.id,
          title: step.title
        }}
        onUpdateStep={onUpdateStep}
        isOpen={isDeactivateStepModalOpen}
        onCloseModal={onToggleDeactivateStepModal}
      />
      <div {...(draggableProvided && { ...draggableProvided.dragHandleProps })}>
        <DragIndicatorIcon
          className={cn(styles['drag-icon'], !active && styles.hidden)}
          data-testid="drag-icon"
        />
      </div>

      <span className={cn(styles['order-number'], !active && styles.hidden)}>
        {index}
      </span>

      {imageUrl || videoThumbnail ? (
        <Avatar
          variant="square"
          alt={`${title} task`}
          className={styles.image}
          src={imageUrl || videoThumbnail}
        />
      ) : (
        <div className={styles.icon}>
          <Icon />
        </div>
      )}
      <div className={styles['step-details']}>
        <h4 className={styles.title}>{title}</h4>

        <div className={styles['details-box']}>
          <div className={styles['details-column']}>
            <span className={styles['details-title']}>Content type</span>
            <span>{getStepContentTypes(step)}</span>
          </div>
          <div className={styles['details-column']}>
            <span className={styles['details-title']}>Timer</span>
            <span>{getPeriodByMilliseconds(timer || 0)}</span>
          </div>
        </div>
      </div>

      <div className={styles.actions}>
        <Button
          size="small"
          variant="contained"
          className={styles.button}
          onClick={onToggleEditStepModal}
          color={active ? 'primary' : 'secondary'}
        >
          Edit step
        </Button>
        <Button
          size="small"
          color="secondary"
          variant="contained"
          onClick={onToggleDeactivateStepModal}
        >
          Deactivate
        </Button>
      </div>
    </div>
  );
};
