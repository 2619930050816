import React from 'react';
import cn from 'classnames';
import { If } from 'components/If';

import styles from './LoadingOverlay.module.scss';

interface Props {
  loading: boolean;
  unmountOnLoading?: boolean;
}

export const LoadingOverlay: React.FC<Props> = ({
  loading,
  children,
  unmountOnLoading = false
}) => {
  return (
    <div className={styles['loading-overlay']}>
      <div
        data-testid="overlay"
        className={cn(styles.overlay, loading && styles.disabled)}
      >
        {unmountOnLoading && loading ? null : children}
      </div>
      <If condition={loading}>
        <div data-testid="spinner" className={styles.spinner} />
      </If>
    </div>
  );
};
