import { deserialize, serializable } from 'serializr';

export class ActivityGroup {
  @serializable
  year = 0;

  @serializable
  month = 0;

  @serializable
  activitiesAmount = 0;

  @serializable
  averageTimeSec = 0;

  static deserializeAsList(list: ActivityGroup[]): ActivityGroup[] {
    return list.map(ActivityGroup.deserialize);
  }

  static deserialize(json: Object | string): ActivityGroup {
    return deserialize(ActivityGroup, json);
  }
}
