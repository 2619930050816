import React from 'react';
import { NavigationHeader } from 'components/NavigationHeader';

import { LoadingOverlay } from '../LoadingOverlay';

import styles from './Layout.module.scss';

interface Props {
  loading?: boolean;
}

export const Layout: React.FC<Props> = ({ children, loading = false }) => (
  <LoadingOverlay loading={loading}>
    <div className={styles.layout}>
      <NavigationHeader />
      {children || 'Data is not loaded, sorry'}
    </div>
  </LoadingOverlay>
);
