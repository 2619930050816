import React, { FC } from 'react';
import { useMutation } from 'react-query';
import { updateOrganizationDetails } from 'actions/Organization/organizationActions';
import { Modal } from 'components/Modal';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Organization } from 'entities/Organization.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { queryClient } from 'index';

import { EditLicensesForm, FormData } from '../EditLicensesForm';

interface Props {
  isOpen: boolean;
  data: Organization;
  onCloseModal: () => void;
}

export const EditLicensesModal: FC<Props> = ({
  data,
  isOpen,
  onCloseModal
}) => {
  const { id, slugId } = data;

  const { isLoading, isError, isSuccess, mutateAsync } = useMutation<
    Organization,
    ApiError,
    FormData
  >((formData: FormData) => updateOrganizationDetails(id, formData), {
    onSuccess: (data) => {
      queryClient.setQueryData(queryKeys.organizationDetails(slugId), data);
      onCloseModal();
    }
  });

  return (
    <>
      <StatusSnackBar
        isError={isError}
        isSuccess={isSuccess}
        errorMessage={ErrorMessages.FailedPostRequest}
        successMessage="Organization licenses successfully updated"
      />
      <Modal
        isOpen={isOpen}
        onCloseModal={onCloseModal}
        title={`Edit licenses for ${data.name}`}
      >
        <EditLicensesForm
          data={data}
          isLoading={isLoading}
          onSubmit={mutateAsync}
          onCloseModal={onCloseModal}
        />
      </Modal>
    </>
  );
};
