import React from 'react';
import { Row as TableRow } from 'react-table';
import cn from 'classnames';
import { RowMoveOptions } from 'enums/RowMoveOptions.enum';

import styles from './SimpleTable.module.scss';

export type Data = object & {
  id: string;
};

interface Props<T extends object> {
  item: TableRow<T & { isCombining?: boolean }>;
  isCombining?: boolean;
  isCombiningHover?: boolean;
  renderSubRows?: (props: SubRows) => void;
}

export interface SubRows {
  row: TableRow;
}

export interface OnMoveActionProps {
  rowId: string;
  index: number;
  parentId: string | null;
  action: RowMoveOptions;
}

export const Row: <T extends Data>(
  p: Props<T>
) => React.ReactElement<Props<T>> = ({
  renderSubRows,
  item,
  isCombiningHover
}) => {
  const { isExpanded, depth } = item;

  return (
    <>
      <tr
        {...item.getRowProps()}
        className={cn(styles.tr, depth || (isExpanded && styles.expanded))}
      >
        {item.cells.map((cell) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <div {...cell.getCellProps()} className={styles.td}>
              {cell.render('Cell')}
            </div>
          );
        })}
      </tr>
      {renderSubRows &&
        isExpanded &&
        renderSubRows({
          row: { ...item, isCombiningHover }
        } as unknown as SubRows)}
    </>
  );
};
