import { deserialize, object, serializable } from 'serializr';

import { Token } from './Token.entity';

export class AuthToken {
  @serializable(object(Token))
  access: Token = new Token();

  @serializable(object(Token))
  refresh: Token = new Token();

  static deserialize(json: Object | string): AuthToken {
    return deserialize(AuthToken, json);
  }
}
