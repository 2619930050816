import { createTheme } from '@mui/material/styles';

import styles from 'scss/core.module.scss';

const TOP_NAV_HEIGHT = 64;
const LEFT_NAV_WIDTH = 240;

export const theme = createTheme({
  typography: {
    fontFamily: 'Open Sans'
  },
  palette: {
    primary: {
      main: styles.Blue,
      light: styles.HoverBlue
    },
    secondary: {
      main: styles.SoftGray
    },
    error: {
      main: styles.RoseRed
    },
    warning: {
      main: styles.DarkCrGold
    },
    info: {
      main: styles.HoverBlue
    },
    success: {
      main: styles.GreenCr
    }
  },
  shape: {
    borderRadius: 2
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: 'none',
          fontWeight: 700
        },
        contained: {
          boxShadow: 'none'
        }
      }
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          width: LEFT_NAV_WIDTH,
          top: TOP_NAV_HEIGHT,
          maxHeight: 'inherit',
          boxSizing: 'border-box',
          backgroundColor: styles.Background
        }
      }
    },
    MuiCheckbox: {
      styleOverrides: {
        root: {
          color: styles.CrGray
        }
      }
    }
  }
});
