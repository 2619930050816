import React, { FC, useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import { updateTaskSchedule } from 'actions/Task/taskActions';
import { Modal } from 'components/Modal';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Learner } from 'entities/Learner.entity';
import { Schedule } from 'entities/Schedule.entity';
import { Task } from 'entities/Task.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { queryClient } from 'index';
import { DeleteEventModal } from 'views/People/Scheduler/DeleteEventModal';
import { EventForm, FormData } from 'views/People/Scheduler/EventForm';

interface Props {
  isOpen: boolean;
  eventId: Schedule['id'];
  taskTitle?: Task['title'];
  eventData: FormData;
  revertEvent?: () => void;
  onCloseModal: () => void;
  learnerId: Learner['id'];
}

export const EditEventModal: FC<Props> = ({
  isOpen,
  eventId,
  learnerId,
  taskTitle = '',
  revertEvent,
  eventData,
  onCloseModal
}) => {
  const [isDeleteEventModalOpen, setIsDeleteEventModalOpen] = useState(false);

  const { isError, isSuccess, isLoading, error, mutateAsync } = useMutation<
    Schedule,
    ApiError,
    FormData
  >(
    ({ repeat, notify, scheduledAt }: FormData) =>
      updateTaskSchedule(eventId, {
        scheduledAt,
        repeat,
        notify,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          queryKeys.userSchedulesList(learnerId)
        );
        onCloseModal();
      },
      onError: () => {
        if (revertEvent) {
          revertEvent();
        }
      }
    }
  );

  const closeModal = () => {
    if (revertEvent) {
      revertEvent();
    }
    onCloseModal();
  };

  const onToggleDeleteEventModal = useCallback(() => {
    setIsDeleteEventModalOpen(
      (prevIsDeleteEventModalOpen) => !prevIsDeleteEventModalOpen
    );
  }, []);

  return (
    <>
      <StatusSnackBar
        isError={isError}
        isSuccess={isSuccess}
        successMessage="Task successfully updated"
        errorMessage={error?.errorMessage || ErrorMessages.FailedPostRequest}
      />
      <DeleteEventModal
        eventId={eventId}
        taskTitle={taskTitle}
        learnerId={learnerId}
        eventData={eventData}
        isOpen={isDeleteEventModalOpen}
        onCloseModal={onToggleDeleteEventModal}
        onCloseEditModal={onCloseModal}
      />
      <Modal
        title="Edit schedule task"
        isOpen={isOpen}
        onCloseModal={closeModal}
      >
        <EventForm
          saveButtonText="Edit"
          isLoading={isLoading}
          onSubmit={mutateAsync}
          defaultValues={eventData}
          onCloseModal={closeModal}
          onDelete={onToggleDeleteEventModal}
        />
      </Modal>
    </>
  );
};
