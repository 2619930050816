import React, { useState } from 'react';
import { useMutation } from 'react-query';
import { Button } from '@mui/material';
import { resendEmailVerification } from 'actions/User/userActions';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';

import styles from './ResendVerificationEmailButton.module.scss';

type ResendVerificationEmailButtonProps = {
  userId: string;
};

export const ResendVerificationEmailButton: React.FC<ResendVerificationEmailButtonProps> =
  ({ userId }) => {
    const [message, setMessage] = useState('');

    const { mutateAsync, isLoading, isSuccess, status } = useMutation<
      void,
      ApiError,
      string
    >(resendEmailVerification, {
      onSuccess: () => {
        setMessage('Email was resent');
      },
      onError() {
        setMessage('Error sending email');
      }
    });

    const displayAlert = status === 'error' || status === 'success';

    return (
      <>
        {displayAlert && (
          <StatusSnackBar
            isError={!isSuccess}
            isSuccess={isSuccess}
            successMessage="Email was resent"
            errorMessage="Error sending email"
          />
        )}
        <span className={styles['status-message']}>
          <Button
            title="Resend verification email"
            size="small"
            color="secondary"
            variant="contained"
            disabled={isLoading}
            onClick={async () => {
              try {
                await mutateAsync(userId);
              } catch (e) {
                console.error(e);
              }
            }}
          >
            Resend verification email
          </Button>
          <p>{message}</p>
        </span>
      </>
    );
  };
