import React, { FC, useCallback } from 'react';
import { format } from 'date-fns';
import { Assessment } from 'entities/Assessment.entity';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts';

import styles from './EvidenceChart.module.scss';

const barChartMargin = {
  top: 20,
  right: 32,
  left: 0,
  bottom: 0
};

interface Props {
  assessments: Assessment[];
}

const firstPoint = { scorePercent: 0 };
const TICK_WIDTH = 63;

export const EvidenceChart: FC<Props> = ({ assessments }) => {
  const formatLabel = (value: Date | number) =>
    value ? format(value, 'dd-MMM-yyyy') : '0';

  const renderCustomTooltip = useCallback(({ active, payload, label }) => {
    if (!active || !label) {
      return null;
    }

    return (
      <div className={styles.tooltip}>
        Score/Date of {formatLabel(label)} = {payload[0]?.value}%
      </div>
    );
  }, []);

  const renderTick = useCallback(
    ({ payload, width, visibleTicksCount, index, x, y }) => {
      const isDisplayAllTick = width / TICK_WIDTH > visibleTicksCount;

      if (!isDisplayAllTick && index % 2 !== 0) {
        return <span />;
      }

      return (
        <g transform={`translate(${x},${y})`}>
          <text
            x={0}
            y={0}
            dy={8}
            textAnchor="end"
            className={styles.tick}
            transform="rotate(-45)"
          >
            {formatLabel(payload.value)}
          </text>
        </g>
      );
    },
    []
  );

  return (
    <ResponsiveContainer width="99%" height={240} className={styles.chart}>
      <LineChart
        width={500}
        height={240}
        data={[firstPoint, ...assessments]}
        margin={barChartMargin}
      >
        <CartesianGrid />
        <XAxis
          dataKey="createdAt"
          height={70}
          interval={0}
          tickCount={100}
          tick={renderTick}
          stroke={styles.CrGray}
        />
        <YAxis
          type="number"
          tickFormatter={(value) => `${value}%`}
          domain={[0, 100]}
          tickCount={6}
          style={{
            fontSize: '12px'
          }}
          stroke={styles.CrGray}
        />
        <Tooltip content={renderCustomTooltip} />
        <Line type="monotone" dataKey="scorePercent" stroke={styles.RoseRed} />
      </LineChart>
    </ResponsiveContainer>
  );
};
