import React, {
  CSSProperties,
  forwardRef,
  ForwardRefRenderFunction
} from 'react';

const PADDING_BOTTOM = 87;

const ElementType: ForwardRefRenderFunction<
  HTMLDivElement,
  { style: CSSProperties }
> = ({ style, ...rest }, ref) => (
  <div
    ref={ref}
    style={{
      ...style,
      height: `${parseFloat(style.height as string) + PADDING_BOTTOM}px`
    }}
    {...rest}
  />
);

export const ListElementType = forwardRef(ElementType);
