export enum TaskAssignStatus {
  All = 'all',
  Assigned = 'assigned',
  Unassigned = 'unassigned'
}

export const TaskAssignStatusLabels = Object.freeze<
  Record<TaskAssignStatus, string>
>({
  [TaskAssignStatus.All]: 'All',
  [TaskAssignStatus.Assigned]: 'Assigned',
  [TaskAssignStatus.Unassigned]: 'Unassigned'
});

export const taskAssignStatusOptions = Object.values(TaskAssignStatus).map(
  (value) => ({
    value,
    label: TaskAssignStatusLabels[value]
  })
);
