export enum TaskSort {
  ByName = 'title:ASC',
  RecentlyCreated = 'createdAt:DESC'
}

export const TaskSortLabels = Object.freeze<Record<TaskSort, string>>({
  [TaskSort.ByName]: 'By name',
  [TaskSort.RecentlyCreated]: 'Recently created'
});

export const taskSortOptions = Object.values(TaskSort).map((value) => ({
  value,
  label: TaskSortLabels[value]
}));
