import React, { FC } from 'react';
import { useMutation } from 'react-query';
import { updateRepertoireDetails } from 'actions/Repertoire/repertoireActions';
import { Modal } from 'components/Modal';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Repertoire } from 'entities/Repertoire.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { queryClient } from 'index';
import { FormData, RepertoireForm } from 'views/Settings/RepertoireForm';

interface Props {
  isOpen: boolean;
  repertoire: Repertoire;
  onCloseModal: () => void;
}

export const EditRepertoireModal: FC<Props> = ({
  repertoire,
  isOpen,
  onCloseModal
}) => {
  const { isError, isSuccess, isLoading, error, mutateAsync } = useMutation<
    Repertoire,
    ApiError,
    FormData
  >(
    (formData: FormData) =>
      updateRepertoireDetails(repertoire.id, formData.title),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeys.repertoires);

        onCloseModal();
      }
    }
  );

  return (
    <>
      <StatusSnackBar
        isError={isError}
        isSuccess={isSuccess}
        successMessage="Repertoire successfully updated"
        errorMessage={error?.errorMessage || ErrorMessages.FailedPostRequest}
      />
      <Modal
        title="Edit repertoire"
        isOpen={isOpen}
        onCloseModal={onCloseModal}
      >
        <RepertoireForm
          defaultValues={{ title: repertoire.title }}
          isLoading={isLoading}
          onSubmit={mutateAsync}
          onCloseModal={onCloseModal}
          saveButtonText="Edit repertoire"
        />
      </Modal>
    </>
  );
};
