import { AuthToken } from 'entities/AuthToken.entity';
import { LocalStorage } from 'services/LocalStorage';
import { SessionStorage } from 'services/SessionStorage';
import { AUTH_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'utils/constants';

export const saveTokenToStorage = (data: AuthToken): void => {
  LocalStorage.setItem(AUTH_TOKEN_KEY, data.access.token);
  LocalStorage.setItem(REFRESH_TOKEN_KEY, data.refresh.token);
};

export const clearStorage = (): void => {
  LocalStorage.reset();
  SessionStorage.reset();
};
