import React, { FC, useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import { LoadingButton } from '@mui/lab';
import { Grid, Snackbar, SnackbarContent } from '@mui/material';
import {
  activateUsers,
  archiveUsers,
  deactivateUsers
} from 'actions/User/userActions';
import cn from 'classnames';
import { PermissionsGate } from 'components/PermissionsGate';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Learner } from 'entities/Learner.entity';
import { User } from 'entities/User.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { UserScopes } from 'enums/UserScopes.enum';
import { queryClient } from 'index';
import { ArchiveUserModal } from 'views/People/ArchiveUserModal';

import styles from './UserStatusBar.module.scss';

interface Props {
  selectedUsers: Array<User | Learner>;
  isUpdating: boolean;
  onSuccess: () => void;
}

export const UserStatusBar: FC<Props> = ({
  selectedUsers,
  onSuccess,
  isUpdating
}) => {
  const [isOpenArchiveUserModal, setIsOpenArchiveUserModal] = useState(false);
  const [archiveUserIds, setArchiveUserIds] = useState<string[]>([]);

  const onToggleOpenArchiveUserModal = useCallback(() => {
    setIsOpenArchiveUserModal(
      (prevIsArchiveUserModalOpen) => !prevIsArchiveUserModalOpen
    );
    setArchiveUserIds(selectedUsers.map((user) => user.id));
  }, [selectedUsers]);

  const activateMutation = useMutation<void, ApiError>(
    () => activateUsers(selectedUsers.map((user) => user.id)),
    {
      onSuccess() {
        onSuccess();
      }
    }
  );

  const { isError, isSuccess, isLoading, error, mutate } = useMutation<
    void,
    ApiError
  >(() => deactivateUsers(selectedUsers.map((user) => user.id)), {
    onSuccess() {
      onSuccess();
      queryClient.invalidateQueries(queryKeys.associations);
    }
  });

  const {
    isError: isArchiveUserError,
    isSuccess: isArchiveUserSuccess,
    isLoading: isArchiveUserLoading,
    mutateAsync: archiveMutation,
    error: archiveUserError
  } = useMutation<void, ApiError>(
    () => archiveUsers(selectedUsers.map((user) => user.id)),
    {
      onSuccess() {
        onToggleOpenArchiveUserModal();
        onSuccess();
        queryClient.invalidateQueries(queryKeys.associations);
      }
    }
  );

  return (
    <>
      <StatusSnackBar
        isError={activateMutation.isError}
        isSuccess={activateMutation.isSuccess}
        errorMessage={
          activateMutation.error?.errorMessage ||
          ErrorMessages.FailedPostRequest
        }
        successMessage="User(s) successfully activated"
      />
      <StatusSnackBar
        isError={isError}
        isSuccess={isSuccess}
        successMessage="User(s) successfully deactivated"
        errorMessage={error?.errorMessage || ErrorMessages.FailedPostRequest}
      />
      <StatusSnackBar
        isError={isArchiveUserError}
        isSuccess={isArchiveUserSuccess}
        errorMessage={
          archiveUserError?.errorMessage || ErrorMessages.FailedPostRequest
        }
        successMessage="User(s) successfully archived"
      />
      <Snackbar
        open={!!selectedUsers.length}
        className={cn(styles.container, styles.snackbar)}
      >
        <SnackbarContent
          message={`You've selected ${
            selectedUsers.length === 1
              ? '1 person'
              : `${selectedUsers.length} persons`
          }`}
          action={
            <Grid container>
              <LoadingButton
                color="primary"
                variant="contained"
                onClick={() => activateMutation.mutate()}
                className={styles['action-button']}
                loading={activateMutation.isLoading || isUpdating}
                loadingPosition="center"
                disabled={isLoading || isArchiveUserLoading}
              >
                Activate
              </LoadingButton>
              <LoadingButton
                color="primary"
                variant="contained"
                onClick={() => mutate()}
                className={styles['action-button']}
                loading={isLoading || isUpdating}
                loadingPosition="center"
                disabled={activateMutation.isLoading || isArchiveUserLoading}
              >
                Deactivate
              </LoadingButton>
              <PermissionsGate scopes={[UserScopes.ArchiveUsers]}>
                <LoadingButton
                  color="error"
                  variant="contained"
                  onClick={onToggleOpenArchiveUserModal}
                  className={styles['action-button']}
                  loading={isArchiveUserLoading}
                  loadingPosition="center"
                  disabled={activateMutation.isLoading || isLoading}
                >
                  Archive
                </LoadingButton>
              </PermissionsGate>
            </Grid>
          }
          className={styles.snackbarContent}
        />
      </Snackbar>
      <ArchiveUserModal
        isOpen={isOpenArchiveUserModal}
        onCloseModal={onToggleOpenArchiveUserModal}
        onArchiveUsers={archiveMutation}
        users={archiveUserIds}
      />
    </>
  );
};
