import { useEffect, useState } from 'react';

export const useInitialLoading = (isFetching: boolean): boolean => {
  const [isInitialLoading, setInitialLoading] = useState<boolean>(true);

  useEffect(() => {
    if (!isFetching) {
      setInitialLoading(false);
    }
  }, [isFetching]);

  return isInitialLoading;
};
