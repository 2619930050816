import { deserialize, serializable } from 'serializr';

export class OrganizationReport {
  @serializable
  numberOfStaff = 0;

  @serializable
  numberOfLearners = 0;

  @serializable
  numberOfTasksAssigned = 0;

  @serializable
  numberOfSessions = 0;

  @serializable
  numberOfTaskCompletions = 0;

  @serializable
  numberOfAssessments = 0;

  @serializable
  numberOfTasksMastered = 0;

  static deserialize(json: Object | string): OrganizationReport {
    return deserialize(OrganizationReport, json);
  }
}
