import React, { FC, useMemo, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import { LoadingButton } from '@mui/lab';
import { Button, Checkbox, Chip, Grid, TextField } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import { createAssociation } from 'actions/Learner/learnerActions';
import { fetchUsers } from 'actions/User/userActions';
import { EmptyState } from 'components/EmptyState';
import { Modal } from 'components/Modal';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Association } from 'entities/Association.entity';
import { User } from 'entities/User.entity';
import { EntityStatus } from 'enums/EntityStatus.enum';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { useLoadAuthUserData } from 'hooks/Auth/useLoadAuthUserData';
import { useOrganizationBySlug } from 'hooks/Organization/useOrganizationBySlug';
import { queryClient } from 'index';
import { ACCESS_TOKEN_EXPIRATION, MAX_PAGE_LIMIT } from 'utils/constants';
import { STAFF_ROLES } from 'views/People/StaffTable/StaffTable';

import styles from './EditAssociationsModal.module.scss';

interface Props {
  isOpen: boolean;
  userId: User['id'];
  onCloseModal: () => void;
  selectedAssociation: Option[];
}

interface Option {
  title: string;
  id: string;
}

export const EditAssociationsModal: FC<Props> = ({
  userId,
  isOpen,
  onCloseModal,
  selectedAssociation
}) => {
  const { data: userData } = useLoadAuthUserData();
  const { data: organizationData } = useOrganizationBySlug();

  const [associations, setAssociations] = useState(selectedAssociation);

  const { isLoading, isError, data } = useQuery(
    queryKeys.filteredStaff(organizationData!.id, {
      statuses: [EntityStatus.Active],
      roles: STAFF_ROLES
    }),
    () =>
      fetchUsers({
        sort: ['firstName:ASC'],
        statusIn: [EntityStatus.Active],
        roleIn: STAFF_ROLES,
        organizationIdEq: organizationData?.id,
        limit: MAX_PAGE_LIMIT
      }),
    {
      staleTime: ACCESS_TOKEN_EXPIRATION,
      retry: 0
    }
  );

  const {
    isLoading: isEditAssociationsLoading,
    isError: isEditAssociationsError,
    isSuccess: isEditAssociationsSuccess,
    mutateAsync
  } = useMutation<Association, ApiError>(
    () => {
      const ids = associations.map(({ id }) => id);

      return createAssociation(userId, {
        associationIds: ids
      });
    },
    {
      onSuccess: (data) => {
        queryClient.setQueryData(
          queryKeys.userAssociations(organizationData!.id, userId),
          data
        );
        queryClient.invalidateQueries(
          queryKeys.organizationAssociations(organizationData!.id),
          { active: false }
        );

        onCloseModal();
      }
    }
  );

  const options = useMemo(
    () =>
      (data || [])?.map(({ id, fullName }) => ({
        id,
        title: fullName
      })),
    [data]
  );

  const onChangeAssociations = (values: (Association['id'] | Option)[]) => {
    setAssociations(values as Option[]);
  };

  return (
    <>
      <StatusSnackBar
        isError={isError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      <StatusSnackBar
        isError={isEditAssociationsError}
        isSuccess={isEditAssociationsSuccess}
        successMessage="Associations successfully updated"
        errorMessage={ErrorMessages.FailedPostRequest}
      />
      <Modal
        isOpen={isOpen}
        onCloseModal={onCloseModal}
        title="Edit associations"
      >
        <div className={styles.container}>
          <Autocomplete
            open={!!data?.length || isLoading}
            multiple
            disableClearable
            options={options}
            loading={isLoading}
            value={associations}
            id="choose-association"
            aria-label="Associations"
            renderTags={(tagValue, getTagProps) =>
              tagValue.map((option, index) => (
                <Chip
                  {...getTagProps({ index })}
                  key={option.title}
                  label={option.title}
                  disabled={option.id === userData?.id}
                />
              ))
            }
            renderOption={(props, option, { selected }) => (
              <li {...props}>
                <Checkbox
                  checked={selected}
                  className={styles.checkbox}
                  checkedIcon={<CheckBoxIcon fontSize="small" />}
                  icon={<CheckBoxOutlineBlankIcon fontSize="small" />}
                  inputProps={{
                    'aria-label': 'Select association'
                  }}
                />
                {option.title}
              </li>
            )}
            getOptionDisabled={(option) => option.id === userData?.id}
            isOptionEqualToValue={(option, value) => option.id === value.id}
            getOptionLabel={(option: Option) => option.title}
            renderInput={(params) => (
              <TextField {...params} label="Associations" />
            )}
            onChange={(_e, value) => {
              onChangeAssociations(value);
            }}
            className={styles.autocomplete}
            classes={{
              paper: styles.paper,
              listbox: styles.listbox
            }}
          />
          <div className={styles['empty-box']}>
            {!data?.length && !isLoading && <EmptyState />}
          </div>

          <div className={styles.footer}>
            <Grid container alignItems="stretch" spacing={2}>
              <Grid item xs={6}>
                <LoadingButton
                  fullWidth
                  type="submit"
                  color="primary"
                  variant="contained"
                  onClick={() => mutateAsync()}
                  loading={isEditAssociationsLoading}
                >
                  Save
                </LoadingButton>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  color="primary"
                  variant="outlined"
                  onClick={onCloseModal}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </Modal>
    </>
  );
};
