import { ApiError } from 'entities/ApiError.entity';
import { LearnerReport } from 'entities/LearnerReport.entity';
import { Organization } from 'entities/Organization.entity';
import { OrganizationReport } from 'entities/OrganizationReport.entity';
import { StaffReport } from 'entities/StaffReport.entity';
import { User } from 'entities/User.entity';
// eslint-disable-next-line import/no-cycle
import { ReportApi } from 'services/API/Report/ReportApi';

export const fetchOrganizationReport = async (
  organizationId: Organization['id']
): Promise<OrganizationReport> => {
  try {
    const { data } = await ReportApi.fetchOrganizationReport(organizationId);

    return OrganizationReport.deserialize(data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchStaffReport = async (
  organizationId: Organization['id']
): Promise<StaffReport[]> => {
  try {
    const { data } = await ReportApi.fetchStaffReport(organizationId);

    return StaffReport.deserializeAsList(data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchLearnersReport = async (
  organizationId: Organization['id']
): Promise<LearnerReport[]> => {
  try {
    const { data } = await ReportApi.fetchLearnersReport(organizationId);

    return LearnerReport.deserializeAsList(data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchStaffReportCSV = async (
  organizationId: Organization['id']
): Promise<string> => {
  try {
    const { data } = await ReportApi.fetchStaffReportCSV(organizationId);

    return data;
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchLearnerReportCSV = async (
  userId: User['id']
): Promise<string> => {
  try {
    const { data } = await ReportApi.fetchLearnerReportCSV(userId);

    return data;
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};
