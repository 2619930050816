import React, { FC, useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { LoadingButton } from '@mui/lab';
import { Button, Chip, FormControlLabel, Switch } from '@mui/material';
import {
  sendResetPasswordLink,
  updateEmailNotifications
} from 'actions/User/userActions';
import imagePlaceholder from 'assets/image-placeholder.png';
import cn from 'classnames';
import { If } from 'components/If';
import { InfoRow } from 'components/InfoRow';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { User } from 'entities/User.entity';
import { EntityStatus, EntityStatusLabels } from 'enums/EntityStatus.enum';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { SuccessMessages } from 'enums/SuccessMessages.enum';
import { UserRoleLabels, UserRoles } from 'enums/UserRoles.enum';
import { useLoadAuthUserData } from 'hooks/Auth/useLoadAuthUserData';
import { queryClient } from 'index';
import { ChangeLearnerPasswordForm } from 'views/People/ChangeLearnerPasswordForm';
import { EditPersonalInformationModal } from 'views/People/EditPersonalInformationModal';
import { EmailAddressVerificationChip } from 'views/People/EmailAddressVerificationChip';
import { MoveToOrganization } from 'views/People/MoveToOrganization';

import { ResendVerificationEmailButton } from '../ResendVerificationEmailButton';

import styles from './PersonalInformation.module.scss';

const EmailAddressVerification: FC<{
  id: string;
  shouldVerifyEmailAddress: boolean;
  isEmailAddressVerified?: boolean;
  email: string;
}> = ({ id, shouldVerifyEmailAddress, isEmailAddressVerified, email }) => (
  <>
    {email && <span className={styles.email}> {email} </span>}
    {shouldVerifyEmailAddress && (
      <>
        {!email && <EmailAddressVerificationChip chipType="missing" />}
        {email && !isEmailAddressVerified && (
          <>
            <EmailAddressVerificationChip chipType="unverified" />
            <ResendVerificationEmailButton userId={id} />
          </>
        )}
        {email && isEmailAddressVerified && (
          <EmailAddressVerificationChip chipType="verified" />
        )}
      </>
    )}
  </>
);

interface Props {
  data: User;
  shouldVerifyEmailAddress: boolean;
}

export const PersonalInformation: FC<Props> = ({
  data,
  shouldVerifyEmailAddress
}) => {
  const {
    id,
    avatarUrl,
    fullName,
    email,
    phoneNumber,
    role,
    status,
    username,
    isLearner,
    receiveEmailNotifications,
    isEmailVerified
  } = data;

  const { data: userData } = useLoadAuthUserData();

  const [isEditDetailsModalOpen, setIsEditDetailsModalOpen] =
    useState<boolean>(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);

  const onToggleDetailsModal = useCallback(() => {
    setIsEditDetailsModalOpen(
      (prevIsEditDetailsModalOpen) => !prevIsEditDetailsModalOpen
    );
  }, []);

  const onToggleChangePasswordModal = useCallback(() => {
    setIsChangePasswordModalOpen(
      (prevIsChangePasswordModalOpen) => !prevIsChangePasswordModalOpen
    );
  }, []);

  const { isLoading, isError, isSuccess, mutateAsync } = useMutation<
    void,
    ApiError
  >(() => sendResetPasswordLink(id), {
    retry: 0
  });

  const changePassword = useCallback(() => {
    if (isLearner) {
      setIsChangePasswordModalOpen(true);

      return;
    }

    mutateAsync();
  }, [isLearner, mutateAsync]);

  const {
    isLoading: isUpdateEmailNotificationLoading,
    isError: isUpdateEmailNotificationError,
    isSuccess: isUpdateEmailNotificationSuccess,
    error: updateEmailNotificationError,
    mutateAsync: onUpdateEmailNotifications
  } = useMutation<User, ApiError>(
    () => updateEmailNotifications(id, !receiveEmailNotifications),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(queryKeys.user(id), data);
      }
    }
  );

  return (
    <>
      <StatusSnackBar
        isError={isError}
        isSuccess={isSuccess}
        errorMessage={ErrorMessages.FailedPostRequest}
        successMessage={SuccessMessages.ResetPasswordValidEmail}
      />
      <StatusSnackBar
        isError={isUpdateEmailNotificationError}
        isSuccess={isUpdateEmailNotificationSuccess}
        errorMessage={
          updateEmailNotificationError?.errorMessage ||
          ErrorMessages.FailedPostRequest
        }
        successMessage="User Email notification setting successfully updated"
      />
      <EditPersonalInformationModal
        data={data}
        isOpen={isEditDetailsModalOpen}
        onCloseModal={onToggleDetailsModal}
      />
      <ChangeLearnerPasswordForm
        learnerId={id}
        isOpen={isChangePasswordModalOpen}
        onCloseModal={onToggleChangePasswordModal}
      />
      <Button
        size="small"
        color="secondary"
        variant="contained"
        startIcon={<ModeEditIcon />}
        onClick={onToggleDetailsModal}
      >
        Edit personal information
      </Button>

      <InfoRow label="Profile picture">
        <img
          data-testid="image"
          className={styles.image}
          alt={`${fullName} avatar`}
          src={avatarUrl || imagePlaceholder}
        />
      </InfoRow>
      <InfoRow label="Full name">
        <span className={styles['info-value']}>{fullName}</span>
      </InfoRow>
      {isLearner && shouldVerifyEmailAddress && (
        <InfoRow label="Email address" className={styles['chip-email']}>
          <EmailAddressVerification
            shouldVerifyEmailAddress={shouldVerifyEmailAddress}
            isEmailAddressVerified={isEmailVerified}
            email={email}
            id={id}
          />
        </InfoRow>
      )}
      {!isLearner && (
        <>
          <InfoRow label="Email address">
            <span className={styles.email}>{email}</span>
            <FormControlLabel
              className={styles.switch}
              control={
                <Switch
                  color="primary"
                  checked={receiveEmailNotifications}
                  disabled={isUpdateEmailNotificationLoading}
                  onChange={() => onUpdateEmailNotifications()}
                />
              }
              labelPlacement="start"
              label="Email notification"
            />
          </InfoRow>
          <InfoRow label="Phone number">
            <span className={styles['info-value']}>{phoneNumber || '-'}</span>
          </InfoRow>
        </>
      )}
      <InfoRow label="Role">
        <span className={styles['info-value']}>{UserRoleLabels[role]}</span>
      </InfoRow>
      <InfoRow label="Status" className={styles['chip-row']}>
        <Chip
          size="small"
          className={cn(
            styles.chip,
            status === EntityStatus.Active && styles.primary
          )}
          label={EntityStatusLabels[status]}
        />
      </InfoRow>
      <InfoRow label="Username">
        <span className={styles['info-value']}>{username}</span>
      </InfoRow>
      <InfoRow label="Password" className={styles['password-row']}>
        <LoadingButton
          size="small"
          color="secondary"
          variant="contained"
          loading={isLoading}
          onClick={changePassword}
        >
          Change password
        </LoadingButton>
      </InfoRow>
      <If condition={userData?.role === UserRoles.SuperAdmin}>
        <MoveToOrganization userId={id} fullName={fullName} />
      </If>
    </>
  );
};
