import React, { ChangeEvent } from 'react';
import { Search } from '@mui/icons-material';
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material';
import { taskSortOptions } from 'enums/TaskSort.enum';
import { taskStatusOptions } from 'enums/TaskStatus.enum';

import styles from './LibraryTableActionBar.module.scss';

interface Props {
  searchValue: string;
  searchLabel?: string;
  statusValue?: string;
  sortValue?: string;
  onChangeSearchValue: (value: string) => void;
  onChangeStatusValue: (e: SelectChangeEvent<string>) => void;
  onChangeSortValue: (e: SelectChangeEvent<string>) => void;
}

export const LibraryTableActionBar: React.FC<Props> = ({
  statusValue,
  searchValue,
  searchLabel,
  sortValue,
  onChangeSearchValue,
  onChangeStatusValue,
  onChangeSortValue
}) => {
  return (
    <Grid container className={styles['table-action-bar']}>
      <Grid item xs={2}>
        <FormControl className={styles.select}>
          <InputLabel htmlFor="task-status-filter">Filter</InputLabel>
          <Select
            size="small"
            value={statusValue}
            data-testid="action-bar-select"
            onChange={onChangeStatusValue}
            defaultValue={taskStatusOptions[0]?.value}
            input={<OutlinedInput label="Filter" id="task-status-filter" />}
          >
            {taskStatusOptions.map(({ label, value }) => (
              <MenuItem
                key={value}
                value={value}
                data-testid="action-bar-option"
              >
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={4}>
        <TextField
          size="small"
          role="search"
          label="Search"
          variant="outlined"
          value={searchValue}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChangeSearchValue(e.target.value)
          }
          className={styles.search}
          id="outlined-lib-search-input"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search className={styles.icon} />
              </InputAdornment>
            )
          }}
          inputProps={{
            'aria-label': `${searchLabel || 'Search'}`
          }}
        />
      </Grid>

      <Grid item xs={2}>
        <FormControl className={styles.select}>
          <InputLabel htmlFor="task-sort-select">Sort</InputLabel>
          <Select
            size="small"
            value={sortValue}
            renderValue={() => 'Sort'}
            data-testid="action-sort-bar-select"
            onChange={onChangeSortValue}
            defaultValue={taskSortOptions[0]?.value}
            input={<OutlinedInput label="Sort" id="task-sort-select" />}
          >
            {taskSortOptions?.map(({ label, value }) => (
              <MenuItem
                key={value}
                value={value}
                data-testid="action-sort-bar-option"
              >
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};
