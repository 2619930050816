import axios, {
  AxiosError,
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse
} from 'axios';
import { AuthToken } from 'entities/AuthToken.entity';
import { AuthEvents } from 'enums/AuthEvents.enum';
import { LocalStorage } from 'services/LocalStorage';
import { SessionStorage } from 'services/SessionStorage';
import { AUTH_TOKEN_KEY, REFRESH_TOKEN_KEY } from 'utils/constants';
import { saveTokenToStorage } from 'utils/helpers/authHelpers';
import { AuthPublisher } from 'views/Auth/AuthProvider/AuthPublisher';

export const requestSuccessInterceptor = (
  config: AxiosRequestConfig
): AxiosRequestConfig => {
  const requestConfig = config;
  const token =
    SessionStorage.getItem(AUTH_TOKEN_KEY) ||
    LocalStorage.getItem(AUTH_TOKEN_KEY);

  if (token) {
    requestConfig.headers.Authorization = `${token}`;
  }

  return requestConfig;
};

export const responseSuccessInterceptor = (
  response: AxiosResponse
): AxiosResponse => {
  return response;
};

const HandleRedirect = (error: AxiosError) => {
  if (error?.response?.data.context.redirectTo) {
    window.location.assign(error.response.data.context.redirectTo);
  }
};

export const responseErrorInterceptor =
  (client: AxiosInstance) =>
  async (
    error: AxiosError
  ): Promise<AxiosResponse<AxiosInstance | AxiosError>> => {
    const accessToken = LocalStorage.getItem(AUTH_TOKEN_KEY);
    if (error?.response?.status === 403) {
      HandleRedirect(error);
    }

    if (!accessToken || error?.response?.status !== 401 || !error.response) {
      return Promise.reject(error);
    }

    const refreshToken = LocalStorage.getItem(REFRESH_TOKEN_KEY);

    try {
      const response = await axios.post('/auth/refresh-token', undefined, {
        baseURL: process.env.REACT_APP_BASE_URL,
        headers: {
          Authorization: refreshToken
        }
      });

      saveTokenToStorage(AuthToken.deserialize(response.data));
      // eslint-disable-next-line no-param-reassign
      error.response!.config.headers.Authorization = response.data.access.token;
      return client(error.response!.config);
    } catch (_error) {
      AuthPublisher.publish(AuthEvents.TokenExpired);

      return Promise.reject(error);
    }
  };

export abstract class ApiBase {
  protected readonly client: AxiosInstance;

  constructor(prefix = '') {
    const client = axios.create({
      baseURL: process.env.REACT_APP_BASE_URL + prefix,
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Timezone-Offset': new Date().getTimezoneOffset()
      }
    });

    client.interceptors.request.use(requestSuccessInterceptor);
    client.interceptors.response.use(
      responseSuccessInterceptor,
      responseErrorInterceptor(client)
    );

    this.client = client;
  }
}
