import React, { FC, useCallback } from 'react';
import { useInfiniteQuery } from 'react-query';
import { fetchUserActivitiesGroup } from 'actions/UserActivities/userActivitiesActions';
import { DataTable } from 'components/DataTable';
import { GetItemSizeCallback } from 'components/DataTable/DataTable';
import { SubRows } from 'components/DataTable/Row';
import { If } from 'components/If';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { endOfMonth } from 'date-fns';
import { ActivityGroup } from 'entities/ActivityGroup.entity';
import { User } from 'entities/User.entity';
import { EmptyStateMessages } from 'enums/EmptyStateMessages.enum';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { useInitialLoading } from 'hooks/useInitialLoading';
import { ACCESS_TOKEN_EXPIRATION, DEFAULT_PAGE_LIMIT } from 'utils/constants';

import { columns } from './columns';
import { TimeSpentSubRows } from './TimeSpentSubRows';

import styles from './TimeSpent.module.scss';

interface Props {
  userId: User['id'];
}

export const TimeSpent: FC<Props> = ({ userId }) => {
  const { data, hasNextPage, fetchNextPage, isFetching, isError } =
    useInfiniteQuery(
      queryKeys.userActivityGroup(userId),
      async ({ pageParam }) => {
        const activityGroups: ActivityGroup[] = await fetchUserActivitiesGroup({
          userId,
          limit: DEFAULT_PAGE_LIMIT,
          offset: pageParam?.offset || 0,
          fromTime: endOfMonth(new Date()).toISOString()
        });

        return activityGroups.map((activityGroup) => ({
          id: `${activityGroup.month}-${activityGroup.year}`,
          ...activityGroup
        }));
      },
      {
        staleTime: ACCESS_TOKEN_EXPIRATION,
        retry: 0,
        keepPreviousData: true,
        getNextPageParam: (lastPage, allPages) => {
          if (lastPage.length < DEFAULT_PAGE_LIMIT) {
            return undefined;
          }

          return {
            offset: allPages.flat().length
          };
        }
      }
    );

  const getRowSize: GetItemSizeCallback = useCallback(
    ({ expandedRows, rows, ROW_HEIGHT }) =>
      (index: number): number => {
        if (!expandedRows.includes(rows[index]?.id)) {
          return ROW_HEIGHT;
        }

        const activity = rows[index].original as ActivityGroup | undefined;
        const count = activity?.activitiesAmount || 0;

        return count < 2 ? ROW_HEIGHT * 2 : count * ROW_HEIGHT + ROW_HEIGHT;
      },
    []
  );

  const renderSubRows = useCallback(
    ({ row }: SubRows) => <TimeSpentSubRows row={row} userId={userId} />,
    [userId]
  );

  const isInitialLoading = useInitialLoading(isFetching);

  return (
    <>
      <StatusSnackBar
        isError={isError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      <div className={styles.container}>
        <LoadingOverlay loading={isInitialLoading}>
          <If condition={!isInitialLoading}>
            <DataTable
              dndDisabled
              getRowSize={getRowSize}
              isLoading={isFetching}
              hasNextPage={hasNextPage}
              onLoadMore={fetchNextPage}
              data={data?.pages.flat() || []}
              columns={columns}
              renderSubRows={renderSubRows}
              emptyMessage={EmptyStateMessages.Activities}
            />
          </If>
        </LoadingOverlay>
      </div>
    </>
  );
};
