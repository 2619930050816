import { deserialize, serializable } from 'serializr';

export class Thumbnail {
  @serializable
  url = '';

  static deserialize(json: Object | string): Thumbnail {
    return deserialize(Thumbnail, json);
  }
}
