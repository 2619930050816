import { useMutation, UseMutationResult } from 'react-query';
import { updateTaskStep } from 'actions/Task/taskActions';
import { ApiError } from 'entities/ApiError.entity';
import { StepDTO } from 'entities/DTO/StepDTO.entity';
import { Step } from 'entities/Step.entity';

interface UpdateTaskStep {
  id: string;
  taskId: string;
  step: Partial<StepDTO>;
}

export const useUpdateTaskStep = (
  onSuccess?: (data: Step) => void
): UseMutationResult<Step, ApiError, UpdateTaskStep> =>
  useMutation<Step, ApiError, UpdateTaskStep>(
    ({ taskId, id, step }) => updateTaskStep(taskId, id, { ...step }),
    {
      onSuccess: (data) => {
        if (!onSuccess) {
          return;
        }

        onSuccess(data);
      }
    }
  );
