import React, { FC } from 'react';
import { Box, Grid, Link } from '@mui/material';
import logo from 'assets/logo.svg';
import playStoreLogo from 'assets/logo-app-store-google.svg';
import appleStoreLogo from 'assets/logo-app-store-ios.svg';
import appsQrCode from 'assets/qr-code-avail-app.png';

import styles from './LoginPage.module.scss';

interface Props {
  title: string;
}

export const LoginPage: FC<Props> = ({ title, children }) => (
  <Grid container alignItems="stretch" className={styles['login-page']}>
    <Grid item xs={12} md={6} className={styles.banner}>
      <h2 className={styles.title}>Creating a lifetime of independence</h2>
      <Box sx={{ mb: 8 }}>
        <p className={styles['apps-subtitle']}>
          Download the App (Must Be Using Phone/Tablet).
        </p>
        <Box
          display="flex"
          justifyContent="flex-start"
          alignItems="center"
          marginTop={4}
          gap={4}
        >
          <Link
            href="https://apps.apple.com/us/app/avail-by-centralreach/id1611404570"
            target="_blank"
            title="Download App on Apple App Store"
            className={styles['app-logo']}
          >
            <img src={appleStoreLogo} alt="Download App on Apple App Store" />
          </Link>
          <Link
            target="_blank"
            title="Download App on Google Play Store"
            href="https://play.google.com/store/apps/details?id=com.centralreach.avail"
            className={styles['app-logo']}
          >
            <img src={playStoreLogo} alt="Download App on Google Play Store" />
          </Link>
          <img
            src={appsQrCode}
            alt="Download Mobile Apps"
            className={styles['qr-code']}
          />
        </Box>
      </Box>
      <p className={styles.subtitle}>
        For more information, please visit our&nbsp;
        <Link
          color="inherit"
          underline="always"
          href="https://centralreach.com/product-privacy-policy/"
        >
          privacy policy
        </Link>
        .
      </p>
    </Grid>
    <Grid item xs={12} md={6} className={styles.content}>
      <img src={logo} alt="avail logo" className={styles.logo} />
      <div>
        <h3 className={styles['login-title']}>{title}</h3>
        {children}
      </div>
      <div className={styles.info}>
        <h5>Need support to login?</h5>
        <p>
          Our development team is here to help you. To raise a support
          request&nbsp;
          <Link
            href="https://availsupport.com/contact-support/"
            underline="always"
            target="_blank"
          >
            click here
          </Link>
          .
          <br />
          Or call the avail team at: <b>+1-954-787-8090</b>
        </p>
      </div>
    </Grid>
  </Grid>
);
