import { deserialize, serializable } from 'serializr';

export class TaskSchedule {
  @serializable
  id = '';

  @serializable
  title = '';

  static deserialize(json: Object | string): TaskSchedule {
    return deserialize(TaskSchedule, json);
  }
}
