import React, { useContext } from 'react';
import { Repertoire } from 'entities/Repertoire.entity';
import { Task } from 'entities/Task.entity';

interface SharedLibraryContextData {
  search: string;
  activeRepertoire: Repertoire | null;
  activeTask: Task | null;
  onChangeSearch: (search: string) => void;
  onChangeActiveRepertoire: (repertoire: Repertoire | null) => void;
  onChangeActiveTask: (task: Task | null) => void;
}

export const sharedLibraryContext =
  React.createContext<SharedLibraryContextData>({
    search: '',
    activeRepertoire: null,
    activeTask: null,
    onChangeSearch: () => {},
    onChangeActiveRepertoire: () => {},
    onChangeActiveTask: () => {}
  });

export const SharedLibraryContextProvider = sharedLibraryContext.Provider;

export const useSharedLibraryContext = (): SharedLibraryContextData =>
  useContext(sharedLibraryContext);
