import React, { useContext } from 'react';
import { Repertoire } from 'entities/Repertoire.entity';
import { Task } from 'entities/Task.entity';
import { User } from 'entities/User.entity';
import { TaskAssignStatus } from 'enums/TaskAssignStatus.enum';
import { TaskSort } from 'enums/TaskSort.enum';
import { TaskStatus } from 'enums/TaskStatus.enum';

export type TaskColumn = Task & {
  repertoireName?: Repertoire['title'];
  createdByUserList?: User;
  assignedTo?: User;
};

interface TasksContextData {
  search: string;
  status: TaskStatus;
  assignStatus: TaskAssignStatus;
  activeFolder: TaskColumn | null;
  sort: TaskSort;
  onChangeSearch: (search: string) => void;
  onChangeStatus: (status: TaskStatus) => void;
  onChangeAssignStatus: (status: TaskAssignStatus) => void;
  onChangeActiveFolder: (id: TaskColumn | null) => void;
  onChangeSort: (sort: TaskSort) => void;
}

export const tasksContext = React.createContext<TasksContextData>({
  activeFolder: null,
  status: TaskStatus.All,
  assignStatus: TaskAssignStatus.All,
  search: '',
  sort: TaskSort.ByName,
  onChangeStatus: () => {},
  onChangeAssignStatus: () => {},
  onChangeSearch: () => {},
  onChangeActiveFolder: () => {},
  onChangeSort: () => {}
});

export const TasksContextProvider = tasksContext.Provider;

export const useTasksContext = (): TasksContextData => useContext(tasksContext);
