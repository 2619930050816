import React, { ReactElement } from 'react';
import { Column, useTable } from 'react-table';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@mui/material';

import styles from './MuiTable.module.scss';

type Data = object & {
  id: string;
};

interface Props<T extends Data> {
  columns: Column<T>[];
  data: T[];
  stickyHeader?: boolean;
  cellWidth?: string;
}

export const MuiTable = <T extends Data>({
  columns,
  data,
  cellWidth = '20%',
  stickyHeader
}: Props<T>): ReactElement<Props<T>> => {
  const { getTableProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data
  });

  return (
    <Table
      {...getTableProps()}
      stickyHeader={stickyHeader}
      data-testid="mui-table"
      className={styles['mui-table']}
    >
      <TableHead>
        {headerGroups.map((headerGroup) => (
          // eslint-disable-next-line react/jsx-key
          <TableRow {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => (
              <TableCell
                {...column.getHeaderProps()}
                key={column.id}
                width={cellWidth}
                className={styles.header}
                data-testid="th"
              >
                {column.render('Header')}
              </TableCell>
            ))}
          </TableRow>
        ))}
      </TableHead>
      <TableBody>
        {rows.map((row) => {
          prepareRow(row);
          return (
            // eslint-disable-next-line react/jsx-key
            <TableRow {...row.getRowProps()} data-testid="tr">
              {row.cells.map((cell) => {
                return (
                  // eslint-disable-next-line react/jsx-key
                  <TableCell
                    {...cell.getCellProps()}
                    width={cellWidth}
                    className={styles.row}
                  >
                    {cell.value}
                  </TableCell>
                );
              })}
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );
};
