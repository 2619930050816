import React from 'react';
import { Column } from 'react-table';
import { Repertoire } from 'entities/Repertoire.entity';
import { RepertoireSettingAction } from 'views/Settings/RepertoireSettingAction';

import styles from './SettingPage.module.scss';

export const columns: Column<Repertoire>[] = [
  {
    id: 'repertoireName',
    Header: 'Repertoire name',
    accessor: ({ title }) => title,
    Cell: ({ value }: { value: string }) => (
      <span className={styles['row-title']}>{value}</span>
    ),
    width: 5,
    minWidth: 200
  },
  {
    id: 'action',
    Header: '',
    accessor: (repertoire) => repertoire,
    Cell: ({ value }: { value: Repertoire }) => (
      <RepertoireSettingAction repertoire={value} />
    ),
    width: 1,
    minWidth: 50
  }
];
