import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { useParams } from 'react-router-dom';
import { fetchOrganizationBySlug } from 'actions/Organization/organizationActions';
import { ApiError } from 'entities/ApiError.entity';
import { Organization } from 'entities/Organization.entity';
import { queryKeys } from 'enums/QueryKeys.enum';
import { ACCESS_TOKEN_EXPIRATION } from 'utils/constants';

export const useOrganizationBySlug = (
  options?: Omit<
    UseQueryOptions<Organization, ApiError>,
    'queryKey' | 'queryFn'
  >
): UseQueryResult<Organization, ApiError> => {
  const { slugId } = useParams<{ slugId: string }>();

  return useQuery<Organization, ApiError>(
    queryKeys.organizationDetails(slugId),
    () => fetchOrganizationBySlug(slugId),
    {
      ...options,
      retry: 0,
      staleTime: ACCESS_TOKEN_EXPIRATION
    }
  );
};
