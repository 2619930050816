import React, { FC } from 'react';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Avatar } from '@mui/material';
import imagePlaceholder from 'assets/image-placeholder.png';
import cn from 'classnames';
import { Task } from 'entities/Task.entity';
import { TaskPreview } from 'views/Task/TaskPreview';

import styles from './TaskCard.module.scss';

interface Props {
  dndDisabled?: boolean;
  id: Task['id'];
  title: Task['title'];
  className?: string;
  imageUrl: Task['imageUrl'];
  stepsAmount: Task['stepsAmount'];
}

export const TaskCard: FC<Props> = ({
  id,
  className,
  imageUrl,
  title,
  stepsAmount,
  dndDisabled
}) => {
  return (
    <div
      data-id={id}
      title={title}
      className={cn('task-card', styles['task-card'], className)}
    >
      <DragIndicatorIcon
        data-testid="drag-icon"
        className={cn(styles['drag-icon'], dndDisabled && styles.hidden)}
      />
      <Avatar
        variant="square"
        alt={`${title} icon`}
        className={styles.image}
        src={imageUrl || imagePlaceholder}
      />
      <div>
        <h5 className={styles.title}>{title}</h5>
        <TaskPreview
          taskId={id}
          taskName={title}
          buttonType="text"
          stepsAmount={stepsAmount}
          buttonClassName={styles['preview-button']}
        />
      </div>
    </div>
  );
};
