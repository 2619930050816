import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import DownloadIcon from '@mui/icons-material/Download';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, IconButton } from '@mui/material';
import { EmptyState } from 'components/EmptyState';
import { If } from 'components/If';
import { Layout } from 'components/Layout';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ErrorMessages } from 'enums/ErrorMessages.enum';

import { DateRangeSelector } from '../DateRangeSelector';

import styles from './ReportContainer.module.scss';

interface Props {
  isError: boolean;
  isLoading: boolean;
  isExportLoading: boolean;
  isManagerReport?: boolean;
  title: string;
  navTo: string;
  createdAt?: string;
  onExportCSV: () => void;
  backButtonLabel: string;
  showDateRangeSelector?: boolean;
  defaultDateRange?: {
    from: Date | null;
    to: Date | null;
  };
  onChangeDateRange?: (dateRange: {
    from: Date | null;
    to: Date | null;
  }) => void;
}

export const ReportContainer: FC<Props> = ({
  isError,
  isLoading,
  isExportLoading,
  isManagerReport = false,
  title,
  navTo,
  onExportCSV,
  createdAt,
  children,
  backButtonLabel,
  showDateRangeSelector,
  defaultDateRange,
  onChangeDateRange
}) => {
  const history = useHistory();

  const onChangeDates = (dateRange: { from: Date | null; to: Date | null }) => {
    onChangeDateRange?.(dateRange);
  };

  return (
    <Layout loading={isLoading}>
      <StatusSnackBar
        isError={isError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      <If condition={!createdAt && !isLoading}>
        <EmptyState />
      </If>
      <If condition={!!createdAt}>
        <div className={styles['report-container']}>
          <div className={styles['additional-info']}>
            <div className={styles.navigation}>
              <IconButton
                color="secondary"
                aria-label={backButtonLabel}
                data-testid="back-button"
                className={styles.icon}
                onClick={() => history.push(navTo)}
              >
                <ArrowBackIcon />
              </IconButton>
              Back
            </div>
            <div>
              <span className={styles['date-title']}>
                Account created:
                <span className={styles.date}>{` on ${createdAt}`}</span>
              </span>
              <LoadingButton
                size="small"
                color="secondary"
                variant="contained"
                onClick={onExportCSV}
                loading={isExportLoading}
                startIcon={<DownloadIcon />}
                className={styles['download-button']}
              >
                Download as CSV
              </LoadingButton>
              <Button
                size="small"
                color="secondary"
                variant="contained"
                startIcon={<DownloadIcon />}
                onClick={() => {
                  document.title = `avail ${
                    isManagerReport ? 'Manager' : 'Learner'
                  } Report`;
                  window.print();
                  document.title = 'avail';
                }}
                className={styles['download-button']}
              >
                Download as PDF
              </Button>
            </div>
          </div>
          <h1 className={styles.title}>
            <Grid container>
              <Grid item xs={12} md={4}>
                {title}
              </Grid>
              <Grid item xs={12} md={8} className={styles.dateRangePicker}>
                {showDateRangeSelector && (
                  <DateRangeSelector
                    defaultRange={defaultDateRange ?? { from: null, to: null }}
                    onChange={onChangeDates}
                  />
                )}
              </Grid>
              {defaultDateRange &&
                defaultDateRange.from &&
                defaultDateRange.to && (
                  <Grid className={styles.dateRangeValues}>
                    {`${defaultDateRange.from.toLocaleDateString()}
                    -
                    ${defaultDateRange.to.toLocaleDateString()}`}
                  </Grid>
                )}
            </Grid>
          </h1>
          {children}
        </div>
      </If>
    </Layout>
  );
};
