import React, { FC, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { Modal } from 'components/Modal';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { Step } from 'entities/Step.entity';
import { Task } from 'entities/Task.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { useUpdateTaskStep } from 'hooks/Task/useUpdateTaskStep';
import { queryClient } from 'index';
import { getMillisecondsFromDate } from 'utils/helpers/timeHelpers';
import { FormData, StepForm } from 'views/Task/StepForm';

interface Props {
  isOpen: boolean;
  step: Step;
  onCloseModal: () => void;
  onUpdateStep: (step: Step) => void;
}

export const EditStepModal: FC<Props> = ({
  step,
  isOpen,
  onCloseModal,
  onUpdateStep
}) => {
  const { id: taskId } = useParams<{ id: Task['id'] }>();

  const { isSuccess, isLoading, isError, error, mutateAsync } =
    useUpdateTaskStep((data: Step) => {
      queryClient.invalidateQueries(queryKeys.taskAssetHistoryDetails(taskId));

      onUpdateStep(data);
      onCloseModal();
    });

  const {
    id,
    timer,
    text,
    title,
    image,
    video,
    audio,
    videoUrl,
    imageUrl,
    audioUrl
  } = step;

  const onEditStep = useCallback(
    (formData: FormData) => {
      mutateAsync({
        taskId,
        id,
        step: {
          title: formData.title,
          timer: formData.timer
            ? getMillisecondsFromDate(formData.timer)
            : undefined,
          text: formData.text,
          video: formData.video !== videoUrl ? formData.video : undefined,
          audio: formData.audio !== audioUrl ? formData.audio : undefined,
          image: formData.image !== imageUrl ? formData.image : undefined
        }
      });
    },
    [mutateAsync, taskId, id, videoUrl, audioUrl, imageUrl]
  );

  return (
    <>
      <StatusSnackBar
        isError={isError}
        isSuccess={isSuccess}
        successMessage="Step successfully updated"
        errorMessage={error?.errorMessage || ErrorMessages.FailedPostRequest}
      />
      <Modal title="Edit step" isOpen={isOpen} onCloseModal={onCloseModal}>
        <StepForm
          isLoading={isLoading}
          onSubmit={onEditStep}
          defaultValues={{
            timer: timer || null,
            title,
            text,
            image: imageUrl,
            video: videoUrl,
            audio: audioUrl
          }}
          contentData={{
            image,
            video,
            audio,
            text
          }}
          onCloseModal={onCloseModal}
          saveButtonText="Save"
        />
      </Modal>
    </>
  );
};
