import React, { ReactElement, ReactNode } from 'react';
import { Column, UseExpandedRowProps, UseTableRowProps } from 'react-table';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import cn from 'classnames';
import { format } from 'date-fns';
import { Task } from 'entities/Task.entity';
import { User } from 'entities/User.entity';
import { Routes } from 'enums/Routes.enum';
import { UserTaskActionMenu } from 'views/People/TaskLibrary/UserTaskActionMenu';
import { UserTableNavCell } from 'views/People/UserTableNavCell';

import styles from './LearnerTaskLibrary.module.scss';

interface ExpandRow {
  row: UseExpandedRowProps<LearnerTaskColumn> &
    UseTableRowProps<LearnerTaskColumn>;
}
export type LearnerTaskColumn = Task & {
  user?: User;
  tasks: Task[];
  onMoveUp?: () => void;
  onMoveDown?: () => void;
  isCombiningHover?: boolean;
};

export const columns: Column<LearnerTaskColumn>[] = [
  {
    id: 'task',
    Header: 'Task',
    Cell: ({ row }: ExpandRow): ReactElement => {
      const { depth, original, isExpanded } = row;
      const { title, id, imageUrl, isFolder, stepsAmount, tasksAmount } =
        original;

      return (
        <div className={cn(depth && styles['sub-row-icon-bar'])}>
          <UserTableNavCell
            hasAvatarPlaceholder
            title={title}
            imageURL={imageUrl}
            params={{ id }}
            route={isFolder ? undefined : Routes.LearnerTaskDetails}
            isDragIconVisible
            isDragDisabled={isExpanded && !!tasksAmount}
            task={{
              id,
              title,
              stepsAmount,
              isFolder,
              tasksAmount
            }}
          />
        </div>
      );
    },
    width: 3,
    minWidth: 200,
    maxWidth: 200
  },
  {
    id: 'stepsAmount',
    Header: 'Steps',
    accessor: ({ stepsAmount, isFolder }) => (isFolder ? '' : stepsAmount || 0),
    width: 1,
    minWidth: 70
  },
  {
    id: 'user',
    Header: 'Created by',
    accessor: (task): LearnerTaskColumn => task,
    Cell: ({ value }: { value: LearnerTaskColumn }): string | ReactElement => {
      const { user } = value;

      if (!user) {
        return '-';
      }

      return (
        <UserTableNavCell
          rounded
          title={user.fullName}
          imageURL={user.avatarUrl}
          params={{ id: user.id }}
          route={Routes.StaffDetails}
        />
      );
    },
    width: 3,
    minWidth: 150
  },
  {
    id: 'createdAt',
    Header: 'Created date',
    accessor: (row) =>
      row.createdAt ? format(row.createdAt, 'd MMM yyyy') : '-',
    width: 1,
    minWidth: 100
  },
  {
    id: 'actions',
    Header: '',
    accessor: (task): LearnerTaskColumn => task,
    Cell: ({
      value,
      onMoveDown,
      onMoveUp
    }: {
      value: Task;
      onMoveDown: () => void;
      onMoveUp: () => void;
    }) => (
      <UserTaskActionMenu
        task={value}
        onMoveDown={onMoveDown}
        onMoveUp={onMoveUp}
      />
    ),
    width: 2,
    minWidth: 100
  },
  {
    id: 'expander',
    Header: '',
    Cell: ({ row }: ExpandRow): ReactNode => {
      const { original, getToggleRowExpandedProps, isExpanded } = row;

      return original.isFolder && original.tasksAmount ? (
        <span {...getToggleRowExpandedProps()}>
          <ExpandMoreIcon
            className={cn(
              styles['expand-icon'],
              isExpanded && styles['expand-icon-active']
            )}
          />
        </span>
      ) : null;
    },
    width: 30,
    minWidth: 30
  }
];
