import React, { FC } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { DateTimePicker, LoadingButton, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import {
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Switch,
  TextField
} from '@mui/material';
import { If } from 'components/If';
import { isBefore } from 'date-fns';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import {
  ScheduleRepeatType,
  ScheduleRepeatTypeLabels
} from 'enums/ScheduleRepeatType.enum';

import styles from './EventForm.module.scss';

const scheduleRepeatTypes = Object.values(ScheduleRepeatType).map((value) => ({
  value,
  label: ScheduleRepeatTypeLabels[value]
}));

interface Props {
  defaultValues?: Partial<FormData>;
  onCloseModal: () => void;
  onDelete?: () => void;
  onSubmit: (task: FormData) => void;
  isLoading: boolean;
  saveButtonText: string;
}

export interface FormData {
  scheduledAt: Date;
  repeat: ScheduleRepeatType;
  notify: boolean;
}

export const EventForm: FC<Props> = ({
  defaultValues,
  onCloseModal,
  onDelete,
  isLoading,
  onSubmit,
  saveButtonText
}) => {
  const methods = useForm<FormData>({
    defaultValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false
  });

  const {
    control,
    handleSubmit,
    formState: { errors }
  } = methods;

  return (
    <FormProvider {...methods}>
      <form
        className={styles['event-form']}
        onSubmit={handleSubmit((formData: FormData) => onSubmit(formData))}
      >
        <Controller
          control={control}
          name="scheduledAt"
          rules={{
            validate: (data: Date) => isBefore(new Date(), data)
          }}
          render={({ field: { onChange, value } }) => {
            return (
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DateTimePicker
                  renderInput={(props) => (
                    <TextField
                      {...props}
                      className={styles.input}
                      error={!!errors.scheduledAt}
                      helperText={
                        errors?.scheduledAt &&
                        ErrorMessages.NotValidScheduledDate
                      }
                    />
                  )}
                  minDateTime={new Date()}
                  label="Select date and time"
                  value={value}
                  onChange={(newValue) => {
                    onChange(newValue);
                  }}
                />
              </LocalizationProvider>
            );
          }}
        />
        <Controller
          name="repeat"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <FormControl error={!!errors?.repeat} className={styles.select}>
              <InputLabel htmlFor="organization-type-outlined-select-label">
                Repeat
              </InputLabel>
              <Select
                {...field}
                data-testid="event-repeat-type-select"
                input={
                  <OutlinedInput
                    label="Repeat"
                    id="organization-type-outlined-select-label"
                  />
                }
              >
                {scheduleRepeatTypes.map(({ value, label }) => (
                  <MenuItem
                    key={value}
                    value={value}
                    data-testid="event-repeat-type-option"
                  >
                    {label}
                  </MenuItem>
                ))}
              </Select>
              <If condition={!!errors?.repeat}>
                <FormHelperText>
                  {ErrorMessages.FailedRequiredField}
                </FormHelperText>
              </If>
            </FormControl>
          )}
        />
        <Controller
          control={control}
          name="notify"
          render={({ field: { onChange, value } }) => {
            return (
              <FormControlLabel
                className={styles.switch}
                control={
                  <Switch
                    color="primary"
                    checked={value}
                    onChange={({ target: { checked } }) => onChange(checked)}
                  />
                }
                label="Alert notifications"
              />
            );
          }}
        />
        <div className={styles.footer}>
          <Grid container alignItems="stretch" spacing={2}>
            <Grid item xs={onDelete ? 5 : 6}>
              <LoadingButton
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
                loading={isLoading}
              >
                {saveButtonText}
              </LoadingButton>
            </Grid>
            <Grid item xs={onDelete ? 5 : 6}>
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                onClick={onCloseModal}
              >
                Cancel
              </Button>
            </Grid>
            {onDelete && (
              <Grid item xs={2}>
                <Button
                  fullWidth
                  color="error"
                  variant="contained"
                  onClick={onDelete}
                >
                  Delete
                </Button>
              </Grid>
            )}
          </Grid>
        </div>
      </form>
    </FormProvider>
  );
};
