import React, { ReactNode } from 'react';
import { Column, UseExpandedRowProps, UseTableRowProps } from 'react-table';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import cn from 'classnames';
import { ActivityGroup } from 'entities/ActivityGroup.entity';
import {
  formatDateNumberToString,
  formatToMinutesAndSeconds
} from 'utils/helpers/timeHelpers';

import styles from './DurationTab.module.scss';

type MonthProps = {
  year: ActivityGroup['year'];
  month: ActivityGroup['month'];
};

export type ActivityGroupColumn = ActivityGroup & {
  id: string;
};

interface ExpandRow {
  row: UseExpandedRowProps<ActivityGroupColumn> &
    UseTableRowProps<ActivityGroupColumn>;
}

export const columns: Column<ActivityGroupColumn>[] = [
  {
    id: 'month',
    Header: 'Month',
    accessor: ({ year, month }): MonthProps => ({ year, month }),
    Cell: ({ value }: { value: MonthProps }) => (
      <span className={styles['month-row']}>
        {formatDateNumberToString(value.month, value.year)}
      </span>
    ),
    width: 3,
    minWidth: 200
  },
  {
    id: 'activitiesAmount',
    Header: 'Times active',
    accessor: ({ activitiesAmount }) => activitiesAmount || 0,
    width: 3,
    minWidth: 150
  },
  {
    id: 'averageTimeSec',
    Header: 'Average Time',
    accessor: ({ averageTimeSec }) =>
      formatToMinutesAndSeconds(averageTimeSec * 1000, "m' min' s' sec'"),
    width: 3,
    minWidth: 150
  }
];

export const reportColumns: Column<ActivityGroupColumn>[] = [
  {
    id: 'month',
    Header: 'Month',
    accessor: ({ year, month }): MonthProps => ({ year, month }),
    Cell: ({ value }: { value: MonthProps }) => (
      <span className={styles['month-row']}>
        {formatDateNumberToString(value.month, value.year)}
      </span>
    ),
    width: 3,
    minWidth: 200
  },
  {
    id: 'activitiesAmount',
    Header: 'Times active',
    accessor: () => '',
    width: 3,
    minWidth: 150
  },
  {
    id: 'averageTimeSec',
    Header: 'Average Time',
    accessor: () => '',
    width: 3,
    minWidth: 150
  }
];

export const collapsableColumns: Column<ActivityGroupColumn>[] = [
  ...columns,
  {
    id: 'actions',
    Header: '',
    width: 1,
    minWidth: 50,
    Cell: ({ row }: ExpandRow): ReactNode => (
      <span
        {...row.getToggleRowExpandedProps()}
        className={cn(!row.original.activitiesAmount && styles.disabled)}
      >
        <ExpandMoreIcon
          className={cn(styles['expand-icon'], row.isExpanded && styles.active)}
        />
      </span>
    )
  }
];
