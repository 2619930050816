import { date, deserialize, serializable } from 'serializr';

export class TaskAsset {
  @serializable
  id = '';

  @serializable(date())
  createdAt: Date = new Date();

  @serializable(date())
  updatedAt: Date = new Date();

  @serializable
  taskId = '';

  @serializable
  videoStepsCount = 0;

  @serializable
  audioStepsCount = 0;

  @serializable
  imageStepsCount = 0;

  @serializable
  textStepsCount = 0;

  @serializable(date())
  changedAt: Date = new Date();

  static deserialize(json: Object | string): TaskAsset {
    return deserialize(TaskAsset, json);
  }

  static deserializeAsList(list: TaskAsset[]): TaskAsset[] {
    return list.map(TaskAsset.deserialize);
  }
}
