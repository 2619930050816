import React, { FC, Fragment, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { Search } from '@mui/icons-material';
import { LoadingButton, TabContext, TabList, TabPanel } from '@mui/lab';
import {
  Autocomplete,
  Button,
  Grid,
  InputAdornment,
  Tab,
  TextField
} from '@mui/material';
import { changeOrganizationAdmin } from 'actions/Organization/organizationActions';
import { fetchUsers } from 'actions/User/userActions';
import infoIcon from 'assets/info.svg';
import {
  ConfirmationScreen,
  ConfirmationScreenTheme
} from 'components/ConfirmationScreen';
import { EmptyState } from 'components/EmptyState';
import { IconCell } from 'components/IconCell';
import { Modal } from 'components/Modal';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Organization } from 'entities/Organization.entity';
import { User } from 'entities/User.entity';
import { EntityStatus } from 'enums/EntityStatus.enum';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { queryClient } from 'index';
import { ACCESS_TOKEN_EXPIRATION, MAX_PAGE_LIMIT } from 'utils/constants';
import { isEmailValid } from 'utils/helpers/validators';
import { STAFF_ROLES } from 'views/People/StaffTable/StaffTable';

import styles from './ChangeOrganizationAdminModal.module.scss';

interface Props {
  isOpen: boolean;
  data: Organization;
  adminData: User;
  onCloseModal: () => void;
}

interface FormData {
  admin: {
    firstName: string;
    lastName: string;
    email: string;
  };
}

const changeOrganizationAdminTabs = ['New User', 'Existing User'];

export const ChangeOrganizationAdminModal: FC<Props> = ({
  data,
  adminData,
  isOpen,
  onCloseModal
}) => {
  const { id, slugId } = data;
  const [selectedStaff, setSelectedStaff] = useState<User | null>(null);
  const [confirmationIsOpen, setConfirmationIsOpen] = useState<boolean>(false);
  const [activeTab, setActiveTab] = React.useState('0');

  const {
    control,
    setValue: setFormValue,
    getValues: getFormValues,
    handleSubmit: handleNewAdminSubmit,
    formState: { errors }
  } = useForm<FormData>({
    defaultValues: {
      admin: {
        firstName: '',
        lastName: '',
        email: ''
      }
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false
  });

  const { data: staffData, isLoading: isStaffLoading } = useQuery(
    queryKeys.filteredStaff(id, {
      statuses: [EntityStatus.Active],
      roles: STAFF_ROLES
    }),
    () =>
      fetchUsers({
        sort: ['firstName:ASC'],
        statusIn: [EntityStatus.Active],
        roleIn: STAFF_ROLES,
        organizationIdEq: id,
        limit: MAX_PAGE_LIMIT
      }),
    {
      staleTime: ACCESS_TOKEN_EXPIRATION,
      retry: 0
    }
  );

  const { isLoading, isError, isSuccess, error, mutateAsync } = useMutation<
    Organization,
    ApiError,
    FormData
  >(
    (formData: FormData) =>
      changeOrganizationAdmin(id, selectedStaff?.id || '', formData.admin),
    {
      onSuccess: (data) => {
        queryClient.setQueryData(queryKeys.organizationDetails(slugId), data);
        queryClient.invalidateQueries(
          queryKeys.organizationAdmin(slugId, adminData.id)
        );
        queryClient.invalidateQueries(queryKeys.filteredStaff(id, {}));

        onCloseModal();
      }
    }
  );

  const toggleConfirmationModal = () => {
    setConfirmationIsOpen(!confirmationIsOpen);
  };

  const onSubmitConfirmationModal = () => {
    setConfirmationIsOpen(false);

    const formData = getFormValues();
    mutateAsync(formData);
  };

  const clearFormValues = () => {
    setSelectedStaff(null);
    setFormValue('admin.firstName', '');
    setFormValue('admin.lastName', '');
    setFormValue('admin.email', '');
  };

  const handleCloseModal = () => {
    clearFormValues();
    onCloseModal();
  };

  const handleTabChange = (_e: React.SyntheticEvent, newValue: string) => {
    clearFormValues();
    setActiveTab(newValue);
  };

  const getNewAdminFullName = () => {
    if (activeTab === '0') {
      return `
        ${getFormValues('admin.firstName')} 
        ${getFormValues('admin.lastName')}`;
    }
    return `
      ${selectedStaff?.firstName} 
      ${selectedStaff?.lastName}`;
  };

  return (
    <>
      <StatusSnackBar
        isError={isError}
        isSuccess={isSuccess}
        errorMessage={error?.errorMessage || ErrorMessages.FailedPostRequest}
        successMessage="Organization information successfully updated"
      />
      <Modal
        isOpen={isOpen}
        onCloseModal={onCloseModal}
        title="Replace Organization Admin"
      >
        <div className={styles['label-block']}>
          <span className={styles.label}>Current Organization Admin</span>
          <span className={styles['info-value']}>{adminData!.fullName}</span>
          <span className={styles.email}>{adminData!.email}</span>
        </div>
        <div className={styles['label-block']}>
          <span className={styles.label}>New Organization Admin</span>
        </div>
        <form
          className={styles['create-admin-form']}
          onSubmit={handleNewAdminSubmit(() => toggleConfirmationModal())}
        >
          <TabContext value={activeTab}>
            <TabList
              onChange={handleTabChange}
              aria-label="Replace Organization Admin tabs"
              className={styles.tabs}
            >
              {changeOrganizationAdminTabs.map((tab, index) => (
                <Tab
                  key={tab}
                  label={tab}
                  data-testid="change-admin-tab"
                  value={index.toString()}
                  className={styles.tab}
                />
              ))}
            </TabList>
            <TabPanel
              value="0"
              className={styles['tab-panel']}
              classes={{ root: styles['tab-panel-root'] }}
            >
              <div className={styles['create-organization-admin']}>
                <Controller
                  rules={{ required: true }}
                  name="admin.firstName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="text"
                      variant="outlined"
                      margin="normal"
                      inputRef={field.ref}
                      error={!!errors?.admin?.firstName}
                      label="First name"
                      aria-invalid={!!errors?.admin?.firstName}
                      helperText={
                        errors?.admin?.firstName &&
                        ErrorMessages.FailedRequiredField
                      }
                      id="outlined-organization-first-name-input"
                    />
                  )}
                />
                <Controller
                  rules={{ required: true }}
                  name="admin.lastName"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="text"
                      label="Last name"
                      margin="normal"
                      variant="outlined"
                      error={!!errors?.admin?.lastName}
                      id="outlined-last-name-input"
                      aria-invalid={!!errors?.admin?.lastName}
                      helperText={
                        errors?.admin?.lastName &&
                        ErrorMessages.FailedRequiredField
                      }
                    />
                  )}
                />
                <Controller
                  rules={{
                    validate: (value) => {
                      if (!value) {
                        return ErrorMessages.FailedRequiredField;
                      }
                      if (!isEmailValid(value)) {
                        return ErrorMessages.NotValidEmail;
                      }

                      return true;
                    }
                  }}
                  name="admin.email"
                  control={control}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      type="text"
                      label="Email address"
                      margin="normal"
                      variant="outlined"
                      error={!!errors?.admin?.email}
                      id="outlined-email-input"
                      aria-invalid={!!errors?.admin?.email}
                      helperText={errors?.admin?.email?.message}
                    />
                  )}
                />
              </div>
            </TabPanel>
            <TabPanel
              value="1"
              className={styles['tab-panel']}
              classes={{ root: styles['tab-panel-root'] }}
            >
              <div className={styles.container}>
                <Autocomplete
                  id="choose-organization-admin"
                  aria-label="Search"
                  options={staffData || []}
                  getOptionLabel={(option: User) =>
                    `${option.firstName} ${option.lastName}`
                  }
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        <IconCell
                          rounded
                          title={option.fullName}
                          subtitle={option.username}
                          imageURL={option.avatarUrl}
                          hasAvatarPlaceholder={false}
                        />
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Search"
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: (
                          <InputAdornment position="start">
                            <Search className={styles.icon} />
                          </InputAdornment>
                        )
                      }}
                    />
                  )}
                  onChange={(_e, value) => {
                    if (typeof value !== 'string') {
                      setSelectedStaff(value);
                    }
                  }}
                  freeSolo
                  open
                  className={styles.autocomplete}
                  classes={{
                    paper: styles.paper,
                    listbox: styles.listbox
                  }}
                />
                <div className={styles['empty-box']}>
                  {!staffData?.length && !isStaffLoading && <EmptyState />}
                </div>
              </div>
            </TabPanel>
          </TabContext>
          <Grid container alignItems="stretch" spacing={2}>
            <Grid item xs={6}>
              <LoadingButton
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
                disabled={(activeTab === '1' && !selectedStaff) || isLoading}
                loading={isStaffLoading || isLoading}
              >
                Assign
              </LoadingButton>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                onClick={handleCloseModal}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </form>
      </Modal>
      <Modal isOpen={confirmationIsOpen} onCloseModal={toggleConfirmationModal}>
        <ConfirmationScreen
          icon={infoIcon}
          onCancel={toggleConfirmationModal}
          cancelButtonTitle="Cancel"
          submitButtonTitle="Confirm"
          theme={ConfirmationScreenTheme.Primary}
          onSubmit={onSubmitConfirmationModal}
        >
          <p>
            Are you sure you want to make {getNewAdminFullName()} the new
            Organization Admin? <br />
          </p>
        </ConfirmationScreen>
      </Modal>
    </>
  );
};
