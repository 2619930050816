import React, { FC } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { LoadingButton, LocalizationProvider, TimePicker } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Button, Grid, TextField } from '@mui/material';
import { File } from 'entities/File.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { ContentUploader } from 'views/Task/ContentUploader';

import styles from './StepForm.module.scss';

interface Props {
  defaultValues?: Partial<FormData>;
  onCloseModal: () => void;
  onSubmit: (task: FormData) => void;
  isLoading: boolean;
  saveButtonText: string;
  contentData?: {
    image?: File | null;
    video?: File | null;
    audio?: File | null;
    text?: string | null;
  };
}

export interface FormData {
  title: string;
  text?: string;
  hasContentType?: boolean;
  timer?: number | null;
  image?: string | null;
  video?: string | null;
  audio?: string | null;
}

export const StepForm: FC<Props> = ({
  defaultValues,
  onCloseModal,
  isLoading,
  onSubmit,
  contentData,
  saveButtonText
}) => {
  const methods = useForm<FormData>({
    defaultValues,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty }
  } = methods;

  return (
    <FormProvider {...methods}>
      <form
        className={styles['step-form']}
        onSubmit={handleSubmit((formData: FormData) => onSubmit(formData))}
      >
        <Controller
          rules={{ required: true }}
          name="title"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              type="text"
              margin="normal"
              variant="outlined"
              label="Step title"
              inputRef={field.ref}
              error={!!errors?.title}
              id="outlined-title-input"
              className={styles.input}
              inputProps={{ maxLength: 30 }}
              helperText={errors?.title && ErrorMessages.FailedRequiredField}
            />
          )}
        />
        <ContentUploader contentData={contentData} />
        <Controller
          name="timer"
          control={control}
          render={({ field }) => (
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <TimePicker
                {...field}
                ampm={false}
                mask="__:__"
                disableOpenPicker
                inputFormat="mm:ss"
                label="Timer(minutes and seconds)"
                views={['minutes', 'seconds']}
                renderInput={(params) => (
                  <TextField {...params} className={styles.timer} />
                )}
              />
            </LocalizationProvider>
          )}
        />
        <div className={styles.footer}>
          <Grid container alignItems="stretch" spacing={2}>
            <Grid item xs={6}>
              <LoadingButton
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
                loading={isLoading}
                disabled={!isDirty}
              >
                {saveButtonText}
              </LoadingButton>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                onClick={onCloseModal}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    </FormProvider>
  );
};
