import { list, primitive, serializable, serialize } from 'serializr';

export class AssociationDTO {
  @serializable(list(primitive()))
  associationIds: string[] = [];

  static serialize(json: Object | string): AssociationDTO {
    return serialize(AssociationDTO, json);
  }
}
