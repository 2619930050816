import React, { useState } from 'react';
import { Repertoire } from 'entities/Repertoire.entity';
import { Task } from 'entities/Task.entity';
import { SharedLibraryContextProvider } from 'hooks/Task/useSharedLibraryContext';

export const SharedLibraryProvider: React.FC = ({ children }) => {
  const [search, setSearch] = useState<string>('');
  const [activeRepertoire, setActiveRepertoire] = useState<Repertoire | null>(
    null
  );
  const [activeTask, setActiveTask] = useState<Task | null>(null);

  return (
    <SharedLibraryContextProvider
      value={{
        search,
        activeRepertoire,
        activeTask,
        onChangeSearch: setSearch,
        onChangeActiveRepertoire: setActiveRepertoire,
        onChangeActiveTask: setActiveTask
      }}
    >
      {children}
    </SharedLibraryContextProvider>
  );
};
