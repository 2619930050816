import React, { FC, useMemo } from 'react';
import archiveIcon from 'assets/archive.svg';
import {
  ConfirmationScreen,
  ConfirmationScreenTheme
} from 'components/ConfirmationScreen';
import { Modal } from 'components/Modal';
import { Learner } from 'entities/Learner.entity';
import { User } from 'entities/User.entity';

interface Props {
  isOpen: boolean;
  users: Array<User['id']>;
  onArchiveUsers: () => void;
  onCloseModal: () => void;
}

export const ArchiveUserModal: FC<Props> = ({
  isOpen,
  users,
  onCloseModal,
  onArchiveUsers
}) => {
  const archiveUserTitle = useMemo(
    () =>
      users.length === 1
        ? `Are you sure you want to archive "${users.length}" user?`
        : `Are you sure you want to archive ${users.length} users?`,
    [users]
  );

  return (
    <Modal isOpen={isOpen} onCloseModal={onCloseModal}>
      <ConfirmationScreen
        icon={archiveIcon}
        onSubmit={onArchiveUsers}
        onCancel={onCloseModal}
        cancelButtonTitle="Cancel"
        submitButtonTitle="Archive"
        theme={ConfirmationScreenTheme.Error}
      >
        <p>{archiveUserTitle}</p>
        <p>You will no longer be able to see these users in your People tab.</p>
      </ConfirmationScreen>
    </Modal>
  );
};
