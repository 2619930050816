import React, { useEffect } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useQueryClient } from 'react-query';
import { Redirect, Route, Switch, useHistory } from 'react-router-dom';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { ProtectedRoute } from 'components/ProtectedRoute';
import { Routes } from 'enums/Routes.enum';
import { UserScopes } from 'enums/UserScopes.enum';
import { useActivityContext } from 'hooks/Auth/useActivityContext';
import { useLoadAuthUserData } from 'hooks/Auth/useLoadAuthUserData';
import { useInitialLoading } from 'hooks/useInitialLoading';
import { LocalStorage } from 'services/LocalStorage';
import {
  ACTIVITY_IDLE_TIME,
  ACTIVITY_PING_TIME,
  AUTH_TOKEN_KEY
} from 'utils/constants';
import { clearStorage } from 'utils/helpers/authHelpers';
import { AccountPage } from 'views/Account/AccountPage';
// eslint-disable-next-line
import { ForgotPasswordForm } from 'views/Auth/FortotPasswordForm';
import { LoginForm } from 'views/Auth/LoginForm';
import { LoginPage } from 'views/Auth/LoginPage';
import { ResetPasswordForm } from 'views/Auth/ResetPasswordForm';
import { LibraryPage } from 'views/Library/LibraryPage/LibraryPage';
import { TasksProvider } from 'views/Library/TasksProvider';
import { CreateOrganizationPage } from 'views/Organizations/CreateOrganizationPage';
import { OrganizationDetailsPage } from 'views/Organizations/OrganizationDetailsPage';
import { OrganizationsPage } from 'views/Organizations/OrganizationsPage';
import { VerifyEmailPage } from 'views/People/VerifyEmail';
import { LearnerReport } from 'views/Reports/LearnerReport';
import { ManagerReport } from 'views/Reports/ManagerReport';
import { SettingPage } from 'views/Settings/SettingPage';
import { TaskDetailsPage } from 'views/Task/TaskDetailsPage';

export const App: React.FC = () => {
  const token = LocalStorage.getItem(AUTH_TOKEN_KEY);

  const { stopActivity, updateActivity, startActivity, refreshActivity } =
    useActivityContext();

  const onActive = () => {
    if (LocalStorage.getItem(AUTH_TOKEN_KEY)) {
      startActivity();
    }
  };

  useIdleTimer({
    crossTab: true,
    timeout: ACTIVITY_IDLE_TIME,
    throttle: ACTIVITY_PING_TIME,
    onActive,
    onAction: updateActivity,
    onIdle: () => stopActivity(false)
  });

  useEffect(() => {
    refreshActivity();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const queryClient = useQueryClient();
  const history = useHistory();

  const { isLoading } = useLoadAuthUserData({
    enabled: !!token,
    retry: 0,
    onError() {
      clearStorage();
      queryClient.removeQueries();
      history.replace(Routes.Login);
    }
  });

  const isInitializing = useInitialLoading(isLoading);

  return (
    <LoadingOverlay loading={isInitializing} unmountOnLoading>
      <Switch>
        <Route exact path={Routes.Root}>
          <Redirect to={Routes.Login}>Login</Redirect>
        </Route>

        <ProtectedRoute exact path={Routes.Login} forAuthenticated={false}>
          <LoginPage title="Login to your account">
            <LoginForm />
          </LoginPage>
        </ProtectedRoute>

        <ProtectedRoute
          exact
          path={Routes.ForgotPassword}
          forAuthenticated={false}
        >
          <LoginPage title="A link to reset your password will be sent to your email address">
            <ForgotPasswordForm />
          </LoginPage>
        </ProtectedRoute>

        <ProtectedRoute
          exact
          path={Routes.ResetPassword}
          forAuthenticated={false}
        >
          <LoginPage title="Set a new password">
            <ResetPasswordForm />
          </LoginPage>
        </ProtectedRoute>

        <ProtectedRoute
          exact
          path={Routes.VerifyEmail}
          forAuthenticated={false}
        >
          <VerifyEmailPage />
        </ProtectedRoute>

        <ProtectedRoute
          exact
          path={Routes.Account}
          redirectPath={Routes.Login}
          scopes={[UserScopes.AccessApp]}
        >
          <AccountPage />
        </ProtectedRoute>

        <ProtectedRoute
          exact
          path={Routes.Organizations}
          redirectPath={Routes.Login}
          scopes={[UserScopes.ViewOrganizations]}
        >
          <OrganizationsPage />
        </ProtectedRoute>

        <ProtectedRoute
          path={Routes.CreateOrganization}
          redirectPath={Routes.Login}
          scopes={[UserScopes.AccessApp]}
        >
          <CreateOrganizationPage />
        </ProtectedRoute>

        <ProtectedRoute
          path={Routes.LearnerReport}
          redirectPath={Routes.Login}
          scopes={[UserScopes.AccessApp]}
        >
          <LearnerReport />
        </ProtectedRoute>

        <ProtectedRoute
          exact
          path={Routes.OrganizationReport}
          scopes={[UserScopes.ViewManagerReport]}
        >
          <ManagerReport />
        </ProtectedRoute>

        <ProtectedRoute
          path={Routes.OrganizationDetails}
          redirectPath={Routes.Login}
          scopes={[UserScopes.AccessApp]}
        >
          <OrganizationDetailsPage />
        </ProtectedRoute>

        <ProtectedRoute
          exact
          path={Routes.Library}
          redirectPath={Routes.Login}
          scopes={[UserScopes.ViewAvailLibrary]}
        >
          <TasksProvider>
            <LibraryPage />
          </TasksProvider>
        </ProtectedRoute>

        <ProtectedRoute
          exact
          path={Routes.LibrarySettings}
          redirectPath={Routes.Login}
          scopes={[UserScopes.ViewAvailLibrary]}
        >
          <SettingPage />
        </ProtectedRoute>

        <ProtectedRoute
          exact
          path={Routes.TaskDetails}
          redirectPath={Routes.Login}
          scopes={[UserScopes.ViewTask]}
        >
          <TaskDetailsPage />
        </ProtectedRoute>

        <ProtectedRoute
          path={Routes.Dashboard}
          redirectPath={Routes.Login}
          scopes={[UserScopes.AccessApp]}
        >
          Dashboard
        </ProtectedRoute>
      </Switch>
    </LoadingOverlay>
  );
};
