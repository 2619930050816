import { useQuery, UseQueryOptions, UseQueryResult } from 'react-query';
import { loadMe } from 'actions/Auth/authActions';
import { ApiError } from 'entities/ApiError.entity';
import { User } from 'entities/User.entity';
import { queryKeys } from 'enums/QueryKeys.enum';
import { ACCESS_TOKEN_EXPIRATION } from 'utils/constants';

export const useLoadAuthUserData = (
  options?: Omit<UseQueryOptions<User, ApiError>, 'queryKey' | 'queryFn'>
): UseQueryResult<User, ApiError> =>
  useQuery<User, ApiError>(queryKeys.authenticatedUserData, loadMe, {
    staleTime: ACCESS_TOKEN_EXPIRATION,
    retry: 0,
    ...options
  });
