import { EntityStatus } from 'enums/EntityStatus.enum';
import { serializable, serialize } from 'serializr';

export class UserDTO {
  @serializable
  firstName = '';

  @serializable
  lastName = '';

  @serializable
  email?: string;

  @serializable
  phoneNumber?: string | null;

  @serializable
  status?: EntityStatus;

  @serializable
  avatar?: string | null;

  @serializable
  username?: string;

  @serializable
  role = '';

  static serialize(instance: Object): UserDTO {
    return serialize(UserDTO, instance);
  }
}
