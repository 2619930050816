import React, {
  Dispatch,
  FC,
  SetStateAction,
  useCallback,
  useMemo,
  useState
} from 'react';
import { useInfiniteQuery, useMutation } from 'react-query';
import { Search } from '@mui/icons-material';
import { Button, Grid, InputAdornment, TextField } from '@mui/material';
import { duplicateTask, fetchTasks } from 'actions/Task/taskActions';
import { If } from 'components/If';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Organization } from 'entities/Organization.entity';
import { Task } from 'entities/Task.entity';
import { EmptyStateMessages } from 'enums/EmptyStateMessages.enum';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys, TaskFilters } from 'enums/QueryKeys.enum';
import { TaskSort } from 'enums/TaskSort.enum';
import { TaskStatus } from 'enums/TaskStatus.enum';
import { queryClient } from 'index';
import { TaskDuplicateOptions } from 'services/API/Task/TaskApi';
import { useDebounce } from 'use-debounce/lib';
import { ACCESS_TOKEN_EXPIRATION, DEFAULT_PAGE_LIMIT } from 'utils/constants';
import { SharedLibraryList } from 'views/Organizations/OrganizationLibrary/SharedLibraryList';
import { SharedLibraryListItem } from 'views/Organizations/OrganizationLibrary/SharedLibraryListItem';
import { SharedLibraryModal } from 'views/SharedLibrary/SharedLibraryModal';
import { SharedLibraryProvider } from 'views/SharedLibrary/SharedLibraryProvider';

import styles from './SharedLibrary.module.scss';

interface QueryFilter {
  filters: TaskFilters;
}

interface Props {
  organization: Pick<Organization, 'id' | 'name'>;
  activeFolder: Task | null;
  onChangeActiveFolder: Dispatch<SetStateAction<Task | null>>;
  isLoading: boolean;
}

export const SharedLibrary: FC<Props> = ({
  organization,
  activeFolder,
  onChangeActiveFolder,
  isLoading
}) => {
  const [searchValue, setSearchValue] = useState<string>('');
  const [isSharedLibraryModalOpen, setIsSharedLibraryModalOpen] =
    useState(false);

  const [debouncedSearch] = useDebounce(searchValue, 1000);

  const onToggleSharedLibraryModal = useCallback(() => {
    setIsSharedLibraryModalOpen(
      (prevIsSharedLibraryModalOpen) => !prevIsSharedLibraryModalOpen
    );
  }, []);

  const options = useMemo(
    () =>
      activeFolder
        ? {
            parentIdEq: activeFolder!.id,
            parentIdIsNull: undefined
          }
        : { parentIdIsNull: true, parentIdEq: undefined },
    [activeFolder]
  );

  const {
    mutateAsync: onDuplicateTask,
    isSuccess: isDuplicateTaskSuccess,
    isError: isDuplicateTaskError,
    error: duplicateTaskError
  } = useMutation<Task, ApiError, TaskDuplicateOptions & { id: Task['id'] }>(
    ({ id, sharable, publishStatus }) =>
      duplicateTask(id, {
        sharable,
        parentId: null,
        organizationId: organization.id,
        publishStatus
      }),
    {
      retry: 0,
      onSuccess: (data) => {
        if (data.sharable) {
          queryClient.invalidateQueries(queryKeys.sharedOrgTasksList);
        } else {
          queryClient.invalidateQueries(queryKeys.tasksList, {
            predicate: ({ queryKey }) => {
              const { filters } = queryKey[queryKey.length - 1] as QueryFilter;

              return !filters?.organizationIdIsNull;
            }
          });
        }

        queryClient.invalidateQueries(queryKeys.folders);
      }
    }
  );

  const {
    data,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isError,
    isFetchingNextPage
  } = useInfiniteQuery<Task[], ApiError>(
    queryKeys.filteredSharedOrgTasksList({
      search: debouncedSearch,
      organizationIdEq: organization.id,
      sharableEq: true,
      ...options
    }),
    ({ pageParam }): Promise<Task[]> =>
      fetchTasks({
        search: debouncedSearch === '' ? undefined : debouncedSearch,
        sort: [TaskSort.ByName],
        organizationIdEq: organization.id,
        sharableEq: true,
        exclude: ['steps'],
        limit: DEFAULT_PAGE_LIMIT,
        offset: pageParam?.offset || 0,
        ...options
      }),
    {
      staleTime: ACCESS_TOKEN_EXPIRATION,
      retry: 0,
      keepPreviousData: true,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length < DEFAULT_PAGE_LIMIT) {
          return undefined;
        }

        return {
          offset: allPages.flat().length
        };
      }
    }
  );

  return (
    <>
      <StatusSnackBar
        isError={isError || isDuplicateTaskError}
        errorMessage={
          isError
            ? ErrorMessages.FailedGetRequest
            : duplicateTaskError?.errorMessage ||
              ErrorMessages.FailedPostRequest
        }
        isSuccess={isDuplicateTaskSuccess}
        successMessage="Item successfully copied"
      />
      <LoadingOverlay
        loading={(isFetching && !isFetchingNextPage) || isLoading}
      >
        <Grid
          container
          flexDirection="column"
          className={styles['shared-library']}
        >
          <h3 className={styles.header}>{organization.name} Shared library</h3>

          <Button
            size="small"
            color="primary"
            variant="contained"
            className={styles['avail-button']}
            onClick={onToggleSharedLibraryModal}
          >
            Browse avail® Library
          </Button>

          <TextField
            size="small"
            role="search"
            label="Search"
            variant="outlined"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            className={styles.search}
            id="outlined-shared-lib-search-input"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search className={styles.icon} />
                </InputAdornment>
              )
            }}
            inputProps={{
              'aria-label': 'Search for organization tasks or folders'
            }}
          />

          <If condition={!!activeFolder}>
            <SharedLibraryListItem
              task={activeFolder!}
              isActiveFolder
              onSetActiveFolder={() => onChangeActiveFolder(null)}
            />
          </If>

          <SharedLibraryList
            data={data?.pages.flat() || []}
            hasNextPage={hasNextPage}
            isLoading={isFetching}
            onLoadMore={fetchNextPage}
            onChangeActiveFolder={onChangeActiveFolder}
            emptyMessage={
              debouncedSearch
                ? EmptyStateMessages.Search
                : EmptyStateMessages.Tasks
            }
          />
        </Grid>
      </LoadingOverlay>
      <SharedLibraryProvider>
        <SharedLibraryModal
          onCloseModal={onToggleSharedLibraryModal}
          isOpen={isSharedLibraryModalOpen}
          onAddToSharedLibrary={(taskId) =>
            onDuplicateTask({
              id: taskId,
              sharable: true,
              publishStatus: TaskStatus.Published
            })
          }
          onAddToMyTasks={(taskId) =>
            onDuplicateTask({
              id: taskId,
              sharable: false,
              publishStatus: TaskStatus.Published
            })
          }
        />
      </SharedLibraryProvider>
    </>
  );
};
