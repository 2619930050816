import React from 'react';
import { generatePath } from 'react-router-dom';
import { Column, Row } from 'react-table';
import { Checkbox, Chip, Grid } from '@mui/material';
import cn from 'classnames';
import { IconCell } from 'components/IconCell';
import { format } from 'date-fns';
import { Organization } from 'entities/Organization.entity';
import { EntityStatus } from 'enums/EntityStatus.enum';
import { Routes } from 'enums/Routes.enum';

import styles from './OrganizationsPage.module.scss';

type TitleProps = {
  name: string;
  icon: string | null;
  slugId: string;
  id: string;
};

export const columns: Column<Organization>[] = [
  {
    id: 'name',
    Header: 'Organization name',
    accessor: ({ name, slugId, imageUrl, id }): TitleProps => ({
      name,
      slugId,
      id,
      icon: imageUrl
    }),
    Cell: ({ value, row }: { value: TitleProps; row: Row<Organization> }) => (
      <Grid container>
        <Checkbox
          checked={row.isSelected}
          onChange={row.getToggleRowSelectedProps().onChange}
          inputProps={{
            'aria-label': 'Select organization'
          }}
        />
        <IconCell
          imageURL={value.icon}
          title={value.name}
          navTo={generatePath(Routes.OrganizationDetails, {
            slugId: value.slugId,
            slug: value.id
          })}
        />
      </Grid>
    ),
    width: 3,
    minWidth: 300
  },
  {
    id: 'licenses',
    Header: 'Licenses',
    accessor: 'formattedLicenses',
    width: 1,
    minWidth: 100
  },
  {
    id: 'lastActive',
    Header: 'Last active',
    accessor: 'formattedLastActive',
    width: 1,
    minWidth: 130
  },
  {
    id: 'dateCreated',
    Header: 'Date created',
    accessor: (row) =>
      row.createdAt ? format(row.createdAt, 'dd MMMM yyyy') : '-',
    width: 1,
    minWidth: 130
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => (
      <Chip
        size="small"
        label={value}
        className={cn(
          styles.chip,
          value === EntityStatus.Active && styles.primary
        )}
      />
    ),
    width: 1,
    minWidth: 100
  }
];
