export const isPasswordValid = (password = ''): boolean => {
  const hasValidLength = password.length >= 8 && password.length <= 32;
  const hasNumber = /\d/.test(password);
  const hasUpperCaseChar = password.toLowerCase() !== password;
  const hasLowerCaseChar = password.toUpperCase() !== password;
  // eslint-disable-next-line
  const hasSpecialChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(
    password
  );

  return [
    hasValidLength,
    hasNumber,
    hasUpperCaseChar,
    hasLowerCaseChar,
    hasSpecialChar
  ].every(Boolean);
};

export const isPasswordMatch = (
  password = '',
  confirmPassword = ''
): boolean => {
  return !!password && password === confirmPassword;
};

export const isEmailValid = (email: string): boolean =>
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
    email.toLowerCase()
  );

export const isUsernameValid = (username: string): boolean =>
  /^(?=[a-zA-Z0-9._]{1,100}$)(?!.*[_.]{2})[^_.].*[^_.]$/.test(username);
