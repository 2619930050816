import React, { FC, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField
} from '@mui/material';
import { FileUploader } from 'components/FileUploader';
import { If } from 'components/If';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { Task } from 'entities/Task.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { useFolders } from 'hooks/Task/useFolders';
import { useRepertoires } from 'hooks/Task/useRepertoires';
import { TasksQuery } from 'services/API/Task/TaskApi';

import styles from './TaskForm.module.scss';

interface Props {
  defaultValues?: Partial<Task>;
  onCloseModal: () => void;
  onSubmit: (task: FormData) => void;
  isLoading: boolean;
  saveButtonText: string;
  taskQuery?: TasksQuery;
}

export interface FormData {
  title: string;
  repertoireId: string;
  parentId?: string;
  description?: string;
  image?: string | null;
}

export const TaskForm: FC<Props> = ({
  defaultValues,
  onCloseModal,
  isLoading,
  onSubmit,
  taskQuery,
  saveButtonText
}) => {
  const {
    control,
    handleSubmit,
    formState: { errors, isDirty }
  } = useForm({
    defaultValues: {
      repertoireId: '',
      ...defaultValues,
      image: defaultValues?.imageUrl,
      parentId: defaultValues?.parentId || 'none'
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false
  });

  const {
    data: folderData,
    isError: isFolderError,
    isLoading: isFolderLoading
  } = useFolders({ filters: taskQuery });

  const folderOptions = useMemo(
    () =>
      (folderData || [])?.map(({ title, id }) => ({
        id,
        title
      })),
    [folderData]
  );

  const folders = [{ title: 'None', id: 'none' }, ...folderOptions];

  const {
    data: repertoiresData,
    isLoading: isRepertoireLoading,
    isError: isRepertoireError
  } = useRepertoires();

  return (
    <>
      <StatusSnackBar
        isError={isFolderError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      <StatusSnackBar
        isError={isRepertoireError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      <form
        className={styles['task-form']}
        onSubmit={handleSubmit((formData: FormData) => onSubmit(formData))}
      >
        <Controller
          rules={{ required: true }}
          name="title"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              type="text"
              variant="outlined"
              label="Task title"
              margin="normal"
              inputRef={field.ref}
              error={!!errors?.title}
              inputProps={{ maxLength: 30 }}
              helperText={errors?.title && ErrorMessages.FailedRequiredField}
              id="outlined-title-input"
            />
          )}
        />

        <div className={styles['image-block']}>
          <Controller
            control={control}
            name="image"
            render={({ field: { onChange } }) => {
              return (
                <FileUploader
                  label="Task image"
                  onChangeFile={onChange}
                  fileData={defaultValues?.image}
                />
              );
            }}
          />
        </div>

        <Controller
          name="repertoireId"
          rules={{ required: true }}
          control={control}
          render={({ field }) => (
            <FormControl
              className={styles.select}
              error={!!errors?.repertoireId}
            >
              <InputLabel htmlFor="repertoire-outlined-select-label">
                Repertoire
              </InputLabel>
              <Select
                {...field}
                data-testid="repertoire-select"
                input={
                  <OutlinedInput
                    label="Repertoire"
                    id="repertoire-outlined-select-label"
                  />
                }
              >
                {repertoiresData?.map(({ id, title }) => (
                  <MenuItem key={id} value={id} data-testid="repertoire-option">
                    {title}
                  </MenuItem>
                ))}
              </Select>
              <If condition={!!errors?.repertoireId}>
                <FormHelperText>
                  {ErrorMessages.FailedRequiredField}
                </FormHelperText>
              </If>
            </FormControl>
          )}
        />

        <Controller
          name="description"
          control={control}
          render={({ field }) => (
            <TextField
              {...field}
              multiline
              minRows={2}
              maxRows={4}
              type="text"
              margin="normal"
              variant="outlined"
              label="Description"
              aria-label="Description"
              inputProps={{ maxLength: 500 }}
              id="outlined-description-input"
            />
          )}
        />

        <Controller
          name="parentId"
          control={control}
          render={({ field }) => (
            <FormControl className={styles.select} error={!!errors?.parentId}>
              <InputLabel htmlFor="folder-outlined-select-label">
                Choose folder
              </InputLabel>
              <Select
                {...field}
                data-testid="folder-select"
                input={
                  <OutlinedInput
                    label="Choose folder"
                    id="folder-outlined-select-label"
                  />
                }
              >
                {folders.map(({ id, title }) => (
                  <MenuItem key={id} value={id} data-testid="folder-option">
                    {title}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          )}
        />

        <div className={styles.footer}>
          <Grid container alignItems="stretch" spacing={2}>
            <Grid item xs={6}>
              <LoadingButton
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
                loading={isLoading}
                disabled={isRepertoireLoading || isFolderLoading || !isDirty}
              >
                {saveButtonText}
              </LoadingButton>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                onClick={onCloseModal}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </div>
      </form>
    </>
  );
};
