import { FC, useState } from 'react';
import * as React from 'react';
import { useMutation } from 'react-query';
import { LoadingButton } from '@mui/lab';
import { Box, Link, TextField } from '@mui/material';
import { resetPassword } from 'actions/Auth/authActions';
import { If } from 'components/If';
import { InfoMessage, InfoMessageTheme } from 'components/InfoMessage';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { Routes } from 'enums/Routes.enum';
import { SuccessMessages } from 'enums/SuccessMessages.enum';

import styles from './ForgotPasswordForm.module.scss';

export const ForgotPasswordForm: FC = () => {
  const [email, setEmail] = useState<string>('');
  const [emailError, setEmailError] = useState<string>('');

  const { error, mutateAsync, isLoading, isSuccess } = useMutation<
    void,
    ApiError,
    string
  >(resetPassword);

  const validateEmail = () => {
    const errorMessage = email ? '' : ErrorMessages.FailedRequiredField;

    setEmailError(errorMessage);
  };

  return (
    <Box component="form" className={styles['forgot-password-form']}>
      <StatusSnackBar
        isSuccess={isSuccess}
        successMessage={SuccessMessages.ResetPasswordValidEmail}
      />
      <If condition={!!error}>
        <InfoMessage
          theme={InfoMessageTheme.Error}
          message={error?.errorMessage || ErrorMessages.FailedPostRequest}
        />
      </If>
      <TextField
        required
        type="email"
        variant="outlined"
        label="E-mail"
        margin="normal"
        value={email}
        disabled={isSuccess}
        error={!!emailError}
        helperText={emailError}
        id="outlined-email-input"
        onBlur={validateEmail}
        onChange={({ target: { value } }) => setEmail(value)}
      />
      <LoadingButton
        variant="contained"
        color="primary"
        loading={isLoading}
        className={styles.button}
        disabled={!email || isSuccess}
        onClick={() => mutateAsync(email)}
      >
        Send password reset link
      </LoadingButton>
      <span className={styles.link}>
        Have an account?&nbsp;
        <Link underline="hover" href={Routes.Login}>
          Login
        </Link>
      </span>
    </Box>
  );
};
