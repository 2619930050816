import { Thumbnail } from 'entities/Thumbnail.entity';
import { date, deserialize, object, serializable } from 'serializr';

export class Asset {
  @serializable
  id = '';

  @serializable
  key = '';

  @serializable
  fullUrl = '';

  @serializable
  originalName = '';

  @serializable(date())
  createdAt: Date = new Date();

  @serializable(date())
  updatedAt: Date = new Date();

  @serializable(object(Thumbnail))
  thumbnail?: Thumbnail | null;

  get thumbnailUrl(): string | undefined {
    return this.thumbnail?.url;
  }

  static deserialize(json: Object | string): Asset {
    return deserialize(Asset, json);
  }
}
