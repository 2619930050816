import { ActivityStatus } from 'enums/ActivityStatus.enum';
import { date, deserialize, serializable } from 'serializr';

export class TaskActivity {
  @serializable
  id = '';

  @serializable(date())
  createdAt: Date = new Date();

  @serializable(date())
  updatedAt: Date = new Date();

  @serializable
  taskId = '';

  @serializable(date())
  startTime: Date = new Date();

  @serializable(date())
  endTime: Date = new Date();

  @serializable
  durationSec = 0;

  @serializable
  taskStatus = ActivityStatus;

  static deserialize(json: Object | string): TaskActivity {
    return deserialize(TaskActivity, json);
  }

  static deserializeAsList(list: TaskActivity[]): TaskActivity[] {
    return list.map(TaskActivity.deserialize);
  }
}
