import React, { useContext } from 'react';
import { MessageData } from 'views/Auth/MessagesProvider/MassagesProvider';

interface MessagesContextData {
  showSnackBar: (messageData: MessageData) => void;
}

export const messagesContext = React.createContext<MessagesContextData>({
  showSnackBar: () => {}
});

export const MessagesContextProvider = messagesContext.Provider;

export const useMessagesContext = (): MessagesContextData =>
  useContext(messagesContext);
