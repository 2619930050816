import { serializable, serialize } from 'serializr';

export class StepDTO {
  @serializable
  title = '';

  @serializable
  timer?: number | null;

  @serializable
  text?: string | null;

  @serializable
  active = true;

  @serializable
  image?: string | null;

  @serializable
  video?: string | null;

  @serializable
  audio?: string | null;

  @serializable
  safetyIcon?: string | null;

  static serialize(json: Object | string): StepDTO {
    return serialize(StepDTO, json);
  }
}
