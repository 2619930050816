import React, { FC } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { fetchStaffReport } from 'actions/Report/reportActions';
import { EmptyState } from 'components/EmptyState';
import { If } from 'components/If';
import { AbsoluteSpinner } from 'components/LoadingOverlay';
import { MuiTable } from 'components/MuiTable';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Organization } from 'entities/Organization.entity';
import { StaffReport } from 'entities/StaffReport.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { ACCESS_TOKEN_EXPIRATION } from 'utils/constants';

import { columns } from './columns';

interface Props {
  organizationId: Organization['id'];
}

export const StaffSection: FC<Props> = ({ organizationId }) => {
  const { slugId } = useParams<{ slugId: string }>();

  const { data, isError, isLoading } = useQuery<StaffReport[], ApiError>(
    queryKeys.organizationReportStaff(slugId),
    () => fetchStaffReport(organizationId),
    {
      retry: 0,
      staleTime: ACCESS_TOKEN_EXPIRATION
    }
  );

  return (
    <>
      <StatusSnackBar
        isError={isError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      <AbsoluteSpinner loading={isLoading} />
      <If condition={!data?.length && !isLoading}>
        <EmptyState />
      </If>
      <If condition={!!data?.length}>
        <MuiTable cellWidth="15%" data={data || []} columns={columns} />
      </If>
    </>
  );
};
