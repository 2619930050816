import * as React from 'react';
import { ChangeEvent, FC, useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Box, Link, TextField } from '@mui/material';
import { submitResetPassword } from 'actions/Auth/authActions';
import { If } from 'components/If';
import { InfoMessage, InfoMessageTheme } from 'components/InfoMessage';
import { PasswordAdornment } from 'components/PasswordAdornment';
import { PasswordWarningBanner } from 'components/PasswordWarningBanner';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { Routes } from 'enums/Routes.enum';
import { SuccessMessages } from 'enums/SuccessMessages.enum';
import * as queryString from 'query-string';
import { isPasswordMatch, isPasswordValid } from 'utils/helpers/validators';

import styles from './ResetPasswordForm.module.scss';

export const ResetPasswordForm: FC = () => {
  const [password, setPassword] = useState<string>('');
  const [confirmPassword, setConfirmPassword] = useState<string>('');
  const [showPassword, setShowPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [passwordError, setPasswordError] = useState<string>('');
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>('');

  const history = useHistory();

  const { mutateAsync, isLoading, isSuccess, error } = useMutation<
    void,
    ApiError,
    {
      password: string;
      token: string;
    }
  >(submitResetPassword);

  const validatePassword = (password: string) => {
    let errorMessage = '';

    if (!password) {
      errorMessage = ErrorMessages.FailedRequiredField;
    } else if (!isPasswordValid(password)) {
      errorMessage = 'Password is not valid';
    }

    if (isPasswordMatch(password, confirmPassword)) {
      setConfirmPasswordError('');
    } else if (confirmPassword && !isPasswordMatch(password, confirmPassword)) {
      setConfirmPasswordError(ErrorMessages.NotValidConfirmPassword);
    }

    setPasswordError(errorMessage);
  };

  const validateConfirmPassword = useCallback(
    (confirmPassword: string) => {
      let errorMessage = '';

      if (!confirmPassword) {
        errorMessage = ErrorMessages.FailedRequiredField;
      } else if (!isPasswordMatch(password, confirmPassword)) {
        errorMessage = ErrorMessages.NotValidConfirmPassword;
      }

      setConfirmPasswordError(errorMessage);
    },
    [password]
  );

  const changePassword = ({
    target: { value }
  }: ChangeEvent<HTMLInputElement>) => {
    setPassword(value);
    validatePassword(value);
  };

  const changeConfirmPassword = ({
    target: { value }
  }: ChangeEvent<HTMLInputElement>) => {
    setConfirmPassword(value);
    validateConfirmPassword(value);
  };

  const { location } = useHistory();

  const query = queryString.parse(location.search);

  if (isSuccess) {
    setTimeout(() => {
      history.push(Routes.Login);
    }, 10000);
  }

  return (
    <>
      <StatusSnackBar
        isSuccess={isSuccess}
        successMessage={SuccessMessages.PasswordSuccessfullyReset}
      />
      <PasswordWarningBanner />
      <Box component="form" className={styles['reset-password-form']}>
        <If condition={!!error}>
          <InfoMessage
            theme={InfoMessageTheme.Error}
            message={error?.errorMessage || ErrorMessages.FailedPostRequest}
          />
        </If>
        <TextField
          margin="normal"
          variant="outlined"
          required
          label="New password"
          value={password}
          disabled={isSuccess}
          error={!!passwordError}
          helperText={passwordError}
          onChange={changePassword}
          id="reset-outlined-password-input"
          type={showPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <PasswordAdornment
                isPasswordVisible={showPassword}
                onShowPassword={() => setShowPassword(!showPassword)}
              />
            )
          }}
        />
        <TextField
          required
          variant="outlined"
          label="Confirm password"
          margin="normal"
          disabled={isSuccess}
          value={confirmPassword}
          error={!!confirmPasswordError}
          helperText={confirmPasswordError}
          onChange={changeConfirmPassword}
          id="confirm-outlined-password-input"
          type={showConfirmPassword ? 'text' : 'password'}
          InputProps={{
            endAdornment: (
              <PasswordAdornment
                isPasswordVisible={showConfirmPassword}
                onShowPassword={() =>
                  setShowConfirmPassword(!showConfirmPassword)
                }
              />
            )
          }}
        />
        <LoadingButton
          variant="contained"
          color="primary"
          loading={isLoading}
          className={styles.button}
          disabled={
            !password ||
            !confirmPassword ||
            isSuccess ||
            !!passwordError ||
            !!confirmPasswordError
          }
          onClick={() => mutateAsync({ password, token: query.key as string })}
        >
          Set a new password
        </LoadingButton>
        <If condition={isSuccess}>
          <Link underline="hover" href={Routes.Login} className={styles.link}>
            Go to Login
          </Link>
        </If>
      </Box>
    </>
  );
};
