import React from 'react';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from '@mui/material';
import { PermissionsProvider } from 'services/Permissions';
import { theme } from 'utils/helpers/theme';
import { ActivityProvider } from 'views/Auth/ActivityProvider';
import { AuthProvider } from 'views/Auth/AuthProvider';
import { MassagesProvider } from 'views/Auth/MessagesProvider';

import { App } from './App';
import { reportWebVitals } from './reportWebVitals';

import './index.css';

export const queryClient = new QueryClient();

ReactDOM.render(
  <React.StrictMode>
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={theme}>
        <PermissionsProvider>
          <BrowserRouter>
            <ActivityProvider>
              <MassagesProvider>
                <AuthProvider>
                  <App />
                </AuthProvider>
              </MassagesProvider>
            </ActivityProvider>
          </BrowserRouter>
        </PermissionsProvider>
      </ThemeProvider>
      <ReactQueryDevtools initialIsOpen={false} />
    </QueryClientProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
