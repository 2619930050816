import React, { CSSProperties, FC } from 'react';
import { useQuery } from 'react-query';
import { Row as TableRow, UseTableCellProps } from 'react-table';
import { fetchUserActivities } from 'actions/UserActivities/userActivitiesActions';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { endOfMonth, startOfMonth } from 'date-fns';
import { ApiError } from 'entities/ApiError.entity';
import { User } from 'entities/User.entity';
import { UserActivity } from 'entities/UserActivity.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { ACCESS_TOKEN_EXPIRATION, MAX_PAGE_LIMIT } from 'utils/constants';
import { ExpandedActivityTableCell } from 'views/People/ExpandedActivityTableCell/ExpandedActivityTableCell';

import { ActivityGroupColumn } from './columns';

import styles from './TimeSpent.module.scss';

const labelsMap = {
  month: 'Date',
  activitiesAmount: 'Time Spent',
  averageTimeSec: 'User logged out',
  action: ''
};

interface Props {
  row: TableRow;
  userId: User['id'];
  style?: CSSProperties;
}

export const TimeSpentSubRows: FC<Props> = ({ row, style, userId }) => {
  const {
    getRowProps,
    original: { year, month },
    cells
  } = row as unknown as TableRow<ActivityGroupColumn>;
  const rowProps = getRowProps();

  const currentRowMonth = new Date(year, month, 15);

  const { data, isLoading, isError } = useQuery<UserActivity[], ApiError>(
    queryKeys.filteredUserActivity({
      userIdEq: userId,
      sort: ['startTime:DESC'],
      endTimeLt: endOfMonth(currentRowMonth).toISOString(),
      endTimeGt: startOfMonth(currentRowMonth).toISOString()
    }),
    (): Promise<UserActivity[]> => {
      return fetchUserActivities({
        limit: MAX_PAGE_LIMIT,
        userIdEq: userId,
        sort: ['startTime:DESC'],
        endTimeLt: endOfMonth(currentRowMonth).toISOString(),
        endTimeGt: startOfMonth(currentRowMonth).toISOString()
      });
    },
    {
      staleTime: ACCESS_TOKEN_EXPIRATION,
      retry: 0
    }
  );

  if (isLoading) {
    return (
      <div>
        <LoadingOverlay loading={isLoading} />
      </div>
    );
  }

  return (
    <>
      <StatusSnackBar
        isError={isError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      {!data?.length ? (
        <p className={styles['empty-state']}>No records</p>
      ) : (
        data?.map((activity: UserActivity) => {
          return (
            <div
              style={{
                ...style
              }}
              {...{ ...rowProps, key: activity.id }}
              key={activity.id}
              className={styles['sub-row']}
            >
              {cells.map((cell: UseTableCellProps<ActivityGroupColumn>) => {
                return (
                  // eslint-disable-next-line react/jsx-key
                  <div {...cell.getCellProps()}>
                    <ExpandedActivityTableCell
                      id={cell.column.id}
                      activity={activity}
                      label={labelsMap[cell.column.id]}
                    />
                  </div>
                );
              })}
            </div>
          );
        })
      )}
    </>
  );
};
