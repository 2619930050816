import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { fetchTaskDetails } from 'actions/Task/taskActions';
import { fetchUserData } from 'actions/User/userActions';
import { ApiError } from 'entities/ApiError.entity';
import { Task } from 'entities/Task.entity';
import { User } from 'entities/User.entity';
import { queryKeys } from 'enums/QueryKeys.enum';
import { ACCESS_TOKEN_EXPIRATION } from 'utils/constants';
import { getEntityName } from 'utils/helpers/commonHelpers';

import { useRepertoires } from './useRepertoires';

export interface TaskData {
  task?: Task;
  createdBy?: User;
  assignedTo?: User;
  currentUser?: User;
  isLoading: boolean;
  isError: boolean;
  repertoireName?: string;
}

export const useTaskData = (): TaskData => {
  const { userId, id: taskId } = useParams<{
    userId: string;
    id: Task['id'];
  }>();

  const {
    data,
    isSuccess,
    isError: isTaskError,
    isLoading: isTaskLoading
  } = useQuery<Task, ApiError>(
    queryKeys.taskDetails(taskId),
    () => fetchTaskDetails(taskId),
    {
      retry: 0,
      staleTime: ACCESS_TOKEN_EXPIRATION
    }
  );

  const {
    isError: isUserDetailsError,
    isLoading: isUserDetailsLoading,
    data: userData
  } = useQuery<User, ApiError>(
    queryKeys.user(userId),
    () => fetchUserData(userId),
    {
      retry: 0,
      staleTime: ACCESS_TOKEN_EXPIRATION,
      enabled: !!userId
    }
  );

  const { isLoading: isCreatedByUserLoading, data: createdByUserData } =
    useQuery<User, ApiError>(
      queryKeys.taskCreator(data?.createdById || ''),
      () => fetchUserData(data!.createdById),
      {
        retry: 0,
        enabled: isSuccess && !!data?.createdById,
        staleTime: ACCESS_TOKEN_EXPIRATION
      }
    );

  const {
    isError: isAssignedToUserError,
    isLoading: isAssignedToUserLoading,
    data: assignedToUserData
  } = useQuery<User, ApiError>(
    queryKeys.taskAssignee(data?.assignedToId || ''),
    () => fetchUserData(data!.assignedToId!),
    {
      retry: 0,
      enabled: isSuccess && !!data?.assignedToId,
      staleTime: ACCESS_TOKEN_EXPIRATION
    }
  );

  const {
    data: repertoiresData,
    isError: isRepertoireError,
    isLoading: isRepertoireLoading
  } = useRepertoires();

  const repertoireName =
    data?.repertoireId && getEntityName(data!.repertoireId, repertoiresData);

  const isLoading = useMemo(
    () =>
      isTaskLoading ||
      isUserDetailsLoading ||
      isRepertoireLoading ||
      isAssignedToUserLoading ||
      isCreatedByUserLoading,
    [
      isAssignedToUserLoading,
      isCreatedByUserLoading,
      isRepertoireLoading,
      isTaskLoading,
      isUserDetailsLoading
    ]
  );

  const isError = useMemo(
    () =>
      isTaskError ||
      isUserDetailsError ||
      isRepertoireError ||
      isAssignedToUserError,
    [isTaskError, isAssignedToUserError, isRepertoireError, isUserDetailsError]
  );

  return {
    task: data,
    assignedTo: assignedToUserData,
    createdBy: createdByUserData,
    currentUser: userData,
    repertoireName,
    isError,
    isLoading
  };
};
