export enum UserRoles {
  SuperAdmin = 'super_admin',
  Admin = 'admin',
  Manager = 'manager',
  Facilitator = 'facilitator',
  PassiveFacilitator = 'passive_facilitator',
  Learner = 'learner'
}

export const UserRoleLabels = Object.freeze<Record<UserRoles, string>>({
  [UserRoles.SuperAdmin]: 'Super admin',
  [UserRoles.Admin]: 'Admin',
  [UserRoles.Manager]: 'Manager',
  [UserRoles.Facilitator]: 'Facilitator',
  [UserRoles.PassiveFacilitator]: 'Passive facilitator',
  [UserRoles.Learner]: 'Learner'
});

export const rolesOptions = Object.values(UserRoles).map((value) => ({
  value,
  label: UserRoleLabels[value]
}));
