import React, { ReactElement } from 'react';
import { Column, UseExpandedRowProps, UseTableRowProps } from 'react-table';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Chip } from '@mui/material';
import cn from 'classnames';
import { format } from 'date-fns';
import { Task } from 'entities/Task.entity';
import { User } from 'entities/User.entity';
import { Routes } from 'enums/Routes.enum';
import { StaffActionMenu } from 'views/People/TaskLibrary/StaffActionMenu';
import { UserTableNavCell } from 'views/People/UserTableNavCell';

import styles from './StaffTaskLibrary.module.scss';

interface ExpandRow {
  row: UseExpandedRowProps<StaffTaskColumn> & UseTableRowProps<StaffTaskColumn>;
}
export type StaffTaskColumn = Task & { user?: User | null };

export const columns: Column<StaffTaskColumn>[] = [
  {
    id: 'task',
    Header: 'Task',
    Cell: ({ row }: ExpandRow): ReactElement => {
      const {
        depth,
        original: { title, imageUrl, id, tasksAmount, stepsAmount, isFolder }
      } = row;

      return (
        <div className={cn(depth && styles['sub-row-icon-bar'])}>
          <UserTableNavCell
            hasAvatarPlaceholder
            title={title}
            imageURL={imageUrl}
            params={{ id }}
            route={isFolder ? undefined : Routes.LearnerTaskDetails}
            task={{
              id,
              title,
              stepsAmount,
              isFolder,
              tasksAmount
            }}
          />
        </div>
      );
    },
    width: 3,
    minWidth: 200,
    maxWidth: 200
  },
  {
    id: 'stepsAmount',
    Header: 'Steps',
    accessor: ({ stepsAmount, isFolder }) => (isFolder ? '' : stepsAmount || 0),
    width: 1,
    minWidth: 70
  },
  {
    id: 'user',
    Header: 'Assigned to',
    accessor: (task): StaffTaskColumn => task,
    Cell: ({ value }: { value: StaffTaskColumn }): string | ReactElement =>
      value.user ? (
        <UserTableNavCell
          rounded
          title={value.user.fullName}
          imageURL={value.user.avatarUrl}
          params={{ id: value.user.id }}
          route={Routes.LearnerDetails}
        />
      ) : (
        <Chip size="small" label="Unassigned" className={styles.chip} />
      ),
    width: 3,
    minWidth: 150
  },
  {
    id: 'createdAt',
    Header: 'Created date',
    accessor: (row) =>
      row.createdAt ? format(row.createdAt, 'd MMM yyyy') : '-',
    width: 1,
    minWidth: 100
  },
  {
    id: 'actions',
    Header: '',
    accessor: (task): StaffTaskColumn => task,
    Cell: ({ row }: ExpandRow): ReactElement => {
      return (
        <div className={styles.actions}>
          {row.original.isFolder ? (
            <span {...row.getToggleRowExpandedProps()}>
              <ExpandMoreIcon
                className={cn(
                  styles['expand-icon'],
                  row.isExpanded && styles['expand-icon-active']
                )}
              />
            </span>
          ) : (
            <StaffActionMenu
              taskId={row.original.id}
              taskTitle={row.original.title}
            />
          )}
        </div>
      );
    },
    width: 3,
    minWidth: 150
  }
];
