import React, { FC, useMemo } from 'react';
import { NavLink } from 'react-router-dom';
import DragIndicatorIcon from '@mui/icons-material/DragIndicator';
import { Avatar, Button, Grid } from '@mui/material';
import availIcon from 'assets/avail-icon.png';
import imagePlaceholder from 'assets/image-placeholder.png';
import cn from 'classnames';
import { If } from 'components/If';

import styles from './IconCell.module.scss';

interface Props {
  imageURL?: string | null;
  rounded?: boolean;
  title: string;
  navTo?: string;
  subtitle?: string;
  hasAvatarPlaceholder?: boolean;
  isDragIconVisible?: boolean;
  isDragDisabled?: boolean;
  showAvailIcon?: boolean;
  onTitleClick?: () => void;
}

export const IconCell: FC<Props> = ({
  imageURL,
  rounded,
  title,
  subtitle,
  navTo,
  hasAvatarPlaceholder = true,
  isDragIconVisible,
  isDragDisabled,
  children,
  showAvailIcon,
  onTitleClick
}) => {
  const iconCellTitle = useMemo(() => {
    if (navTo) {
      return (
        <NavLink to={navTo} className={styles['nav-link']}>
          <h5 className={styles.title} title={title}>
            {title}
          </h5>
        </NavLink>
      );
    }

    if (onTitleClick) {
      return (
        <Button
          onClick={onTitleClick}
          className={styles.button}
          disableRipple
          title={title}
        >
          {title}
        </Button>
      );
    }

    return (
      <h5 className={styles.title} title={title}>
        {title}
      </h5>
    );
  }, [navTo, onTitleClick, title]);

  return (
    <Grid
      container
      spacing={1}
      flexWrap="nowrap"
      alignItems="center"
      flexDirection="row"
      className={styles['icon-cell']}
    >
      <If condition={!!isDragIconVisible}>
        <DragIndicatorIcon
          data-testid="drag-icon"
          className={cn(styles['drag-icon'], isDragDisabled && styles.disabled)}
        />
      </If>
      <Grid item className={styles.image}>
        <Avatar
          alt={`${title} icon`}
          variant={rounded ? 'circular' : 'square'}
          src={imageURL || (hasAvatarPlaceholder ? imagePlaceholder : '')}
        />
        <If condition={!!showAvailIcon}>
          <img alt="avail-logo" src={availIcon} className={styles.icon} />
        </If>
      </Grid>
      <Grid item className={styles.info}>
        {iconCellTitle}
        {subtitle && <span className={styles.subtitle}>{subtitle}</span>}
        {children}
      </Grid>
    </Grid>
  );
};
