import { Column } from 'react-table';
import { format } from 'date-fns';
import { StaffReport } from 'entities/StaffReport.entity';
import {
  formatSecondsToDays,
  formatSecondsToDuration
} from 'utils/helpers/timeHelpers';

export const columns: Column<StaffReport>[] = [
  {
    id: 'name',
    Header: 'Staff name',
    accessor: ({ name }) => name
  },
  {
    id: 'timeSpentOnAppSec',
    Header: 'Time spent on app',
    accessor: ({ timeSpentOnAppSec }) =>
      timeSpentOnAppSec
        ? formatSecondsToDays(timeSpentOnAppSec) +
          formatSecondsToDuration(timeSpentOnAppSec)
        : '-'
  },
  {
    id: 'lastActiveTime',
    Header: 'Last active date',
    accessor: ({ lastActiveTime }) =>
      lastActiveTime ? format(lastActiveTime, 'd MMM yyyy') : '-'
  },
  {
    id: 'numberOfSessions',
    Header: '№ of sessions',
    accessor: 'numberOfSessions'
  },
  {
    id: 'numberOfLearners',
    Header: '№ of Learners',
    accessor: 'numberOfLearners'
  },
  {
    id: 'numberOfTasksCreated',
    Header: '№ of tasks created',
    accessor: 'numberOfTasksCreated'
  },
  {
    id: 'numberOfTasksAssigned',
    Header: '№ of tasks assigned',
    accessor: 'numberOfTasksAssigned'
  }
];
