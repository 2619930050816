import React, { FC } from 'react';
import { useMutation } from 'react-query';
import { useParams } from 'react-router-dom';
import { inviteUser } from 'actions/Organization/organizationActions';
import { Modal } from 'components/Modal';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { User } from 'entities/User.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { useOrganizationBySlug } from 'hooks/Organization/useOrganizationBySlug';
import { queryClient } from 'index';

import { CreateStaffForm, FormData } from '../CreateStaffForm';

interface Props {
  isOpen: boolean;
  onCloseModal: () => void;
}

export const CreateStaffModal: FC<Props> = ({ isOpen, onCloseModal }) => {
  const { data: organizationData } = useOrganizationBySlug();
  const { slugId } = useParams<{ slugId: string }>();

  const { isLoading, isError, isSuccess, error, mutateAsync } = useMutation<
    User,
    ApiError,
    FormData
  >(
    (formData: FormData) =>
      inviteUser(organizationData!.id, {
        firstName: formData.firstName,
        lastName: formData.lastName,
        username: formData.username,
        email: formData.email,
        role: formData.role,
        phoneNumber: formData.phoneNumber || null,
        avatar: formData.image
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(
          queryKeys.staff(organizationData!.id)
        );
        await queryClient.invalidateQueries(
          queryKeys.organizationDetails(slugId)
        );

        onCloseModal();
      }
    }
  );

  return (
    <>
      <StatusSnackBar
        isError={isError}
        isSuccess={isSuccess}
        successMessage="Account successfully created"
        errorMessage={error?.errorMessage || ErrorMessages.FailedPostRequest}
      />
      <Modal
        title="Add new account"
        isOpen={isOpen}
        onCloseModal={onCloseModal}
      >
        <CreateStaffForm
          isLoading={isLoading}
          onSubmit={mutateAsync}
          onCloseModal={onCloseModal}
        />
      </Modal>
    </>
  );
};
