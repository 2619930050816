import { date, deserialize, serializable } from 'serializr';

export class UserActivity {
  @serializable
  id = '';

  @serializable(date())
  createdAt: Date = new Date();

  @serializable(date())
  updatedAt: Date = new Date();

  @serializable
  userId = '';

  @serializable(date())
  startTime: Date = new Date();

  @serializable(date())
  endTime: Date = new Date();

  @serializable
  durationSec = 0;

  @serializable
  userLoggedOut = false;

  @serializable
  sessionToken? = '';

  get userLoggedOutTitle(): string {
    return this.userLoggedOut ? 'Yes' : 'No';
  }

  static deserialize(json: Object | string): UserActivity {
    return deserialize(UserActivity, json);
  }

  static deserializeAsList(list: UserActivity[]): UserActivity[] {
    return list.map(UserActivity.deserialize);
  }
}
