import React, { useContext } from 'react';
import { UserRoles } from 'enums/UserRoles.enum';
import { UserScopes } from 'enums/UserScopes.enum';
import { useLoadAuthUserData } from 'hooks/Auth/useLoadAuthUserData';

type Permissions = {
  [x in UserRoles]: Set<UserScopes>;
};

export const permissionsContext = React.createContext<Permissions>({
  [UserRoles.SuperAdmin]: new Set(),
  [UserRoles.Admin]: new Set(),
  [UserRoles.Facilitator]: new Set(),
  [UserRoles.Learner]: new Set(),
  [UserRoles.Manager]: new Set(),
  [UserRoles.PassiveFacilitator]: new Set()
});

export const hasPermission = (
  permissions: Set<UserScopes>,
  scopes: UserScopes[]
): boolean => scopes.some((scope) => permissions.has(scope));

export const usePermissions = (): Permissions => useContext(permissionsContext);

export const useHasPermission = (scopes: UserScopes[]): boolean => {
  const { data } = useLoadAuthUserData();
  const permissions = usePermissions();

  return !!data && hasPermission(permissions[data.role], scopes);
};
