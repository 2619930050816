import {
  EntityStatus,
  EntityStatusLabels,
  entityStatusOptions
} from 'enums/EntityStatus.enum';
import { UserRoleLabels, UserRoles } from 'enums/UserRoles.enum';

const filterRolesOptions = [
  UserRoles.Manager,
  UserRoles.Facilitator,
  UserRoles.PassiveFacilitator
].map((value) => ({
  value,
  label: UserRoleLabels[value]
}));

export const filters = {
  '': [
    {
      value: EntityStatus.All,
      label: EntityStatusLabels[EntityStatus.All]
    }
  ],
  Role: filterRolesOptions,
  Status: entityStatusOptions.filter(
    (roleOption) => roleOption.value !== EntityStatus.All
  )
};

export const isRoleFilter = (newValue: UserRoles): boolean =>
  !!filters.Role.find((roleOption) => roleOption.value === newValue);

export const isStatusFilter = (newValue: EntityStatus): boolean =>
  !!filters.Status.find((statusOption) => statusOption.value === newValue);
