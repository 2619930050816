import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  TextField
} from '@mui/material';
import { FileUploader } from 'components/FileUploader';
import { If } from 'components/If';
import { Organization } from 'entities/Organization.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import {
  OrganizationType,
  OrganizationTypeLabels
} from 'enums/OrganizationType.enum';

import styles from './EditDetailsForm.module.scss';

const organizationTypes = Object.values(OrganizationType).map((value) => ({
  value,
  label: OrganizationTypeLabels[value]
}));

export interface FormData {
  name: string;
  address: string;
  type: string;
  description?: string;
  image?: string | null;
}

interface Props {
  data: Organization;
  isLoading: boolean;
  onCloseModal: () => void;
  onSubmit: (formData: FormData) => void;
}

export const EditDetailsForm: FC<Props> = ({
  data,
  onSubmit,
  isLoading,
  onCloseModal
}) => {
  const { name, type, address, description, imageUrl, image } = data;

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty }
  } = useForm<FormData>({
    defaultValues: {
      name,
      type,
      address,
      description,
      image: imageUrl
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false
  });

  return (
    <form
      className={styles['edit-details-form']}
      onSubmit={handleSubmit((formData) => onSubmit(formData))}
    >
      <Controller
        control={control}
        name="image"
        render={({ field: { onChange } }) => {
          return (
            <FileUploader
              fileData={image}
              onChangeFile={onChange}
              label="Organization photo"
            />
          );
        }}
      />
      <Controller
        rules={{ required: true }}
        name="name"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="text"
            variant="outlined"
            label="Organization name"
            margin="normal"
            inputRef={field.ref}
            error={!!errors?.name}
            aria-invalid={!!errors?.name}
            inputProps={{ maxLength: 30 }}
            helperText={errors?.name && ErrorMessages.FailedRequiredField}
            id="outlined-organization-name-input"
          />
        )}
      />
      <Controller
        rules={{ required: true }}
        name="address"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="text"
            variant="outlined"
            label="Address"
            margin="normal"
            error={!!errors?.address}
            aria-invalid={!!errors?.address}
            helperText={errors?.address && ErrorMessages.FailedRequiredField}
            id="outlined-organization-address-input"
          />
        )}
      />
      <Controller
        name="type"
        rules={{ required: true }}
        control={control}
        render={({ field }) => (
          <FormControl error={!!errors?.type} className={styles.select}>
            <InputLabel htmlFor="organization-type-outlined-select-label">
              Type of organization
            </InputLabel>
            <Select
              {...field}
              data-testid="organization-type-select"
              input={
                <OutlinedInput
                  label="Type of organization"
                  id="organization-type-outlined-select-label"
                />
              }
            >
              {organizationTypes.map(({ value, label }) => (
                <MenuItem
                  key={value}
                  value={value}
                  data-testid="organization-type-option"
                >
                  {label}
                </MenuItem>
              ))}
            </Select>
            <If condition={!!errors?.type}>
              <FormHelperText>
                {ErrorMessages.FailedRequiredField}
              </FormHelperText>
            </If>
          </FormControl>
        )}
      />
      <Controller
        name="description"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="text"
            variant="outlined"
            label="Description"
            margin="normal"
            inputProps={{ maxLength: 500 }}
            error={!!errors?.description}
            helperText={
              errors?.description && ErrorMessages.FailedRequiredField
            }
            id="outlined-organization-description-input"
          />
        )}
      />
      <div className={styles.footer}>
        <Grid container alignItems="stretch" spacing={2}>
          <Grid item xs={6}>
            <LoadingButton
              fullWidth
              type="submit"
              color="primary"
              variant="contained"
              disabled={!isDirty}
              loading={isLoading}
            >
              Save
            </LoadingButton>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              onClick={onCloseModal}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </div>
    </form>
  );
};
