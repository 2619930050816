import { DependencyList, EffectCallback, useEffect, useRef } from 'react';
import isEqual from 'deep-equal';

const useDeepCompareMemoize = (value: Unpacked<DependencyList>) => {
  const ref = useRef<Unpacked<DependencyList>>();

  if (!isEqual(value, ref.current)) {
    ref.current = value;
  }

  return ref.current;
};

export const useDeepCompareEffect = (
  callback: EffectCallback,
  dependencies: DependencyList
): void => {
  // eslint-disable-next-line
  useEffect(callback, dependencies.map(useDeepCompareMemoize));
};
