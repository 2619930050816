import React, { FC, useState } from 'react';
import { Alert, Snackbar } from '@mui/material';
import { AlertColor } from '@mui/material/Alert/Alert';

import styles from './SnackBar.module.scss';

interface Props {
  className?: string;
  hideDuration?: number;
  theme?: AlertColor;
  isOpen?: boolean;
  onClose?: () => void;
  message: string;
}

export const SnackBar: FC<Props> = ({
  message,
  className,
  isOpen = false,
  hideDuration = 10000,
  onClose,
  theme = 'success'
}) => {
  const [isModalOpen, setIsOpen] = useState<boolean>(isOpen);

  const closeSnackBar = () => {
    setIsOpen(false);

    if (onClose) {
      onClose();
    }
  };

  return (
    <Snackbar
      open={isModalOpen}
      className={className}
      onClose={closeSnackBar}
      autoHideDuration={hideDuration}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert severity={theme} className={styles.alert}>
        {message}
      </Alert>
    </Snackbar>
  );
};
