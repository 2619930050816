import { AxiosError } from 'axios';
import { ApiMatchErrors } from 'enums/ApiMatchErrors.enum';
import { deserialize, serializable } from 'serializr';

const fallbackError = {
  errorName: 'badRequest',
  message: 'Something went wrong',
  statusCode: 400
};

export class ApiError {
  @serializable
  errorName = '';

  @serializable
  message = '';

  @serializable
  statusCode = 400;

  get errorMessage(): string {
    return ApiMatchErrors[this.errorName] || this.message;
  }

  static deserialize(json: Object | string): ApiError {
    return deserialize(ApiError, json);
  }

  static deserializeFromCatch(error?: AxiosError): ApiError {
    const data = error?.response?.data || fallbackError;

    return ApiError.deserialize(data);
  }
}
