export enum UserTabs {
  PersonalInformation = 'PersonalInformation',
  TimeSpent = 'TimeSpent',
  Associations = 'Associations',
  TaskLibrary = 'TaskLibrary',
  Schedule = 'Schedule',
  Assessments = 'Assessments'
}

export const UserTabsLabels = Object.freeze<Record<UserTabs, string>>({
  [UserTabs.PersonalInformation]: 'Personal information',
  [UserTabs.TimeSpent]: 'Time spent on avail®',
  [UserTabs.Associations]: 'Associations',
  [UserTabs.TaskLibrary]: 'Task library',
  [UserTabs.Schedule]: 'Schedule',
  [UserTabs.Assessments]: 'Assessments'
});
