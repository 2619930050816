import React, { FC, ReactElement, ReactNode } from 'react';

interface Props {
  condition: boolean;
  wrapper: (props: ReactNode) => ReactElement;
}

export const ConditionalWrapper: FC<Props> = ({
  condition,
  wrapper,
  children
}) => {
  return <>{condition ? wrapper(children) : children}</>;
};
