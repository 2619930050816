import { ActivityGroup } from 'entities/ActivityGroup.entity';
import { ApiError } from 'entities/ApiError.entity';
import { UserActivityDTO } from 'entities/DTO/UserActivityDTO.entity';
import { UserActivity } from 'entities/UserActivity.entity';
import {
  UserActivitiesApi,
  UserActivitiesGroupQuery,
  UserActivitiesQuery
} from 'services/API/UserActivities/UserActivitiesApi';

export const fetchUserActivities = async (
  query: UserActivitiesQuery
): Promise<UserActivity[]> => {
  try {
    const { data } = await UserActivitiesApi.fetchUserActivities(query);

    return UserActivity.deserializeAsList(data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchUserActivitiesGroup = async (
  query: UserActivitiesGroupQuery
): Promise<ActivityGroup[]> => {
  try {
    const { data } = await UserActivitiesApi.fetchUserActivitiesGroup(query);

    return ActivityGroup.deserializeAsList(data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const startUserActivity = async (): Promise<UserActivity> => {
  try {
    const { data } = await UserActivitiesApi.startUserActivity();

    return UserActivity.deserialize(data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const stopUserActivity = async (
  sessionToken: string,
  activityDTO?: Partial<UserActivityDTO>
): Promise<UserActivity> => {
  try {
    const { data } = await UserActivitiesApi.stopUserActivity(
      sessionToken,
      activityDTO
    );

    return UserActivity.deserialize(data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const updateUserActivity = async (
  sessionToken: string
): Promise<UserActivity> => {
  try {
    const { data } = await UserActivitiesApi.updateUserActivity(sessionToken);

    return UserActivity.deserialize(data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const overrideUserActivity = async (
  sessionToken: string,
  activityDTO: Partial<UserActivityDTO>
): Promise<UserActivity> => {
  try {
    const { data } = await UserActivitiesApi.overrideUserActivity(
      sessionToken,
      activityDTO
    );

    return UserActivity.deserialize(data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};
