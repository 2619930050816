import { useMemo } from 'react';
import { useQuery } from 'react-query';
import { fetchTaskDetails } from 'actions/Task/taskActions';
import { ApiError } from 'entities/ApiError.entity';
import { Task } from 'entities/Task.entity';
import { queryKeys } from 'enums/QueryKeys.enum';
import { ACCESS_TOKEN_EXPIRATION } from 'utils/constants';
import { getEntityName } from 'utils/helpers/commonHelpers';

import { useFolders } from './useFolders';
import { useRepertoires } from './useRepertoires';

export interface TaskDetails {
  task?: Task;
  isLoading: boolean;
  isError: boolean;
  repertoireName?: string;
  folderName?: string | null;
  error: ApiError | null;
}

export const useTaskDetails = (taskId: Task['id']): TaskDetails => {
  const {
    data,
    error,
    isError: isTaskError,
    isLoading: isTaskLoading
  } = useQuery<Task, ApiError>(
    queryKeys.taskDetails(taskId),
    () => fetchTaskDetails(taskId),
    {
      retry: 0,
      staleTime: ACCESS_TOKEN_EXPIRATION
    }
  );

  const {
    data: folderData,
    isError: isFolderError,
    isLoading: isFolderLoading
  } = useFolders();

  const {
    data: repertoiresData,
    isError: isRepertoireError,
    isLoading: isRepertoireLoading
  } = useRepertoires();

  const repertoireName =
    data?.repertoireId && getEntityName(data!.repertoireId, repertoiresData);
  const folderName =
    data?.parentId && getEntityName(data!.parentId, folderData);

  const isLoading = useMemo(
    () => isTaskLoading || isRepertoireLoading || isFolderLoading,
    [isFolderLoading, isRepertoireLoading, isTaskLoading]
  );

  const isError = useMemo(
    () => isTaskError || isFolderError || isRepertoireError,
    [isFolderError, isRepertoireError, isTaskError]
  );

  return {
    task: data,
    folderName,
    repertoireName,
    error,
    isError,
    isLoading
  };
};
