import { useQuery, UseQueryResult } from 'react-query';
import { fetchRepertoires } from 'actions/Repertoire/repertoireActions';
import { ApiError } from 'entities/ApiError.entity';
import { Repertoire } from 'entities/Repertoire.entity';
import { queryKeys } from 'enums/QueryKeys.enum';
import { MAX_PAGE_LIMIT } from 'utils/constants';

export const useRepertoires = (): UseQueryResult<Repertoire[], ApiError> =>
  useQuery<Repertoire[], ApiError>(
    queryKeys.repertoires,
    async () => {
      const repertoires = await fetchRepertoires({
        limit: MAX_PAGE_LIMIT,
        sort: ['title:ASC']
      });

      return Repertoire.deserializeAsList(repertoires);
    },
    {
      retry: 0,
      staleTime: Infinity
    }
  );
