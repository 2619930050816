import React, { FC, Fragment } from 'react';
import {
  generatePath,
  NavLink,
  Redirect,
  Switch,
  useHistory,
  useParams,
  useRouteMatch
} from 'react-router-dom';
import ArticleIcon from '@mui/icons-material/Article';
import GroupsIcon from '@mui/icons-material/Groups';
import InboxIcon from '@mui/icons-material/Inbox';
import {
  Avatar,
  Chip,
  Divider,
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText
} from '@mui/material';
import imagePlaceholder from 'assets/image-placeholder.png';
import { If } from 'components/If';
import { Layout } from 'components/Layout';
import { ProtectedRoute } from 'components/ProtectedRoute';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { OrganizationTypeLabels } from 'enums/OrganizationType.enum';
import { Routes } from 'enums/Routes.enum';
import { useOrganizationBySlug } from 'hooks/Organization/useOrganizationBySlug';
import { TasksProvider } from 'views/Library/TasksProvider';
import { DetailsInformation } from 'views/Organizations/DetailsInformation';
import { OrganizationLibraryPage } from 'views/Organizations/OrganizationLibrary/OrganizationLibraryPage';
import { PeoplePage } from 'views/People/PeoplePage';
import { UserDetails } from 'views/People/UserDetails';
import { UserTaskDetailsPage } from 'views/People/UserTaskDetailsPage';

import styles from './OrganizationDetailsPage.module.scss';

const menuItems = [
  {
    label: 'People',
    icon: <GroupsIcon />,
    route: Routes.OrganizationPeople
  },
  {
    label: 'Library',
    icon: <InboxIcon />,
    route: Routes.OrganizationLibrary
  },
  {
    label: 'Information',
    icon: <ArticleIcon />,
    route: Routes.OrganizationInformation
  }
];

export const OrganizationDetailsPage: FC = () => {
  const { slugId, slug } = useParams<{ slugId: string; slug: string }>();
  const history = useHistory();
  const routeMatch = useRouteMatch([
    Routes.OrganizationPeople,
    Routes.OrganizationLibrary,
    Routes.OrganizationInformation
  ]);

  const {
    isError: isOrgDetailsError,
    isLoading,
    data
  } = useOrganizationBySlug({
    onSuccess: (data) => {
      if (data.id !== slug) {
        history.push(
          generatePath(Routes.OrganizationInformation, {
            slugId,
            slug: data.id
          })
        );
      }
    }
  });

  const currentPath = routeMatch?.path;

  return (
    <Layout loading={isLoading}>
      <StatusSnackBar
        isError={isOrgDetailsError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      <div className={styles['organization-details-page']}>
        <Drawer variant="permanent" anchor="left" className={styles.drawer}>
          <div className={styles.info}>
            <Avatar
              variant="square"
              alt="organization"
              data-testid="image"
              className={styles.image}
              src={data?.imageUrl || imagePlaceholder}
            />
            <div>
              <h1 className={styles.label}>{data?.name}</h1>
              <Chip
                size="small"
                className={styles.chip}
                label={data?.type ? OrganizationTypeLabels[data?.type] : ''}
              />
            </div>
          </div>
          <List>
            {menuItems.map(({ label, icon, route }, index) => (
              <Fragment key={label}>
                <If condition={!index}>
                  <Divider />
                </If>
                <ListItem
                  button
                  exact
                  component={NavLink}
                  data-testid="organization-nav-tab"
                  selected={currentPath === route}
                  to={generatePath(route, { slugId, slug })}
                >
                  <ListItemIcon className={styles.icon}>{icon}</ListItemIcon>
                  <ListItemText primary={label} />
                </ListItem>
                <Divider />
              </Fragment>
            ))}
          </List>
        </Drawer>
        {data?.id && (
          <Switch>
            <ProtectedRoute exact path={Routes.LearnerDetails}>
              <UserDetails />
            </ProtectedRoute>
            <ProtectedRoute exact path={Routes.StaffDetails}>
              <UserDetails />
            </ProtectedRoute>
            <ProtectedRoute exact path={Routes.StaffTaskDetails}>
              <UserTaskDetailsPage />
            </ProtectedRoute>
            <ProtectedRoute exact path={Routes.LearnerTaskDetails}>
              <UserTaskDetailsPage />
            </ProtectedRoute>
            <ProtectedRoute exact path={Routes.OrganizationTaskDetails}>
              <UserTaskDetailsPage />
            </ProtectedRoute>
            <ProtectedRoute path={Routes.OrganizationPeople}>
              <PeoplePage />
            </ProtectedRoute>
            <ProtectedRoute path={Routes.OrganizationLibrary}>
              <TasksProvider>
                <OrganizationLibraryPage
                  organization={{
                    id: data.id,
                    name: data.name
                  }}
                />
              </TasksProvider>
            </ProtectedRoute>
            <ProtectedRoute path={Routes.OrganizationInformation}>
              <DetailsInformation organization={data!} />
            </ProtectedRoute>
            <Redirect
              to={Routes.OrganizationInformation}
              from={Routes.OrganizationDetails}
            />
          </Switch>
        )}
      </div>
    </Layout>
  );
};
