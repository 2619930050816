import { STORAGE_KEY_PREFIX } from 'utils/constants';

export class LocalStorage {
  private static prefixKey(key: string): string {
    return `${STORAGE_KEY_PREFIX}${key}`;
  }

  static setItem(key: string, value: unknown): void {
    localStorage.setItem(LocalStorage.prefixKey(key), JSON.stringify(value));
  }

  static getItem<T>(key: string): T | null {
    const value = localStorage.getItem(LocalStorage.prefixKey(key));

    return value ? (JSON.parse(value) as T) : null;
  }

  static removeItem(key: string): void {
    localStorage.removeItem(LocalStorage.prefixKey(key));
  }

  static reset(): void {
    localStorage.clear();
  }
}
