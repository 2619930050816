import React, { FC, ReactElement, useMemo } from 'react';
import deleteIcon from 'assets/delete.svg';
import {
  ConfirmationScreen,
  ConfirmationScreenTheme
} from 'components/ConfirmationScreen';
import { If } from 'components/If';
import { Modal } from 'components/Modal';
import { Task } from 'entities/Task.entity';
import { User } from 'entities/User.entity';
import { TaskType } from 'enums/TaskType.enum';
import { checkForPlural } from 'utils/helpers/stringHelpers';

import styles from './DeleteTaskModal.module.scss';

interface Props {
  isOpen: boolean;
  data: Pick<Task, 'id' | 'title' | 'type' | 'tasksAmount'> & {
    assigneeName?: User['fullName'];
  };
  onDeleteTask: () => void;
  onCloseModal: () => void;
  customDeleteTitle?: ReactElement | string;
  additionalTitle?: string;
}

export const DeleteTaskModal: FC<Props> = ({
  isOpen,
  data,
  onCloseModal,
  onDeleteTask,
  customDeleteTitle,
  additionalTitle
}) => {
  const { title, type, tasksAmount, assigneeName } = data;

  const deleteTaskTitle = useMemo(
    () =>
      type === TaskType.Task && !tasksAmount ? (
        <>
          Are you sure you want to delete task <b>{title}</b>?
          {!!assigneeName && (
            <>
              {' '}
              It is assigned to <b>{assigneeName}</b>.
            </>
          )}
        </>
      ) : (
        <>
          Are you sure you want to delete folder? It includes{' '}
          <b>
            {tasksAmount} task{checkForPlural(tasksAmount || 0)}
          </b>
          {assigneeName && (
            <>
              {' '}
              assigned to <b>{assigneeName}</b>
            </>
          )}
          .
        </>
      ),
    [assigneeName, tasksAmount, title, type]
  );

  return (
    <Modal isOpen={isOpen} onCloseModal={onCloseModal}>
      <ConfirmationScreen
        icon={deleteIcon}
        onSubmit={onDeleteTask}
        onCancel={onCloseModal}
        cancelButtonTitle="Cancel"
        submitButtonTitle="Delete"
        theme={ConfirmationScreenTheme.Error}
      >
        <p>{customDeleteTitle || deleteTaskTitle}</p>

        <If condition={!!additionalTitle}>
          <p className={styles['additional-title']}>{additionalTitle}</p>
        </If>
      </ConfirmationScreen>
    </Modal>
  );
};
