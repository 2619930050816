import { deserialize, object, serializable } from 'serializr';

import { File } from './File.entity';

export class Step {
  @serializable
  id = '';

  @serializable
  title = '';

  @serializable
  timer?: number | null;

  @serializable
  text = '';

  @serializable
  active = true;

  @serializable(object(File))
  image?: File | null;

  @serializable(object(File))
  video?: File | null;

  @serializable(object(File))
  audio?: File | null;

  @serializable
  safetyIconUrl: string | null = null;

  get imageUrl(): string {
    return this.image?.url || '';
  }

  get videoThumbnail(): string | undefined {
    return this.video?.thumbnail?.url;
  }

  get videoUrl(): string {
    return this.video?.url || '';
  }

  get audioUrl(): string {
    return this.audio?.url || '';
  }

  static deserialize(json: Object | string): Step {
    return deserialize(Step, json);
  }

  static deserializeAsList(list: Step[]): Step[] {
    return list.map(Step.deserialize);
  }
}
