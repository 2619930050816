import { date, deserialize, serializable } from 'serializr';

export class Assessment {
  @serializable
  id = '';

  @serializable(date())
  createdAt: Date = new Date();

  @serializable(date())
  updatedAt: Date = new Date();

  @serializable
  slugId = '';

  @serializable
  notes: string | null = '';

  @serializable
  taskId = '';

  @serializable
  stepsAmount = 0;

  @serializable
  independentStepsAmount = 0;

  @serializable
  organizationId = '';

  @serializable
  evaluatedId = '';

  @serializable
  assessorId = '';

  @serializable
  scorePercent = 0;

  static deserialize(json: Object | string): Assessment {
    return deserialize(Assessment, json);
  }

  static deserializeAsList(list: Assessment[]): Assessment[] {
    return list.map(Assessment.deserialize);
  }
}
