import MicIcon from '@mui/icons-material/Mic';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import VideocamIcon from '@mui/icons-material/Videocam';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';

export enum StepContentType {
  video = 'video',
  image = 'image',
  audio = 'audio',
  text = 'text'
}

export const StepContentTypeTitles = Object.freeze<
  Record<StepContentType, string>
>({
  [StepContentType.text]: 'Text',
  [StepContentType.image]: 'Image',
  [StepContentType.video]: 'Video',
  [StepContentType.audio]: 'Audio'
});

export const StepContentTypeIcon = Object.freeze<
  Record<
    StepContentType,
    OverridableComponent<SvgIconTypeMap<{}, 'svg'>> & { muiName: string }
  >
>({
  [StepContentType.text]: TextFieldsIcon,
  [StepContentType.image]: PhotoCameraIcon,
  [StepContentType.video]: VideocamIcon,
  [StepContentType.audio]: MicIcon
});
