import { useQuery, UseQueryResult } from 'react-query';
import { fetchUserData } from 'actions/User/userActions';
import { ApiError } from 'entities/ApiError.entity';
import { User } from 'entities/User.entity';
import { queryKeys } from 'enums/QueryKeys.enum';
import { ACCESS_TOKEN_EXPIRATION } from 'utils/constants';

interface UseOrganizationAdminProps {
  slugId?: string;
  adminId?: string;
}

export const useOrganizationAdmin = ({
  slugId = '',
  adminId = ''
}: UseOrganizationAdminProps): UseQueryResult<User, ApiError> => {
  return useQuery<User, ApiError>(
    queryKeys.organizationAdmin(slugId, adminId),
    () => fetchUserData(adminId),
    {
      retry: 0,
      enabled: !!adminId,
      staleTime: ACCESS_TOKEN_EXPIRATION
    }
  );
};
