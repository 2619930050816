import { ApiError } from 'entities/ApiError.entity';
import { Repertoire } from 'entities/Repertoire.entity';
import {
  RepertoireApi,
  RepertoiresQuery
} from 'services/API/Repertoire/RepertoireApi';

export const fetchRepertoires = async (
  repertoiresQuery: RepertoiresQuery
): Promise<Repertoire[]> => {
  try {
    const { data } = await RepertoireApi.fetchRepertoires(repertoiresQuery);

    return Repertoire.deserializeAsList(data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const updateRepertoireDetails = async (
  repertoireId: Repertoire['id'],
  repertoireTitle: string
): Promise<Repertoire> => {
  try {
    const response = await RepertoireApi.updateRepertoireDetails(
      repertoireId,
      repertoireTitle
    );

    return Repertoire.deserialize(response.data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const createRepertoire = async (
  repertoireTitle: string
): Promise<Repertoire> => {
  try {
    const response = await RepertoireApi.createRepertoire(repertoireTitle);

    return Repertoire.deserialize(response.data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};
