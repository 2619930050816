import React, { FC } from 'react';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import cn from 'classnames';

import styles from './PasswordWarningBanner.module.scss';

const passwordRules = [
  'a minimum of 8 characters & a maximum of 32 characters ',
  'at least one lowercase character',
  'at least one uppercase character',
  'at least one number',
  'at least one of the following special characters ^ $ * . [ ] { } ( ) ? - " ! @ # % & / \\ , > < \' : ; | _ ~ '
];

interface Props {
  className?: string;
}

export const PasswordWarningBanner: FC<Props> = ({ className }) => (
  <div className={cn(styles['password-warning-banner'], className)}>
    <WarningAmberIcon className={styles['warning-icon']} />
    <div>
      <span className={styles.label}>Password must contain:</span>
      <ol className={styles.list} data-testid="rules-list">
        {passwordRules.map((rule) => (
          <li key={rule}>{rule}</li>
        ))}
      </ol>
    </div>
  </div>
);
