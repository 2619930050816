import React, { FC } from 'react';
import {
  generatePath,
  NavLink,
  useHistory,
  useRouteMatch
} from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Avatar, ListItemIcon, MenuItem } from '@mui/material';
import logo from 'assets/main-logo.svg';
import { IconMenu } from 'components/IconMenu';
import { PermissionsGate } from 'components/PermissionsGate';
import { Routes, RoutesLabels } from 'enums/Routes.enum';
import { UserScopes } from 'enums/UserScopes.enum';
import { useAuthContext } from 'hooks/Auth/useAuthContext';
import { useLoadAuthUserData } from 'hooks/Auth/useLoadAuthUserData';

import { NavTab } from '../NavTab';

import styles from './NavigationHeader.module.scss';

export const NavigationHeader: FC = () => {
  const { logOut } = useAuthContext();
  const { data } = useLoadAuthUserData();

  const routeMatch = useRouteMatch([
    Routes.Dashboard,
    Routes.Organizations,
    Routes.Library
  ]);
  const currentTab = routeMatch?.path;

  const history = useHistory();

  return (
    <header className={styles['navigation-header']}>
      <img
        src={logo}
        alt="avail logo"
        data-testid="logo"
        className={styles.logo}
      />
      <nav className={styles.tabs} data-testid="navigation">
        <PermissionsGate scopes={[UserScopes.ViewDashboard]}>
          <NavTab
            exact
            component={NavLink}
            to={Routes.Dashboard}
            className={styles.tab}
            label={RoutesLabels[Routes.Dashboard]}
            active={currentTab === Routes.Dashboard}
          />
        </PermissionsGate>
        <PermissionsGate scopes={[UserScopes.ViewOrganizations]}>
          <NavTab
            exact
            component={NavLink}
            to={Routes.Organizations}
            className={styles.tab}
            label={RoutesLabels[Routes.Organizations]}
            active={currentTab === Routes.Organizations}
          />
        </PermissionsGate>
        <PermissionsGate scopes={[UserScopes.ViewAvailLibrary]}>
          <NavTab
            exact
            component={NavLink}
            to={Routes.Library}
            className={styles.tab}
            label={RoutesLabels[Routes.Library]}
            active={currentTab === Routes.Library}
          />
        </PermissionsGate>
      </nav>
      <div className={styles['user-info']}>
        <Avatar src={data?.avatarUrl} alt="user-avatar" />
        <span className={styles.name}>{data?.fullName}</span>
        <span className={styles.divider} />
        <IconMenu
          icon={<KeyboardArrowDownIcon className={styles['icon-down']} />}
          buttonLabel="Open Dropdown Menu to Access Account Info or Logout"
        >
          <MenuItem onClick={() => history.push(generatePath(Routes.Account))}>
            <ListItemIcon>
              <AccountCircleIcon color="primary" />
            </ListItemIcon>
            My account
          </MenuItem>
          <MenuItem onClick={logOut}>Log out</MenuItem>
        </IconMenu>
      </div>
    </header>
  );
};
