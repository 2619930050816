/* eslint-disable */
import React, { FC, useEffect } from 'react';
import { useQuery } from 'react-query';
import '@fullcalendar/react';
import { Draggable } from '@fullcalendar/interaction';
/* eslint-enable */
import { fetchTasks } from 'actions/Task/taskActions';
import { EmptyState } from 'components/EmptyState';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { TaskCard } from 'components/TaskCard';
import { ApiError } from 'entities/ApiError.entity';
import { Learner } from 'entities/Learner.entity';
import { Task } from 'entities/Task.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { TaskSort } from 'enums/TaskSort.enum';
import { TaskType } from 'enums/TaskType.enum';
import { ACCESS_TOKEN_EXPIRATION } from 'utils/constants';

import 'scss/calendar.scss';
import { EmptyStateMessages } from 'enums/EmptyStateMessages.enum';
import styles from './EventsList.module.scss';

interface Props {
  learnerId: Learner['id'];
}

export const EventsList: FC<Props> = ({ learnerId }) => {
  const tasksContainerRef = React.createRef<HTMLDivElement>();

  useEffect(() => {
    let draggable: Draggable;

    if (tasksContainerRef.current) {
      draggable = new Draggable(tasksContainerRef.current, {
        eventData: (eventEl) => {
          return {
            id: eventEl.dataset.id,
            title: eventEl.getAttribute('title')
          };
        },
        itemSelector: '.task-card'
      });
    }

    return () => {
      // eslint-disable-next-line no-unused-expressions
      draggable && draggable.destroy();
    };
  }, [tasksContainerRef]);

  const { data, isError } = useQuery<Task[], ApiError>(
    queryKeys.filteredTasks({
      sort: TaskSort.ByName,
      assignedToIdEq: learnerId
    }),
    () =>
      fetchTasks({
        typeEq: TaskType.Task,
        sort: [TaskSort.ByName],
        exclude: ['steps'],
        assignedToIdEq: learnerId
      }),
    {
      retry: 0,
      staleTime: ACCESS_TOKEN_EXPIRATION
    }
  );

  return (
    <>
      <StatusSnackBar
        isError={isError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      <div ref={tasksContainerRef}>
        <h3 className={styles.title}>My tasks</h3>
        {!data?.length ? (
          <EmptyState title={EmptyStateMessages.Tasks} />
        ) : (
          <div className={styles['tasks-container']}>
            {data.map(({ id, title, imageUrl, stepsAmount }) => (
              <TaskCard
                id={id}
                key={title}
                title={title}
                imageUrl={imageUrl}
                stepsAmount={stepsAmount}
                className={styles['task-card']}
              />
            ))}
          </div>
        )}
      </div>
    </>
  );
};
