import { date, deserialize, serializable } from 'serializr';

export class Token {
  @serializable
  token = '';

  @serializable(date())
  expiresAt: Date = new Date();

  @serializable(date())
  issuedAt: Date = new Date();

  static deserialize(json: Object | string): Token {
    return deserialize(Token, json);
  }
}
