import React, {
  ForwardedRef,
  forwardRef,
  ReactElement,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState
} from 'react';
import { Column, useExpanded, useFlexLayout, useTable } from 'react-table';
import cn from 'classnames';
import { TableLoadingRow } from 'components/DataTable/TableLoadingRow';
import { EmptyState } from 'components/EmptyState';
import { If } from 'components/If';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { Row } from 'components/SimpleTable/Row';

import { Data, SubRows } from './Row';

import styles from './SimpleTable.module.scss';

interface Props<T extends Data> {
  columns: Column<T>[];
  data: T[];
  isRowExpanded?: boolean;
  isLoading?: boolean;
  emptyMessage?: string;
  renderSubRows?: (props: SubRows) => void;
}

export interface DataTableHandle {
  reset: () => void;
}

export const SimpleTableInner = <T extends Data>(
  {
    columns,
    data,
    isLoading = false,
    emptyMessage,
    renderSubRows,
    isRowExpanded = false
  }: Props<T>,
  ref: ForwardedRef<DataTableHandle>
): ReactElement<Props<T>> => {
  const [records, setRecords] = useState<T[]>(data);

  useEffect(() => {
    setRecords(data);
  }, [data]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    toggleAllRowsExpanded
  } = useTable(
    {
      columns,
      data: records,
      autoResetExpanded: false,
      getRowId: ({ id }) => id
    },
    useFlexLayout,
    useExpanded
  );

  useImperativeHandle(ref, () => ({
    reset: () => {
      toggleAllRowsExpanded(true);
    }
  }));

  const renderRow = useCallback(
    (row) => {
      if (!row) {
        return <TableLoadingRow />;
      }

      prepareRow(row);

      return (
        <Row
          item={{ ...row, isExpanded: isRowExpanded || row.isExpanded }}
          {...row.getRowProps()}
          renderSubRows={renderSubRows}
        />
      );
    },
    [isRowExpanded, prepareRow, renderSubRows]
  );

  return (
    <LoadingOverlay loading={isLoading}>
      <If condition={!rows.length}>
        <EmptyState title={emptyMessage} />
      </If>
      <If condition={!!rows.length}>
        <table {...getTableProps()} className={styles.table}>
          <thead>
            {headerGroups.map((headerGroup) => (
              // eslint-disable-next-line react/jsx-key
              <tr
                {...headerGroup.getHeaderGroupProps()}
                className={cn(styles.tr, styles.header)}
              >
                {headerGroup.headers.map((column) => (
                  // eslint-disable-next-line react/jsx-key
                  <div
                    {...column.getHeaderProps()}
                    className={styles.th}
                    data-testid="th"
                  >
                    {column.render('Header')}
                  </div>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {rows.map((row) => renderRow(row))}
          </tbody>
        </table>
      </If>
    </LoadingOverlay>
  );
};

export const SimpleTable = forwardRef(SimpleTableInner) as <T extends Data>(
  props: Props<T> & { ref?: React.ForwardedRef<DataTableHandle> }
) => ReturnType<typeof SimpleTableInner>;
