import React, { FC } from 'react';
import { useMutation } from 'react-query';
import { generatePath, useParams } from 'react-router-dom';
import { fetchStaffReportCSV } from 'actions/Report/reportActions';
import { EmptyState } from 'components/EmptyState';
import { If } from 'components/If';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { differenceInCalendarDays } from 'date-fns';
import { ApiError } from 'entities/ApiError.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { Routes } from 'enums/Routes.enum';
import { useOrganizationAdmin } from 'hooks/Organization/useOrganizationAdmin';
import { useOrganizationBySlug } from 'hooks/Organization/useOrganizationBySlug';
import { saveAsCSV } from 'utils/helpers/filesHelpers';
import { LearnerSection } from 'views/Reports/LearnerSection';
import { OrganizationOverviewSection } from 'views/Reports/OrganizationOverviewSection';
import { OrganizationSummarySection } from 'views/Reports/OrganizationSummarySection';
import { ReportContainer } from 'views/Reports/ReportContainer';
import { StaffSection } from 'views/Reports/StaffSection';

import styles from './ManagerReport.module.scss';

export const ManagerReport: FC = () => {
  const { slugId, slug } = useParams<{ slugId: string; slug: string }>();

  const { isError, isLoading, data: orgDetails } = useOrganizationBySlug();
  const {
    isError: isAdminError,
    isLoading: isAdminLoading,
    data: orgAdmin
  } = useOrganizationAdmin({
    slugId: orgDetails?.slugId,
    adminId: orgDetails?.adminId
  });

  const {
    isLoading: isExportLoading,
    mutateAsync: exportCSV,
    isError: isExportError
  } = useMutation<string, ApiError>(() => fetchStaffReportCSV(orgDetails!.id), {
    retry: 0,
    onSuccess: (data) => {
      saveAsCSV(data, true);
    }
  });

  const renderHeader = (title: string) => (
    <div className={styles.header}>
      <h2 className={styles.title}>{title}</h2>
      <span className={styles.subtitle}>
        Total days in range —{' '}
        {orgDetails?.createdAt
          ? differenceInCalendarDays(new Date(), orgDetails?.createdAt) + 1
          : '—'}
      </span>
    </div>
  );

  return (
    <ReportContainer
      isError={isError}
      title="Managers Report"
      navTo={generatePath(Routes.OrganizationInformation, {
        slug,
        slugId
      })}
      isLoading={isLoading || isAdminError}
      isExportLoading={isExportLoading}
      backButtonLabel="Back to Organization Information"
      onExportCSV={exportCSV}
      createdAt={orgDetails?.formattedCreatedAt}
      isManagerReport
    >
      <StatusSnackBar
        isError={isError || isAdminError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      <StatusSnackBar
        isError={isExportError}
        errorMessage={ErrorMessages.FailedPostRequest}
      />
      <If condition={!orgDetails && !(isLoading || isAdminLoading)}>
        <EmptyState />
      </If>
      {!!orgDetails && (
        <>
          <div className={styles.overview}>
            <div className={styles.section}>
              <h2 className={styles.title}>Organization Report</h2>
              <OrganizationOverviewSection organizationId={orgDetails.id} />
            </div>
            <div className={styles.section}>
              <h2 className={styles.title}>Organization Summary</h2>
              <OrganizationSummarySection
                organization={orgDetails}
                organizationAdmin={orgAdmin}
              />
            </div>
          </div>

          {renderHeader('Staff Report')}
          <StaffSection organizationId={orgDetails.id} />

          {renderHeader('Learners Report')}
          <LearnerSection organizationId={orgDetails.id} />
        </>
      )}
    </ReportContainer>
  );
};
