import React, { FC, useMemo } from 'react';
import { useQuery } from 'react-query';
import { Grid } from '@mui/material';
import { fetchTasks } from 'actions/Task/taskActions';
import { EmptyState } from 'components/EmptyState';
import { If } from 'components/If';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Task } from 'entities/Task.entity';
import { EmptyStateMessages } from 'enums/EmptyStateMessages.enum';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { TaskStatus } from 'enums/TaskStatus.enum';
import { useSharedLibraryContext } from 'hooks/Task/useSharedLibraryContext';
import { useTaskCardWidth } from 'hooks/Task/useTaskCardWidth';
import { ACCESS_TOKEN_EXPIRATION } from 'utils/constants';
import { SharedTaskDetails } from 'views/SharedLibrary/SharedTaskDetails';
import { TaskCard } from 'views/SharedLibrary/TaskCard';

import styles from './SharedFolderDetails.module.scss';

export const SharedFolderDetails: FC = () => {
  const { activeTask, onChangeActiveTask } = useSharedLibraryContext();
  const { taskBoxRef, taskWidth } = useTaskCardWidth();

  const options = useMemo(
    () =>
      activeTask?.isFolder
        ? {
            parentIdEq: activeTask!.id,
            parentIdIsNull: undefined
          }
        : { parentIdIsNull: true, parentIdEq: undefined },
    [activeTask]
  );

  const {
    data: taskData,
    isError,
    isLoading
  } = useQuery<Task[], ApiError>(
    queryKeys.filteredTasks({
      search: '',
      status: TaskStatus.Published,
      ...options
    }),
    (): Promise<Task[]> => {
      return fetchTasks({
        ...options,
        sort: ['title:ASC'],
        exclude: ['steps'],
        publishStatusEq: TaskStatus.Published
      });
    },
    {
      staleTime: ACCESS_TOKEN_EXPIRATION,
      retry: 0
    }
  );

  return (
    <>
      <StatusSnackBar
        isError={isError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      <LoadingOverlay loading={isLoading}>
        <div className={styles['shared-folder-details']} ref={taskBoxRef}>
          <If condition={!!activeTask}>
            <SharedTaskDetails task={activeTask!} />
          </If>

          <If condition={!taskData?.length && !isLoading}>
            <div className={styles['empty-state']}>
              <EmptyState title={EmptyStateMessages.Content} icon={null} />
            </div>
          </If>

          <If condition={!!taskData?.length}>
            <h3 className={styles['task-title']}>Tasks</h3>
            <Grid container spacing={3} className={styles['card-container']}>
              {taskData?.map((task) => (
                <Grid
                  item
                  xs
                  sm
                  md
                  lg
                  key={task.id}
                  data-testid="task"
                  style={{ maxWidth: `${taskWidth}px` }}
                >
                  <TaskCard
                    task={task}
                    onSelectCard={() => onChangeActiveTask(task)}
                  />
                </Grid>
              ))}
            </Grid>
          </If>
        </div>
      </LoadingOverlay>
    </>
  );
};
