export enum ScheduleRepeatType {
  Never = 'never',
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly'
}

export const ScheduleRepeatTypeLabels = Object.freeze<Record<string, string>>({
  [ScheduleRepeatType.Never]: 'Never',
  [ScheduleRepeatType.Daily]: 'Daily',
  [ScheduleRepeatType.Weekly]: 'Weekly',
  [ScheduleRepeatType.Monthly]: 'Monthly'
});
