import React, { FC } from 'react';
import { useMutation } from 'react-query';
import {
  deleteRepeatedTaskSchedule,
  deleteTaskSchedule
} from 'actions/Task/taskActions';
import deleteIcon from 'assets/delete.svg';
import {
  ConfirmationScreen,
  ConfirmationScreenTheme
} from 'components/ConfirmationScreen';
import { Modal } from 'components/Modal';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Learner } from 'entities/Learner.entity';
import { Schedule } from 'entities/Schedule.entity';
import { Task } from 'entities/Task.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { ScheduleRepeatType } from 'enums/ScheduleRepeatType.enum';
import { queryClient } from 'index';
import { FormData } from 'views/People/Scheduler/EventForm';

interface Props {
  isOpen: boolean;
  taskTitle: Task['title'];
  eventId: Schedule['id'];
  eventData: FormData;
  onCloseModal: () => void;
  onCloseEditModal: () => void;
  learnerId: Learner['id'];
}

export const DeleteEventModal: FC<Props> = ({
  isOpen,
  eventId,
  taskTitle,
  learnerId,
  eventData,
  onCloseModal,
  onCloseEditModal
}) => {
  const onSuccess = async () => {
    await queryClient.invalidateQueries(queryKeys.userSchedulesList(learnerId));

    onCloseModal();
    onCloseEditModal();
  };

  const { isError, isSuccess, error, mutateAsync } = useMutation<
    void,
    ApiError,
    void
  >(() => deleteTaskSchedule(eventId), {
    onSuccess
  });

  const {
    isError: isRepeatedScheduleError,
    isSuccess: isRepeatedScheduleSuccess,
    error: repeatedScheduleError,
    mutateAsync: deleteRepeatedEvent
  } = useMutation<Schedule, ApiError, void>(
    () => deleteRepeatedTaskSchedule(eventId, eventData.scheduledAt),
    {
      onSuccess
    }
  );

  const onDeleteTask = () => {
    if (eventData.repeat !== ScheduleRepeatType.Never) {
      deleteRepeatedEvent();
    } else {
      mutateAsync();
    }
  };

  return (
    <>
      <StatusSnackBar
        isError={isError || isRepeatedScheduleError}
        isSuccess={isSuccess || isRepeatedScheduleSuccess}
        successMessage="Task successfully deleted"
        errorMessage={
          error?.errorMessage ||
          repeatedScheduleError?.errorMessage ||
          ErrorMessages.FailedPostRequest
        }
      />
      <Modal isOpen={isOpen} onCloseModal={onCloseModal}>
        <ConfirmationScreen
          icon={deleteIcon}
          onSubmit={onDeleteTask}
          onCancel={onCloseModal}
          cancelButtonTitle="Cancel"
          submitButtonTitle="Delete"
          theme={ConfirmationScreenTheme.Error}
        >
          <p>
            Are you sure you want to stop notifications to Learner for this task
            <b> {taskTitle} </b>?
          </p>
        </ConfirmationScreen>
      </Modal>
    </>
  );
};
