import React, { FC } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { Button, Grid } from '@mui/material';
import { Organization } from 'entities/Organization.entity';
import { CreateOrganizationLicenses } from 'views/Organizations/CreateOrganizationLicenses';

import styles from './EditLicensesForm.module.scss';

export interface FormData {
  managersAmountLimit: number;
  learnersAmountLimit: number;
  facilitatorsAmountLimit: number;
  passiveFacilitatorsAmountLimit: number;
}

interface Props {
  data: Organization;
  isLoading: boolean;
  onCloseModal: () => void;
  onSubmit: (formData: FormData) => void;
}

export const EditLicensesForm: FC<Props> = ({
  data,
  onSubmit,
  isLoading,
  onCloseModal
}) => {
  const {
    managersAmountLimit,
    learnersAmountLimit,
    facilitatorsAmountLimit,
    passiveFacilitatorsAmountLimit
  } = data;

  const methods = useForm<FormData>({
    defaultValues: {
      managersAmountLimit,
      learnersAmountLimit,
      facilitatorsAmountLimit,
      passiveFacilitatorsAmountLimit
    },
    shouldUnregister: true,
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false
  });

  return (
    <FormProvider {...methods}>
      <CreateOrganizationLicenses />
      <div className={styles.footer}>
        <Grid container alignItems="stretch" spacing={2}>
          <Grid item xs={6}>
            <LoadingButton
              fullWidth
              type="submit"
              color="primary"
              variant="contained"
              loading={isLoading}
              disabled={!methods.formState.isDirty}
              onClick={methods.handleSubmit((formData) => onSubmit(formData))}
            >
              Save
            </LoadingButton>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              onClick={onCloseModal}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </div>
    </FormProvider>
  );
};
