import React, { FC, useCallback, useMemo, useRef, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useMutation, useQuery } from 'react-query';
import { CloseSharp } from '@mui/icons-material';
import { Chip, Grid, IconButton } from '@mui/material';
import {
  duplicateTask,
  fetchTaskCount,
  updateTaskDetails
} from 'actions/Task/taskActions';
import { CreateTaskFab } from 'components/CreateTaskFab';
import { DataTable, DataTableHandle } from 'components/DataTable';
import { If } from 'components/If';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Organization } from 'entities/Organization.entity';
import { Task } from 'entities/Task.entity';
import { EmptyStateMessages } from 'enums/EmptyStateMessages.enum';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import {
  DndDroppableContainerIds,
  TableDnDType
} from 'enums/TableDnDType.enum';
import { TaskAssignStatus } from 'enums/TaskAssignStatus.enum';
import { TaskStatus } from 'enums/TaskStatus.enum';
import { TaskType } from 'enums/TaskType.enum';
import { useLoadAuthUserData } from 'hooks/Auth/useLoadAuthUserData';
import { useOrganizationLibrary } from 'hooks/Organization/useOrganizationLibrary';
import { useTasksContext } from 'hooks/Task/useTasksContext';
import { useInitialLoading } from 'hooks/useInitialLoading';
import { queryClient } from 'index';
import { TaskDuplicateOptions } from 'services/API/Task/TaskApi';
import { LocalStorage } from 'services/LocalStorage';
import { useDebounce } from 'use-debounce/lib';
import { ACCESS_TOKEN_EXPIRATION, HIDE_DND_BANNER } from 'utils/constants';
import { checkForPlural } from 'utils/helpers/stringHelpers';
import { updateDraggedOrganizationItemToFolder } from 'utils/helpers/taskDragEndActions';
import { CreateFolderModal } from 'views/Library/CreateFolderModal';
import { OrganizationLibraryActionsBar } from 'views/Organizations/OrganizationLibrary/OrganizationLibraryActionsBar';
import { OrganizationLibraryStatusBar } from 'views/Organizations/OrganizationLibrary/OrganizationLibraryStatusBar';
import { SharedLibrary } from 'views/Organizations/OrganizationLibrary/SharedLibrary';
import { CopyToSharedLibraryModal } from 'views/SharedLibrary/CopyToSharedLibraryModal';
import { CreateTaskModal } from 'views/Task/CreateTaskModal';

import { columns } from './columns';

import styles from './OrganizationLibraryPage.module.scss';

const ROW_PREFIX_LENGTH = 4;

interface TaskDuplicateProps {
  id: Task['id'];
  options: TaskDuplicateOptions;
}

interface MoveToFolderProps {
  id: Task['id'];
  parentId: Task['parentId'];
  assignedToId?: Task['assignedToId'];
}

interface Props {
  organization: Pick<Organization, 'id' | 'name'>;
}

export const OrganizationLibraryPage: FC<Props> = ({ organization }) => {
  const { data: userData } = useLoadAuthUserData();

  const {
    activeFolder,
    search,
    assignStatus,
    sort: taskSort,
    onChangeAssignStatus,
    onChangeSearch,
    onChangeActiveFolder
  } = useTasksContext();

  const tableRef = useRef<DataTableHandle>(null);

  const [isOnlyMyStuff, setIsOnlyMyStuff] = useState<boolean>(
    !userData?.hasAdminRights
  );
  const [isCreateFolderModalOpen, setIsCreateFolderModalOpen] = useState(false);
  const [isCreateTaskModalOpen, setIsCreateTaskModalOpen] = useState(false);
  const [selectedTasks, setSelectedTasks] = useState<Task[]>([]);
  const [isCombineEnabled, setIsCombineEnabled] = useState<boolean>(false);
  const [isSharedLibraryLoading, setIsSharedLibraryLoading] =
    useState<boolean>(false);
  const [sharedActiveFolder, setSharedActiveFolder] = useState<Task | null>(
    null
  );
  const [isDnDForbidden, setIsDnDForbidden] = useState<boolean>(false);
  const [hasDnDBanner, setHasDnDBanner] = useState<boolean>(
    !LocalStorage.getItem(HIDE_DND_BANNER)
  );
  const [copyToSharedLibraryOptions, setCopyToSharedLibraryOptions] =
    useState<null | TaskDuplicateProps>(null);

  const onToggleCreateFolderModal = useCallback(() => {
    setIsCreateFolderModalOpen(
      (prevIsCreateFolderModalOpen) => !prevIsCreateFolderModalOpen
    );
  }, []);

  const onToggleCreateTaskModal = useCallback(() => {
    setIsCreateTaskModalOpen(
      (prevIsCreateTaskModalOpen) => !prevIsCreateTaskModalOpen
    );
  }, []);

  const createdByIdEqQuery = isOnlyMyStuff ? userData?.id : undefined;

  const assignedToIdIsNullQuery =
    assignStatus === TaskAssignStatus.Unassigned ||
    (assignStatus === TaskAssignStatus.Assigned ? false : undefined);

  const options = useMemo(
    () =>
      activeFolder
        ? {
            parentIdEq: activeFolder!.id,
            parentIdIsNull: undefined
          }
        : { parentIdIsNull: true, parentIdEq: undefined },
    [activeFolder]
  );

  const [debouncedSearch] = useDebounce(search, 1000);

  const taskQuery = useMemo(
    () => ({
      search: debouncedSearch === '' ? undefined : debouncedSearch,
      organizationIdEq: organization.id,
      createdByIdEq: createdByIdEqQuery,
      sharableEq: false,
      assignedToIdIsNull: assignedToIdIsNullQuery,
      sort: [taskSort],
      ...options
    }),
    [
      assignedToIdIsNullQuery,
      createdByIdEqQuery,
      debouncedSearch,
      options,
      organization.id,
      taskSort
    ]
  );

  const {
    tableData,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isRepertoiresError,
    isError,
    formattedActiveFolder,
    isDataUpdating
  } = useOrganizationLibrary({
    taskQuery,
    taskSort,
    activeFolder,
    tableRef
  });

  const {
    data: taskCount,
    isError: isTaskCountError,
    isLoading: isTaskCountLoading
  } = useQuery<number, ApiError>(
    queryKeys.taskCount({
      ...taskQuery,
      parentIdEq: undefined,
      parentIdIsNull: undefined,
      typeEq: TaskType.Task
    }),
    () =>
      fetchTaskCount({
        ...taskQuery,
        parentIdEq: undefined,
        parentIdIsNull: undefined,
        typeEq: TaskType.Task
      }),
    {
      retry: 0,
      staleTime: ACCESS_TOKEN_EXPIRATION
    }
  );

  const updateActiveFolder = useCallback(
    (isMoveToSharedLibrary: boolean) => {
      if (isMoveToSharedLibrary && sharedActiveFolder) {
        setSharedActiveFolder({
          ...sharedActiveFolder,
          imageUrl: sharedActiveFolder.imageUrl,
          isFolder: sharedActiveFolder.isFolder,
          tasksAmount: (sharedActiveFolder.tasksAmount || 0) + 1
        });
      }

      if (!isMoveToSharedLibrary && activeFolder) {
        onChangeActiveFolder({
          ...activeFolder,
          imageUrl: activeFolder.imageUrl,
          isFolder: activeFolder.isFolder,
          tasksAmount: (activeFolder.tasksAmount || 0) + 1
        });
      }
    },
    [activeFolder, onChangeActiveFolder, sharedActiveFolder]
  );

  const {
    isLoading: isDuplicateLoading,
    isError: isDuplicateError,
    isSuccess: isDuplicateSuccess,
    mutateAsync: onDuplicateTask
  } = useMutation<Task, ApiError, TaskDuplicateProps>(
    ({ id, options }) =>
      duplicateTask(id, { ...options, organizationId: organization.id }),
    {
      onSuccess: (data) => {
        setIsSharedLibraryLoading(false);
        updateActiveFolder(data.sharable);
        queryClient.invalidateQueries(
          data.sharable ? queryKeys.sharedOrgTasksList : queryKeys.tasksList
        );
      }
    }
  );

  const { isError: isMoveTaskError, mutateAsync: onMoveTaskInFolder } =
    useMutation<Task, ApiError, MoveToFolderProps>(
      ({ id, parentId, assignedToId }) =>
        updateTaskDetails(id, {
          parentId,
          assignedToId
        }),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(queryKeys.tasksList, { active: false });
        },
        onError: () => {
          queryClient.invalidateQueries(queryKeys.tasksList);
        }
      }
    );

  const onTaskSelect = useCallback(
    (tasks: Task[]) => setSelectedTasks(tasks),
    []
  );

  const onSelectionActionSuccess = useCallback(() => {
    tableRef.current?.reset();

    setSelectedTasks([]);
  }, []);

  const handleCloseBanner = () => {
    setHasDnDBanner(false);
    LocalStorage.setItem(HIDE_DND_BANNER, true);
  };

  const isInitialLoading = useInitialLoading(isFetching || isTaskCountLoading);

  const onDragEnd = useCallback(
    ({ source, destination, draggableId, type, combine }: DropResult) => {
      const isDraggableFolder = draggableId.startsWith('row');
      const isFolderCombine = combine?.draggableId.startsWith('row');
      const draggableItemId = isDraggableFolder
        ? draggableId.substr(ROW_PREFIX_LENGTH)
        : draggableId;

      const isMoveToSharedLibrary =
        destination?.droppableId === DndDroppableContainerIds.SharedLibrary;
      const isMoveBetweenTables =
        destination &&
        destination.droppableId !== source.droppableId &&
        (!isDraggableFolder || !(activeFolder || sharedActiveFolder));
      const isMoveItemToFolder =
        type === TableDnDType.Default && isFolderCombine && !isDraggableFolder;

      if (isMoveBetweenTables && !isMoveToSharedLibrary) {
        onDuplicateTask({
          id: draggableItemId,
          options: {
            sharable: false,
            publishStatus: TaskStatus.Published,
            parentId: activeFolder ? activeFolder.id : null,
            assignedToId: activeFolder ? activeFolder.assignedToId : null
          }
        });
      }

      if (isMoveBetweenTables && isMoveToSharedLibrary) {
        setCopyToSharedLibraryOptions({
          id: draggableItemId,
          options: {
            sharable: true,
            assignedToId: null,
            publishStatus: TaskStatus.Published,
            parentId: sharedActiveFolder ? sharedActiveFolder.id : null
          }
        });
      }

      if (isMoveItemToFolder) {
        const folderId = combine?.draggableId.substr(ROW_PREFIX_LENGTH);

        const folderAssignedToId = tableData.find(
          (item) => item.id === folderId
        )?.assignedToId;
        const taskAssignedToId = tableData.find(
          (item) => item.id === draggableId
        )?.assignedToId;

        if (taskAssignedToId && folderAssignedToId !== taskAssignedToId) {
          setIsDnDForbidden(true);
          setIsCombineEnabled(false);

          return;
        }

        onMoveTaskInFolder({
          id: draggableId,
          parentId: folderId || null,
          assignedToId: taskAssignedToId ? undefined : folderAssignedToId
        });

        const updatedTasks = updateDraggedOrganizationItemToFolder({
          records: tableData,
          draggableId,
          folderId
        });

        queryClient.setQueryData(queryKeys.filteredTasks(taskQuery), {
          pages: [updatedTasks]
        });
      }

      setIsCombineEnabled(false);
    },
    [
      activeFolder,
      onDuplicateTask,
      onMoveTaskInFolder,
      sharedActiveFolder,
      tableData,
      taskQuery
    ]
  );

  const onDragStart = useCallback(({ draggableId, source }) => {
    const isCombineEnabled =
      source.droppableId === DndDroppableContainerIds.SharedLibrary
        ? false
        : !draggableId?.startsWith('row');

    setIsCombineEnabled(isCombineEnabled);
  }, []);

  return (
    <>
      <StatusSnackBar
        isError={isError || isTaskCountError || isRepertoiresError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      <StatusSnackBar
        isError={isDuplicateError || isMoveTaskError}
        errorMessage={ErrorMessages.FailedAction}
        isSuccess={isDuplicateSuccess}
        successMessage="Item successfully copied"
      />
      <StatusSnackBar
        hideDuration={5000}
        isError={isDnDForbidden}
        errorMessage="You can not move to another assigned folder"
        onClose={() => setIsDnDForbidden(false)}
      />
      <CopyToSharedLibraryModal
        onSuccess={() => {
          setIsSharedLibraryLoading(true);
          onDuplicateTask(copyToSharedLibraryOptions!);
        }}
        isOpen={!!copyToSharedLibraryOptions}
        onCloseModal={() => {
          setCopyToSharedLibraryOptions(null);
        }}
      />

      <LoadingOverlay
        loading={
          (isDataUpdating && !activeFolder) ||
          isInitialLoading ||
          (isDuplicateLoading && !isSharedLibraryLoading)
        }
      >
        <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
          <Grid container className={styles.container}>
            <Grid item xs={9} className={styles['my-tasks']}>
              <Grid container alignItems="center" className={styles.header}>
                <h1 className={styles.title}>My tasks</h1>
                <Chip
                  size="small"
                  label={`${taskCount || 0} task${checkForPlural(
                    taskCount || 0
                  )}`}
                  className={styles.chip}
                />
              </Grid>
              <OrganizationLibraryActionsBar
                searchValue={search}
                searchLabel="Search for my tasks or folders"
                assignStatus={assignStatus}
                isOnlyMyStuff={isOnlyMyStuff}
                onChangeIsOnlyMyStuff={(e) => {
                  setIsOnlyMyStuff(e.target.checked);
                }}
                onChangeSearchValue={onChangeSearch}
                onChangeAssignStatus={(e) => {
                  onChangeAssignStatus(e.target.value as TaskAssignStatus);
                }}
              />
              <If condition={!isInitialLoading}>
                <If condition={hasDnDBanner}>
                  <Grid
                    container
                    justifyContent="space-between"
                    alignItems="center"
                    className={styles['dnd-banner']}
                  >
                    <span>Drag and drop the tasks to copy to your library</span>
                    <IconButton
                      aria-label="Close banner"
                      onClick={handleCloseBanner}
                    >
                      <CloseSharp />
                    </IconButton>
                  </Grid>
                </If>
                <DataTable
                  ref={tableRef}
                  columns={columns}
                  data={
                    formattedActiveFolder
                      ? [formattedActiveFolder, ...tableData]
                      : tableData
                  }
                  className={styles.table}
                  hasNextPage={hasNextPage}
                  isLoading={
                    isFetching || !!(activeFolder && !formattedActiveFolder)
                  }
                  onLoadMore={fetchNextPage}
                  onRowSelect={onTaskSelect}
                  isCombineEnabled={isCombineEnabled}
                  isPresentDragDropContext={false}
                  cancelDndStyles
                  emptyMessage={
                    debouncedSearch || assignStatus !== TaskAssignStatus.All
                      ? EmptyStateMessages.Search
                      : EmptyStateMessages.TaskPage
                  }
                />
              </If>
            </Grid>
            <Grid item xs={3}>
              <SharedLibrary
                organization={organization}
                activeFolder={sharedActiveFolder}
                onChangeActiveFolder={setSharedActiveFolder}
                isLoading={isSharedLibraryLoading}
              />
            </Grid>
          </Grid>
        </DragDropContext>

        <CreateTaskFab
          onCreateTask={onToggleCreateTaskModal}
          onCreateFolder={onToggleCreateFolderModal}
          showCreateFolderButton={!activeFolder}
        />
        <OrganizationLibraryStatusBar
          selectedTasks={selectedTasks}
          onSuccess={onSelectionActionSuccess}
        />
      </LoadingOverlay>

      <CreateFolderModal
        options={{
          organizationIdEq: organization.id
        }}
        hideIconField
        hideDescriptionField
        onCloseModal={onToggleCreateFolderModal}
        isOpen={isCreateFolderModalOpen}
      />

      <CreateTaskModal
        options={{
          organizationIdEq: organization.id,
          assignedToEq: activeFolder?.assignedToId || undefined,
          assignedToIdIsNull: activeFolder?.assignedToId === null
        }}
        activeFolderId={activeFolder?.id}
        onCloseModal={onToggleCreateTaskModal}
        isOpen={isCreateTaskModalOpen}
      />
    </>
  );
};
