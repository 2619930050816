import { deserialize, object, serializable } from 'serializr';

import { TaskAsset } from './TaskAsset.entity';

export class TaskAssetGroup {
  @serializable(object(TaskAsset))
  data = new TaskAsset();

  @serializable
  date = '';

  static deserialize(json: Object | string): TaskAssetGroup {
    return deserialize(TaskAssetGroup, json);
  }

  static deserializeAsList(list: TaskAssetGroup[]): TaskAssetGroup[] {
    return list.map(TaskAssetGroup.deserialize);
  }
}
