import React, { FC } from 'react';
import { UserRoles } from 'enums/UserRoles.enum';
import { UserScopes } from 'enums/UserScopes.enum';

import { permissionsContext } from './usePermissions';

// This data could be retrieved from API in the future
const SuperAdmin: UserScopes[] = [
  UserScopes.AccessApp,
  UserScopes.ViewDashboard,
  UserScopes.ViewOrganizations,
  UserScopes.ViewAvailLibrary,
  UserScopes.ViewTask,
  UserScopes.ViewStaffTab,
  UserScopes.ViewManagerReport,
  UserScopes.EditLicenses,
  UserScopes.EditOrganizationInfo,
  UserScopes.ChangeOrganizationAdmin,
  UserScopes.ArchiveUsers
];
const AdminPermission: UserScopes[] = [
  UserScopes.AccessApp,
  UserScopes.EditOrganizationInfo,
  UserScopes.ViewManagerReport,
  UserScopes.ViewStaffTab,
  UserScopes.RequestMoreLicenses
];
const ManagerPermission: UserScopes[] = [
  UserScopes.AccessApp,
  UserScopes.EditOrganizationInfo,
  UserScopes.ViewManagerReport,
  UserScopes.ViewStaffTab,
  UserScopes.RequestMoreLicenses
];
const FacilitatorPermission: UserScopes[] = [UserScopes.AccessApp];
const PassiveFacilitatorPermission: UserScopes[] = [];
const LearnerPermission: UserScopes[] = [];

export const PermissionsProvider: FC = ({ children }) => (
  <permissionsContext.Provider
    value={{
      [UserRoles.SuperAdmin]: new Set(SuperAdmin),
      [UserRoles.Admin]: new Set(AdminPermission),
      [UserRoles.Facilitator]: new Set(FacilitatorPermission),
      [UserRoles.Learner]: new Set(LearnerPermission),
      [UserRoles.Manager]: new Set(ManagerPermission),
      [UserRoles.PassiveFacilitator]: new Set(PassiveFacilitatorPermission)
    }}
  >
    {children}
  </permissionsContext.Provider>
);
