import React, { FC, useEffect, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import {
  generatePath,
  useHistory,
  useParams,
  useRouteMatch
} from 'react-router-dom';
import { LoadingButton } from '@mui/lab';
import { Autocomplete, Button, Grid, TextField } from '@mui/material';
import { fetchOrganizations } from 'actions/Organization/organizationActions';
import { transferUser } from 'actions/User/userActions';
import {
  ConfirmationScreen,
  ConfirmationScreenTheme
} from 'components/ConfirmationScreen';
import { Modal } from 'components/Modal';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Organization } from 'entities/Organization.entity';
import { User } from 'entities/User.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { Routes } from 'enums/Routes.enum';
import { queryClient } from 'index';
import { ACCESS_TOKEN_EXPIRATION, MAX_PAGE_LIMIT } from 'utils/constants';

import styles from './MoveToOrganizationModal.module.scss';

interface Props {
  userId: User['id'];
  fullName: User['fullName'];
  isOpen: boolean;
  onCloseModal: () => void;
}

export const MoveToOrganizationModal: FC<Props> = ({
  userId,
  fullName,
  isOpen,
  onCloseModal
}) => {
  const { slugId, slug } = useParams<{
    slugId: string;
    slug: string;
  }>();
  const history = useHistory();

  const [selectedOrganization, setSelectedOrganization] =
    useState<Organization | null>(null);

  const {
    data: organizationsData,
    isLoading: isOrganizationsLoading,
    isError: isOrganizationsError
  } = useQuery(
    queryKeys.organizationsList,
    () =>
      fetchOrganizations({
        sort: ['name:ASC', 'status:ASC', 'createdAt:DESC'],
        limit: MAX_PAGE_LIMIT
      }),
    {
      staleTime: ACCESS_TOKEN_EXPIRATION,
      retry: 0
    }
  );

  const currentOrganization = organizationsData?.find(
    (organization) => organization.slugId === slugId
  );

  const {
    isSuccess: isTransferUserSuccess,
    isLoading: isTransferUserLoading,
    error: transferUserError,
    isError: isTransferUserError,
    mutateAsync: onTransferUser
  } = useMutation<User, ApiError>(
    () => transferUser(userId, selectedOrganization!.id),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(queryKeys.organizations);
        queryClient.invalidateQueries(queryKeys.users);
      }
    }
  );

  const organizationRoute = useRouteMatch(Routes.OrganizationStaff)
    ? Routes.OrganizationStaff
    : Routes.OrganizationPeople;

  const onOpenUsersList = () => {
    history.push(
      generatePath(organizationRoute, {
        slugId,
        slug
      })
    );
  };

  const onOpenNewOrganization = () => {
    history.push(
      generatePath(organizationRoute, {
        slugId: selectedOrganization!.slugId,
        slug: selectedOrganization!.slug
      })
    );
  };

  useEffect(() => setSelectedOrganization(null), [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onCloseModal={isTransferUserSuccess ? onOpenUsersList : onCloseModal}
    >
      <StatusSnackBar
        isError={isOrganizationsError || isTransferUserError}
        errorMessage={
          isOrganizationsError
            ? ErrorMessages.FailedGetRequest
            : transferUserError?.errorMessage || ErrorMessages.FailedPostRequest
        }
        className={styles.notification}
      />

      {isTransferUserSuccess ? (
        <ConfirmationScreen
          onSubmit={onOpenUsersList}
          onCancel={onOpenNewOrganization}
          submitButtonTitle="Close"
          cancelButtonTitle={`Go to ${selectedOrganization!.name}`}
          theme={ConfirmationScreenTheme.Primary}
        >
          {`${fullName} has been successfully moved to ${
            selectedOrganization!.name
          }`}
        </ConfirmationScreen>
      ) : (
        <div className={styles.container}>
          <Grid>
            <p className={styles.title}>
              Move <span className={styles.highlighted}>{fullName}</span> to new
              organization
            </p>
            <Autocomplete
              id="choose-organization"
              aria-label="Choose organization"
              options={organizationsData || []}
              loading={isOrganizationsLoading}
              getOptionLabel={(option: Organization) => option.name}
              renderOption={(props, option) => {
                return (
                  <li {...props} key={option.id}>
                    {option.name}
                  </li>
                );
              }}
              getOptionDisabled={(option) =>
                option.id === currentOrganization?.id
              }
              renderInput={(params) => (
                <TextField {...params} label="Choose organization" />
              )}
              onChange={(_e, value) => {
                if (typeof value !== 'string') {
                  setSelectedOrganization(value || null);
                }
              }}
              freeSolo
              open
              classes={{
                paper: styles.paper,
                listbox: styles.listbox
              }}
            />
          </Grid>
          <Grid container alignItems="stretch" spacing={2}>
            <Grid item xs={6}>
              <LoadingButton
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
                disabled={!selectedOrganization}
                onClick={() => onTransferUser()}
                loading={isOrganizationsLoading || isTransferUserLoading}
              >
                Move
              </LoadingButton>
            </Grid>
            <Grid item xs={6}>
              <Button
                fullWidth
                color="primary"
                variant="outlined"
                onClick={onCloseModal}
              >
                Cancel
              </Button>
            </Grid>
          </Grid>
        </div>
      )}
    </Modal>
  );
};
