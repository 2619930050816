import React, { FC, useCallback } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList } from 'react-window';
import InfiniteLoader from 'react-window-infinite-loader';
import { Grid } from '@mui/material';
import { TableLoadingRow } from 'components/DataTable/TableLoadingRow';
import { EmptyState } from 'components/EmptyState';
import { If } from 'components/If';
import { Task } from 'entities/Task.entity';
import { useSharedLibraryContext } from 'hooks/Task/useSharedLibraryContext';
import { TaskCard } from 'views/SharedLibrary/TaskCard';

import styles from './TaskCardGrid.module.scss';

const MIN_CARD_WIDTH = 260;
const CARD_PADDING = 26;
const CONTAINER_RIGHT_PADDING = 24;
const ROW_HEIGHT = 232;
const ACTIONS_HEIGHT = 78;
const DEFAULT_THRESHOLD = 15;

type CheckItemLoaded = (rowCount: number) => (index: number) => boolean;
interface Props {
  data: Task[];
  hasNextPage?: boolean;
  isLoading?: boolean;
  isInitialLoading?: boolean;
  onLoadMore?: () => void;
  emptyMessage: string;
  onAddToSharedLibrary?: (taskId: Task['id']) => void;
  onAddToMyTasks?: (taskId: Task['id']) => void;
}

const emptyFunction = () => {};

export const TaskCardGrid: FC<Props> = ({
  data,
  hasNextPage,
  isLoading,
  onLoadMore = emptyFunction,
  emptyMessage,
  isInitialLoading,
  onAddToSharedLibrary,
  onAddToMyTasks
}) => {
  const { activeRepertoire, onChangeActiveTask } = useSharedLibraryContext();

  const isItemLoaded = useCallback<CheckItemLoaded>(
    (rowCount) => (index) => !hasNextPage || index < rowCount,
    [hasNextPage]
  );

  const renderCards = useCallback(
    ({ index, style, columnCount, taskWidth }) => {
      const startIndex = index * columnCount;
      const tasks = data?.slice(startIndex, startIndex + columnCount);

      if (!tasks) {
        return <TableLoadingRow />;
      }

      return (
        <Grid
          container
          spacing={3}
          style={style}
          className={styles['card-row']}
        >
          {tasks.map((task) => (
            <Grid
              item
              xs
              sm
              md
              lg
              key={task.id}
              style={{ maxWidth: `${taskWidth}px` }}
              data-testid="task"
            >
              <TaskCard
                task={task}
                onSelectCard={() => onChangeActiveTask(task)}
                onAddToSharedLibrary={onAddToSharedLibrary}
                onAddToMyTasks={onAddToMyTasks}
              />
            </Grid>
          ))}
        </Grid>
      );
    },
    [data, onAddToMyTasks, onAddToSharedLibrary, onChangeActiveTask]
  );

  return (
    <div className={styles['task-card-grid']}>
      <h3 className={styles.title}>{activeRepertoire?.title}</h3>
      <If condition={!data.length && !isLoading}>
        <EmptyState title={emptyMessage} />
      </If>

      <If condition={!!data.length && !isInitialLoading}>
        <div className={styles.content}>
          <AutoSizer>
            {({ height, width }) => {
              const columnCount = Math.floor(
                width / (MIN_CARD_WIDTH + CARD_PADDING)
              );
              const taskWidth = (width - CONTAINER_RIGHT_PADDING) / columnCount;
              const rowsCount = Math.ceil(data.length / columnCount);

              return (
                <InfiniteLoader
                  isItemLoaded={isItemLoaded(rowsCount)}
                  itemCount={hasNextPage ? rowsCount + 1 : rowsCount}
                  loadMoreItems={isLoading ? emptyFunction : onLoadMore}
                  threshold={DEFAULT_THRESHOLD}
                >
                  {({ onItemsRendered, ref }) => (
                    <FixedSizeList
                      height={height}
                      width={width}
                      itemCount={rowsCount}
                      onItemsRendered={onItemsRendered}
                      itemSize={
                        onAddToSharedLibrary || onAddToMyTasks
                          ? ROW_HEIGHT + ACTIONS_HEIGHT
                          : ROW_HEIGHT
                      }
                      ref={ref}
                    >
                      {(props) =>
                        renderCards({ ...props, columnCount, taskWidth })
                      }
                    </FixedSizeList>
                  )}
                </InfiniteLoader>
              );
            }}
          </AutoSizer>
        </div>
      </If>
    </div>
  );
};
