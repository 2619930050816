export enum TaskStatus {
  All = 'all',
  Published = 'published',
  Draft = 'draft'
}

export const TaskStatusLabels = Object.freeze<Record<TaskStatus, string>>({
  [TaskStatus.All]: 'All',
  [TaskStatus.Published]: 'Published',
  [TaskStatus.Draft]: 'Draft'
});

export const taskStatusOptions = Object.values(TaskStatus).map((value) => ({
  value,
  label: TaskStatusLabels[value]
}));
