import React, { CSSProperties } from 'react';
import { DraggableProvided } from 'react-beautiful-dnd';
import { Row as TableRow } from 'react-table';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import cn from 'classnames';
import { RowMoveOptions } from 'enums/RowMoveOptions.enum';

import styles from './DataTable.module.scss';

export type Data = object & {
  id: string;
  isFolder?: boolean;
  parentId?: string | null;
  tasksAmount?: number | null;
};

interface Props<T extends Data> {
  provided: DraggableProvided;
  item: TableRow<T & { isCombining?: boolean }>;
  style?: CSSProperties;
  isDragging?: boolean;
  isCombining?: boolean;
  isCombiningHover?: boolean;
  cancelDndStyles?: boolean;
  isCopyDragging?: boolean;
  renderSubRows?: (props: SubRows) => void;
  userRowProps?: UserRowProps;
  onMove?: (value: OnMoveActionProps) => void;
}

export interface SubRows {
  row: TableRow;
  onMove?: (value: OnMoveActionProps) => void;
}

interface UserRowProps {
  onMoveUp?: () => void;
  onMoveDown?: () => void;
}

export interface OnMoveActionProps {
  rowId: string;
  index: number;
  parentId: string | null;
  action: RowMoveOptions;
}

export const Row: <T extends Data>(
  p: Props<T>
) => React.ReactElement<Props<T>> = ({
  provided,
  renderSubRows,
  item,
  style,
  isDragging,
  isCombining,
  isCombiningHover,
  isCopyDragging,
  userRowProps,
  onMove,
  cancelDndStyles
}) => {
  const { isExpanded, depth, original, isSelected } = item;

  return (
    <div
      ref={provided.innerRef}
      {...provided.draggableProps}
      style={{
        ...style,
        ...(!cancelDndStyles && provided.draggableProps.style),
        opacity: 1
      }}
      data-testid="tr"
      className={cn(
        isDragging && styles['row-dragging'],
        (depth || (isExpanded && original.tasksAmount)) && styles.expanded,
        isCombiningHover && original.isFolder && styles['combine-hover'],
        isSelected && styles.selected
      )}
    >
      {isCombining && <DriveFileMoveIcon className={styles.icon} />}
      {isCopyDragging && <ContentCopyIcon className={styles.icon} />}
      <div
        {...provided.dragHandleProps}
        {...item.getRowProps()}
        className={cn(styles.tr, isDragging && styles.dragging)}
      >
        {item.cells.map((cell) => {
          return (
            // eslint-disable-next-line react/jsx-key
            <div {...cell.getCellProps()} className={styles.td}>
              {cell.render('Cell', userRowProps)}
            </div>
          );
        })}
      </div>
      {renderSubRows &&
        isExpanded &&
        renderSubRows({
          row: { ...item, isCombiningHover },
          onMove
        } as unknown as SubRows)}
    </div>
  );
};
