import React, { FC, useState } from 'react';
import { useMutation, useQuery } from 'react-query';
import { generatePath, useParams } from 'react-router-dom';
import { fetchLearnerReportCSV } from 'actions/Report/reportActions';
import {
  fetchUserTasksDateRangeForReport,
  fetchUserTasksForReport
} from 'actions/Task/taskActions';
import { fetchUserData } from 'actions/User/userActions';
import { EmptyState } from 'components/EmptyState';
import { If } from 'components/If';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Task } from 'entities/Task.entity';
import { User } from 'entities/User.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { Routes } from 'enums/Routes.enum';
import { TaskSort } from 'enums/TaskSort.enum';
import { ACCESS_TOKEN_EXPIRATION } from 'utils/constants';
import { saveAsCSV } from 'utils/helpers/filesHelpers';
import { ReportContainer } from 'views/Reports/ReportContainer';
import { ReportTask } from 'views/Reports/ReportTask';

import styles from './LearnerReport.module.scss';

const defaultDateRange = () => {
  const today = new Date();
  const from = new Date(
    today.getFullYear(),
    today.getMonth() - 6,
    today.getDate()
  );
  return { from, to: today };
};

export const LearnerReport: FC = () => {
  const showDateRangeSelector = true;

  const { slugId, slug, id } =
    useParams<{ id: string; slugId: string; slug: string }>();
  const [dateRange, setDateRange] = useState<{
    from: Date | null;
    to: Date | null;
  }>(defaultDateRange);

  const { isError, isFetching, data } = useQuery<User, ApiError>(
    queryKeys.user(id),
    () => fetchUserData(id),
    {
      retry: 0,
      staleTime: ACCESS_TOKEN_EXPIRATION
    }
  );

  const {
    isLoading: isExportLoading,
    mutateAsync: exportCSV,
    isError: isExportError
  } = useMutation<string, ApiError>(() => fetchLearnerReportCSV(data!.id), {
    retry: 0,
    onSuccess: (data) => {
      saveAsCSV(data);
    }
  });

  const {
    data: tasks,
    isError: isTasksError,
    isLoading: isTasksLoading
  } = useQuery<Task[], ApiError>(
    dateRange.from && dateRange.to
      ? queryKeys.reportLearnerDateRangeTasks(
          data?.id ?? '',
          dateRange.from.toISOString(),
          dateRange.to.toISOString()
        )
      : queryKeys.reportTasks(data?.username ?? '', {
          sort: [TaskSort.ByName]
        }),
    () =>
      dateRange.from && dateRange.to
        ? fetchUserTasksDateRangeForReport(
            data!.id,
            dateRange?.from?.toISOString() ?? new Date().toISOString(),
            dateRange?.to?.toISOString() ?? new Date().toISOString()
          )
        : fetchUserTasksForReport(data!.id),
    {
      retry: 0,
      enabled: !!data?.id,
      staleTime: ACCESS_TOKEN_EXPIRATION
    }
  );

  return (
    <ReportContainer
      isError={isError}
      onExportCSV={exportCSV}
      title="Learner Progress Report"
      createdAt={data?.formattedCreatedAt}
      isExportLoading={isExportLoading}
      isLoading={isFetching || isTasksLoading}
      backButtonLabel="Back to User Details"
      navTo={generatePath(Routes.LearnerDetails, {
        slug,
        slugId,
        id
      })}
      showDateRangeSelector={showDateRangeSelector}
      defaultDateRange={dateRange}
      onChangeDateRange={(dates) => {
        setDateRange(dates);
      }}
    >
      <StatusSnackBar
        isError={isTasksError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      <StatusSnackBar
        isError={isExportError}
        errorMessage={ErrorMessages.FailedPostRequest}
      />
      <If condition={!tasks?.length && !isTasksLoading}>
        <EmptyState />
      </If>
      <If condition={!!tasks?.length}>
        <div className={styles.overview}>
          <h2 className={styles.title}>{data?.fullName}</h2>
          <div className={styles.container}>
            <h3
              className={styles.label}
            >{`Overview of ${data?.fullName} tasks`}</h3>
            <ol className={styles.list}>
              {(tasks || []).map((task) => (
                <li key={task.id} className={styles.li}>
                  {task.title}
                </li>
              ))}
            </ol>
          </div>
        </div>
        {(tasks || []).map((task) => (
          <ReportTask
            key={task.id}
            task={task}
            fromTime={dateRange.from ?? undefined}
            toTime={dateRange.to ?? undefined}
          />
        ))}
      </If>
    </ReportContainer>
  );
};
