import React, { FC } from 'react';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Button, Tab } from '@mui/material';
import { Modal } from 'components/Modal';
import { Task } from 'entities/Task.entity';
import { DurationTab } from 'views/People/TaskLibrary/DurationTab';
import { EvidenceTab } from 'views/People/TaskLibrary/EvidenceTab';
import { ReductionTab } from 'views/People/TaskLibrary/ReductionTab';

import styles from './DataModal.module.scss';

interface Props {
  taskId: Task['id'];
  taskTitle: Task['title'];
  isOpen: boolean;
  onCloseModal: () => void;
}

const taskDataTabs = [
  'Evidence of Learning',
  'Reductions of Prompts',
  'Duration'
];

export const DataModal: FC<Props> = ({
  taskId,
  taskTitle,
  isOpen,
  onCloseModal
}) => {
  const [activeTab, setActiveTab] = React.useState('0');

  const handleChange = (_e: React.SyntheticEvent, newValue: string) => {
    setActiveTab(newValue);
  };

  return (
    <Modal
      title={`Task - ${taskTitle}`}
      isOpen={isOpen}
      onCloseModal={onCloseModal}
      modalClassName={styles.modal}
    >
      <div className={styles.container}>
        <TabContext value={activeTab}>
          <TabList
            scrollButtons
            className={styles.tabs}
            onChange={handleChange}
            aria-label="Task data tabs"
          >
            {taskDataTabs.map((tab, index) => (
              <Tab
                key={tab}
                label={tab}
                data-testid="data-tab"
                value={index.toString()}
                className={styles.tab}
              />
            ))}
          </TabList>
          <TabPanel
            value="0"
            className={styles['tab-panel']}
            classes={{ root: styles['tab-panel-root'] }}
          >
            <EvidenceTab taskId={taskId} />
          </TabPanel>
          <TabPanel
            value="1"
            className={styles['tab-panel']}
            classes={{ root: styles['tab-panel-root'] }}
          >
            <ReductionTab taskId={taskId} />
          </TabPanel>
          <TabPanel
            value="2"
            className={styles['tab-panel']}
            classes={{ root: styles['tab-panel-root'] }}
          >
            <DurationTab taskId={taskId} />
          </TabPanel>
        </TabContext>
        <div className={styles.footer}>
          <Button
            fullWidth
            color="primary"
            variant="outlined"
            onClick={onCloseModal}
          >
            Cancel
          </Button>
        </div>
      </div>
    </Modal>
  );
};
