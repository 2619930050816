import React, { FC } from 'react';
import { useMutation } from 'react-query';
import { updateTaskDetails } from 'actions/Task/taskActions';
import { Modal } from 'components/Modal';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Task } from 'entities/Task.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { useMessagesContext } from 'hooks/Auth/useMessagesContext';
import { queryClient } from 'index';
import { TasksQuery } from 'services/API/Task/TaskApi';
import { FormData, TaskForm } from 'views/Task/TaskForm';

interface Props {
  isOpen: boolean;
  onCloseModal: () => void;
  onEditTask: () => void;
  taskQuery?: TasksQuery;
  data: Pick<
    Task,
    | 'id'
    | 'title'
    | 'description'
    | 'imageUrl'
    | 'repertoireId'
    | 'parentId'
    | 'image'
  >;
}

export const EditTaskModal: FC<Props> = ({
  isOpen,
  onCloseModal,
  onEditTask,
  data,
  taskQuery
}) => {
  const { showSnackBar } = useMessagesContext();
  const { isLoading, isError, isSuccess, error, mutateAsync } = useMutation<
    Task,
    ApiError,
    FormData
  >(
    (formData: FormData) =>
      updateTaskDetails(data.id, {
        title: formData.title,
        repertoireId: formData.repertoireId,
        description: formData.description || '',
        parentId: formData.parentId === 'none' ? null : formData.parentId,
        image: formData.image !== data.imageUrl ? formData.image : undefined
      }),
    {
      onSuccess: async (data) => {
        showSnackBar({
          isSuccess: true,
          successMessage: 'Task successfully updated'
        });

        await queryClient.invalidateQueries(queryKeys.tasksList);
        await queryClient.setQueryData(queryKeys.taskDetails(data.id), data);
        await onEditTask();

        onCloseModal();
      }
    }
  );

  return (
    <>
      <StatusSnackBar
        isError={isError}
        isSuccess={isSuccess}
        successMessage="Task successfully updated"
        errorMessage={error?.errorMessage || ErrorMessages.FailedPostRequest}
      />
      <Modal title="Edit task" isOpen={isOpen} onCloseModal={onCloseModal}>
        <TaskForm
          onSubmit={mutateAsync}
          onCloseModal={onCloseModal}
          isLoading={isLoading}
          defaultValues={data}
          taskQuery={taskQuery}
          saveButtonText="Save"
        />
      </Modal>
    </>
  );
};
