import { Asset } from 'entities/Asset.entity';

import { ApiBase } from '../ApiBase';

class AssetsApi extends ApiBase {
  uploadAsset(assetData: FormData) {
    return this.client.post<Asset>('/upload', assetData);
  }
}

const instance = new AssetsApi('/files');

export { instance as AssetsApi };
