import React, { ChangeEvent } from 'react';
import { Search } from '@mui/icons-material';
import {
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material';

import styles from './TableActionBar.module.scss';

interface SelectOption {
  label: string;
  value: string;
}

interface Props {
  searchValue: string;
  searchLabel?: string;
  selectOptions: SelectOption[];
  selectOption?: string;
  onChangeSearchValue: (value: string) => void;
  onChangeSelectOption: (e: SelectChangeEvent<string>) => void;
}

export const TableActionBar: React.FC<Props> = ({
  selectOptions,
  selectOption,
  searchValue,
  searchLabel,
  onChangeSearchValue,
  onChangeSelectOption
}) => {
  return (
    <Grid container>
      <Grid item xs={3}>
        <FormControl className={styles.select}>
          <InputLabel htmlFor="user-status-filter">Filter</InputLabel>
          <Select
            size="small"
            value={selectOption}
            data-testid="action-bar-select"
            onChange={onChangeSelectOption}
            defaultValue={selectOptions[0]?.value}
            input={<OutlinedInput label="Filter" id="user-status-filter" />}
          >
            {selectOptions.map(({ label, value }) => (
              <MenuItem
                key={value}
                value={value}
                data-testid="action-bar-option"
              >
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={9}>
        <TextField
          size="small"
          role="search"
          label="Search"
          variant="outlined"
          value={searchValue}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            onChangeSearchValue(e.target.value)
          }
          className={styles.search}
          id="outlined-search-input"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search className={styles.icon} />
              </InputAdornment>
            )
          }}
          inputProps={{
            'aria-label': `${searchLabel || 'Search'}`
          }}
        />
      </Grid>
    </Grid>
  );
};
