import React, { FC, useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, TextField } from '@mui/material';
import { changeLearnerPassword } from 'actions/Learner/learnerActions';
import { Modal } from 'components/Modal';
import { PasswordAdornment } from 'components/PasswordAdornment';
import { PasswordWarningBanner } from 'components/PasswordWarningBanner';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { isPasswordMatch, isPasswordValid } from 'utils/helpers/validators';

import styles from './ChangeLearnerPasswordForm.module.scss';

interface PasswordFormData {
  newPassword: string;
  confirmPassword: string;
}

interface Props {
  isOpen: boolean;
  learnerId: string;
  onCloseModal: () => void;
}

export const ChangeLearnerPasswordForm: FC<Props> = ({
  learnerId,
  isOpen,
  onCloseModal
}) => {
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);
  const [newPasswordError, setNewPasswordError] = useState<string>('');
  const [confirmPasswordError, setConfirmPasswordError] = useState<string>('');

  const {
    control,
    handleSubmit,
    getValues,
    setError,
    clearErrors,
    formState: { errors }
  } = useForm<PasswordFormData>({
    defaultValues: {
      newPassword: '',
      confirmPassword: ''
    },
    mode: 'onChange',
    shouldUnregister: true,
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false
  });

  const { isLoading, isError, isSuccess, mutateAsync } = useMutation<
    void,
    ApiError,
    PasswordFormData
  >(
    (passwordData: PasswordFormData) =>
      changeLearnerPassword(learnerId, passwordData.newPassword),
    {
      retry: 0,
      onSuccess: () => {
        onCloseModal();
      }
    }
  );

  const validateNewPassword = useCallback(
    (password: string): string => {
      let errorMessage = '';
      const confirmPassword = getValues('confirmPassword');

      if (!password) {
        errorMessage = ErrorMessages.FailedRequiredField;
      } else if (!isPasswordValid(password)) {
        errorMessage = ErrorMessages.NotValidNewPassword;
      }

      if (confirmPassword && !isPasswordMatch(password, confirmPassword)) {
        setError('confirmPassword', { type: 'custom' });
        setConfirmPasswordError(ErrorMessages.NotValidConfirmPassword);
      } else {
        clearErrors('confirmPassword');
      }

      setNewPasswordError(errorMessage);

      return errorMessage;
    },
    [clearErrors, getValues, setError]
  );

  const validateConfirmPassword = useCallback(
    (confirmPassword: string): string => {
      let errorMessage = '';
      const newPassword = getValues('newPassword');

      if (!confirmPassword) {
        errorMessage = ErrorMessages.FailedRequiredField;
      } else if (!isPasswordMatch(confirmPassword, newPassword)) {
        errorMessage = ErrorMessages.NotValidConfirmPassword;
      }

      setConfirmPasswordError(errorMessage);

      return errorMessage;
    },
    [getValues]
  );

  return (
    <>
      <StatusSnackBar
        isError={isError}
        isSuccess={isSuccess}
        errorMessage={ErrorMessages.FailedPostRequest}
        successMessage="Password was successfully updated"
      />
      <Modal
        isOpen={isOpen}
        title="Change password"
        onCloseModal={onCloseModal}
      >
        <form
          className={styles['change-learner-password-form']}
          onSubmit={handleSubmit((formData) => mutateAsync(formData))}
        >
          <PasswordWarningBanner />
          <Controller
            rules={{
              validate: (value) => !validateNewPassword(value)
            }}
            name="newPassword"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type={showNewPassword ? 'text' : 'password'}
                variant="outlined"
                label="New password"
                margin="normal"
                inputRef={field.ref}
                value={field.value}
                onChange={field.onChange}
                error={!!errors?.newPassword}
                helperText={errors?.newPassword && newPasswordError}
                id="new-password-outlined-input"
                InputProps={{
                  endAdornment: (
                    <PasswordAdornment
                      isPasswordVisible={showNewPassword}
                      onShowPassword={() =>
                        setShowNewPassword(!showNewPassword)
                      }
                    />
                  )
                }}
              />
            )}
          />
          <Controller
            rules={{
              validate: (value) => !validateConfirmPassword(value)
            }}
            name="confirmPassword"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                type={showConfirmPassword ? 'text' : 'password'}
                variant="outlined"
                label="Confirm password"
                margin="normal"
                inputRef={field.ref}
                error={!!errors?.confirmPassword}
                helperText={errors?.confirmPassword && confirmPasswordError}
                id="confirm-new-password-outlined-input"
                InputProps={{
                  endAdornment: (
                    <PasswordAdornment
                      isPasswordVisible={showConfirmPassword}
                      onShowPassword={() =>
                        setShowConfirmPassword(!showConfirmPassword)
                      }
                    />
                  )
                }}
              />
            )}
          />
          <div className={styles.footer}>
            <Grid container alignItems="stretch" spacing={2}>
              <Grid item xs={6}>
                <LoadingButton
                  fullWidth
                  type="submit"
                  color="primary"
                  variant="contained"
                  loading={isLoading}
                >
                  Change password
                </LoadingButton>
              </Grid>
              <Grid item xs={6}>
                <Button
                  fullWidth
                  color="primary"
                  variant="outlined"
                  onClick={onCloseModal}
                >
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </div>
        </form>
      </Modal>
    </>
  );
};
