import { date, deserialize, serializable } from 'serializr';

export class StaffReport {
  @serializable
  id = '';

  @serializable(date())
  createdAt: Date = new Date();

  @serializable(date())
  updatedAt: Date = new Date();

  @serializable
  name = '';

  @serializable(date())
  lastActiveTime: Date = new Date();

  @serializable
  timeSpentOnAppSec = 0;

  @serializable
  numberOfSessions = 0;

  @serializable
  numberOfTasksCreated = 0;

  @serializable
  numberOfTasksAssigned = 0;

  @serializable
  numberOfLearners = 0;

  static deserialize(json: Object | string): StaffReport {
    return deserialize(StaffReport, json);
  }

  static deserializeAsList(list: StaffReport[]): StaffReport[] {
    return list.map(StaffReport.deserialize);
  }
}
