import React, { FC, useCallback, useMemo, useState } from 'react';
import { useQuery } from 'react-query';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { Button, Tooltip } from '@mui/material';
import { fetchTaskSteps } from 'actions/Task/taskActions';
import { If } from 'components/If';
import { AbsoluteSpinner } from 'components/LoadingOverlay';
import { Modal } from 'components/Modal';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Step } from 'entities/Step.entity';
import { Task } from 'entities/Task.entity';
import { EmptyStateMessages } from 'enums/EmptyStateMessages.enum';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { ACCESS_TOKEN_EXPIRATION } from 'utils/constants';
import { StepPreview } from 'views/Task/StepPreview';

interface Props {
  taskId: Task['id'];
  taskName: Task['title'];
  stepsAmount: Task['stepsAmount'];
  buttonWrapClassName?: string;
  buttonClassName?: string;
  buttonType?: 'text' | 'outlined' | 'contained';
}

export const TaskPreview: FC<Props> = ({
  taskId,
  taskName,
  stepsAmount,
  buttonType = 'contained',
  buttonWrapClassName,
  buttonClassName
}) => {
  const [isPreviewModalOpen, setIsPreviewModalOpen] = useState(false);

  const { data, error, isError, isLoading } = useQuery<Step[], ApiError>(
    queryKeys.taskSteps(taskId),
    () => fetchTaskSteps(taskId),
    {
      retry: 0,
      staleTime: ACCESS_TOKEN_EXPIRATION,
      enabled: isPreviewModalOpen
    }
  );

  const activeSteps = useMemo(
    () => data?.filter((step) => step.active),
    [data]
  );

  const onTogglePreviewTaskModal = useCallback(() => {
    setIsPreviewModalOpen((prevIsPreviewModalOpen) => !prevIsPreviewModalOpen);
  }, []);

  return (
    <>
      <StatusSnackBar
        isError={isError}
        errorMessage={error?.errorMessage || ErrorMessages.FailedGetRequest}
      />
      {stepsAmount ? (
        <span className={buttonWrapClassName}>
          <Button
            size="small"
            color="primary"
            variant={buttonType}
            startIcon={<VisibilityIcon />}
            className={buttonClassName}
            onClick={onTogglePreviewTaskModal}
          >
            Preview
          </Button>
        </span>
      ) : (
        <Tooltip title={EmptyStateMessages.Steps} placement="top">
          <span className={buttonWrapClassName}>
            <Button
              size="small"
              color="primary"
              variant={buttonType}
              startIcon={<VisibilityIcon />}
              className={buttonClassName}
              disabled
            >
              <span>Preview</span>
            </Button>
          </span>
        </Tooltip>
      )}
      <Modal
        isOpen={isPreviewModalOpen}
        onCloseModal={onTogglePreviewTaskModal}
      >
        <AbsoluteSpinner loading={isLoading} contentWidth />
        <If condition={!!activeSteps?.length}>
          <StepPreview
            taskName={taskName}
            steps={activeSteps!}
            onClose={onTogglePreviewTaskModal}
          />
        </If>
      </Modal>
    </>
  );
};
