import { STORAGE_KEY_PREFIX } from 'utils/constants';

export class SessionStorage {
  private static prefixKey(key: string): string {
    return `${STORAGE_KEY_PREFIX}${key}`;
  }

  static setItem(key: string, value: unknown): void {
    sessionStorage.setItem(
      SessionStorage.prefixKey(key),
      JSON.stringify(value)
    );
  }

  static getItem<T>(key: string): T | null {
    const value = sessionStorage.getItem(SessionStorage.prefixKey(key));

    return value ? (JSON.parse(value) as T) : null;
  }

  static async removeItem(key: string): Promise<void> {
    await sessionStorage.removeItem(SessionStorage.prefixKey(key));
  }

  static reset(): void {
    sessionStorage.clear();
  }
}
