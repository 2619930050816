import * as React from 'react';
import { useMemo } from 'react';
import { generatePath, Redirect, Route, RouteProps } from 'react-router-dom';
import { Routes } from 'enums/Routes.enum';
import { UserRoles } from 'enums/UserRoles.enum';
import { UserScopes } from 'enums/UserScopes.enum';
import { useAuthContext } from 'hooks/Auth/useAuthContext';
import { useLoadAuthUserData } from 'hooks/Auth/useLoadAuthUserData';
import { hasPermission, usePermissions } from 'services/Permissions';

interface Props extends RouteProps {
  redirectPath?: Routes;
  forAuthenticated?: boolean;
  scopes?: UserScopes[];
}

export const ProtectedRoute: React.FC<Props> = ({
  redirectPath,
  forAuthenticated = true,
  scopes,
  ...rest
}) => {
  const { isAuthenticated } = useAuthContext();

  const { data } = useLoadAuthUserData({
    enabled: isAuthenticated
  });

  const loginRedirectPath = useMemo(() => {
    if (!data) {
      return Routes.Root;
    }

    return data.role === UserRoles.SuperAdmin
      ? Routes.Organizations
      : (generatePath(Routes.OrganizationInformation, {
          slugId: data.organization!.slugId,
          slug: data.organization!.slug
        }) as Routes);
  }, [data]);

  const redirectTo = redirectPath || loginRedirectPath;
  const permissions = usePermissions();

  const canOpen = forAuthenticated
    ? (data && scopes && hasPermission(permissions[data.role], scopes)) ||
      (data && !scopes)
    : !isAuthenticated;

  return canOpen ? <Route {...rest} /> : <Redirect to={redirectTo} />;
};
