import FileSaver from 'file-saver';

export const saveAsCSV = (csv: string, isManagerReport = false): void => {
  const csvData = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  FileSaver.saveAs(
    csvData,
    `avail ${isManagerReport ? 'Manager' : 'Learner'} Report.csv`
  );
};
