import { Thumbnail } from 'entities/Thumbnail.entity';
import { StepContentType } from 'enums/StepContentType.enum';
import { deserialize, object, serializable } from 'serializr';

export class File {
  @serializable
  url = '';

  @serializable
  originalName = '';

  @serializable
  type?: StepContentType = StepContentType.text;

  @serializable(object(Thumbnail))
  thumbnail?: Thumbnail | null;

  get thumbnailUrl(): string | undefined {
    return this.thumbnail?.url;
  }

  static deserialize(json: Object | string): File {
    return deserialize(File, json);
  }
}
