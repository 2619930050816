import { Column } from 'react-table';
import { format } from 'date-fns';
import { LearnerReport } from 'entities/LearnerReport.entity';
import {
  formatSecondsToDays,
  formatSecondsToDuration
} from 'utils/helpers/timeHelpers';

export const columns: Column<LearnerReport>[] = [
  {
    id: 'name',
    Header: 'Learner name',
    accessor: 'name'
  },
  {
    id: 'timeSpentOnAppSec',
    Header: 'Time spent on app',
    accessor: ({ timeSpentOnAppSec }) =>
      timeSpentOnAppSec
        ? formatSecondsToDays(timeSpentOnAppSec) +
          formatSecondsToDuration(timeSpentOnAppSec)
        : '-'
  },
  {
    id: 'lastActiveTime',
    Header: 'Last active date',
    accessor: ({ lastActiveTime }) =>
      lastActiveTime ? format(lastActiveTime, 'd MMM yyyy') : '-'
  },
  {
    id: 'avgImprovementScorePercent',
    Header: 'Average Improvement across tasks',
    accessor: ({ avgImprovementScorePercent }) =>
      avgImprovementScorePercent ? `${avgImprovementScorePercent} %` : '-'
  },
  {
    id: 'numberOfSessions',
    Header: '№ of sessions',
    accessor: 'numberOfSessions'
  },
  {
    id: 'numberOfTasks',
    Header: 'Total № of tasks',
    accessor: 'numberOfTasks'
  },
  {
    id: 'numberOfTaskCompletions',
    Header: '№ of task completions',
    accessor: 'numberOfTaskCompletions'
  },
  {
    id: 'numberOfMasteredTasks',
    Header: 'Total tasks mastered',
    accessor: 'numberOfMasteredTasks'
  },
  {
    id: 'numberOfAssessments',
    Header: '№ of assessments completed',
    accessor: 'numberOfAssessments'
  }
];
