import React, {
  ChangeEvent,
  FC,
  MutableRefObject,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react';
import ReactDOM from 'react-dom';
import { useInfiniteQuery } from 'react-query';
import { Add, Search } from '@mui/icons-material';
import {
  Fab,
  FormControl,
  Grid,
  InputAdornment,
  InputLabel,
  ListSubheader,
  MenuItem,
  OutlinedInput,
  Select,
  SelectChangeEvent,
  TextField
} from '@mui/material';
import { fetchUsers } from 'actions/User/userActions';
import { DataTable, DataTableHandle } from 'components/DataTable';
import { If } from 'components/If';
import { AbsoluteSpinner } from 'components/LoadingOverlay';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { User } from 'entities/User.entity';
import { EmptyStateMessages } from 'enums/EmptyStateMessages.enum';
import { EntityStatus } from 'enums/EntityStatus.enum';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { UserRoles } from 'enums/UserRoles.enum';
import { useOrganizationBySlug } from 'hooks/Organization/useOrganizationBySlug';
import { useInitialLoading } from 'hooks/useInitialLoading';
import { queryClient } from 'index';
import { useDebounce } from 'use-debounce';
import { ACCESS_TOKEN_EXPIRATION, DEFAULT_PAGE_LIMIT } from 'utils/constants';
import { CreateStaffModal } from 'views/People/CreateStaffModal';
import { UserStatusBar } from 'views/People/UserStatusBar';

import { columns } from './columns';
import { filters, isRoleFilter, isStatusFilter } from './helpers';

import styles from './StaffTable.module.scss';

interface Props {
  actionBarRef: MutableRefObject<HTMLDivElement | null>;
}

export const STAFF_ROLES = [
  UserRoles.Manager,
  UserRoles.Facilitator,
  UserRoles.PassiveFacilitator
];

export const StaffTable: FC<Props> = ({ actionBarRef }) => {
  const tableRef = useRef<DataTableHandle>(null);

  const { data: organizationData, isFetching: isOrganizationFetching } =
    useOrganizationBySlug();

  const [search, setSearch] = useState<string>('');
  const [debouncedSearch] = useDebounce(search, 1000);
  const [isCreateUserModalOpen, setIsCreateUserModalOpen] = useState(false);

  const onToggleCreateUserModal = useCallback(() => {
    setIsCreateUserModalOpen(
      (prevIsCreateUserModalOpen) => !prevIsCreateUserModalOpen
    );
  }, []);

  const [statuses, setStatuses] = useState<EntityStatus[]>([]);
  const [roles, setRoles] = useState<UserRoles[]>([]);

  const [selectedUsers, setSelectedUsers] = useState<User[]>([]);

  const {
    isFetching,
    isRefetching,
    isError,
    isRefetchError,
    data,
    hasNextPage,
    fetchNextPage
  } = useInfiniteQuery(
    queryKeys.filteredStaff(organizationData!.id, {
      search: debouncedSearch,
      statuses,
      roles
    }),
    ({ pageParam }) =>
      fetchUsers({
        sort: ['firstName:ASC'],
        statusIn: statuses.length ? statuses : undefined,
        statusNe: EntityStatus.Archived,
        roleIn: roles.length ? roles : STAFF_ROLES,
        organizationIdEq: organizationData?.id,
        limit: DEFAULT_PAGE_LIMIT,
        offset: pageParam?.offset || 0,
        search: debouncedSearch === '' ? undefined : debouncedSearch
      }),
    {
      staleTime: ACCESS_TOKEN_EXPIRATION,
      retry: 0,
      keepPreviousData: true,
      getNextPageParam: (lastPage, allPages) => {
        if (lastPage.length < DEFAULT_PAGE_LIMIT) {
          return undefined;
        }

        return {
          offset: allPages.flat().length
        };
      },
      enabled: !!organizationData
    }
  );

  const onFilterChange = useCallback(
    (e: SelectChangeEvent<(EntityStatus | UserRoles)[]>) => {
      const newValue = e.target.value.slice(-1)[0];
      if (newValue === EntityStatus.All) {
        setStatuses([]);
        setRoles([]);
      } else {
        const filters = (
          e.target.value as (EntityStatus | UserRoles)[]
        ).reduce<{
          statuses: EntityStatus[];
          roles: UserRoles[];
        }>(
          (filterTypes, value) => {
            if (isRoleFilter(value as UserRoles)) {
              filterTypes.roles.push(value as UserRoles);
            } else if (isStatusFilter(value as EntityStatus)) {
              filterTypes.statuses.push(value as EntityStatus);
            }

            return filterTypes;
          },
          { roles: [], statuses: [] }
        );

        setRoles(filters.roles);
        setStatuses(filters.statuses);
      }
    },
    []
  );

  const onUsersSelect = useCallback(
    (users: User[]) => setSelectedUsers(users),
    []
  );

  const onSelectionActionSuccess = useCallback(async () => {
    tableRef.current?.reset();
    await queryClient.invalidateQueries(queryKeys.staff(organizationData!.id));

    setSelectedUsers([]);
  }, [organizationData]);

  const filterValue = useMemo(
    () =>
      !statuses.length && !roles.length
        ? [EntityStatus.All]
        : [...statuses, ...roles],
    [roles, statuses]
  );

  const isInitialLoading = useInitialLoading(
    isFetching || isOrganizationFetching
  );

  return (
    <>
      <AbsoluteSpinner loading={isInitialLoading} />
      <StatusSnackBar
        isError={isError || isRefetchError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      {actionBarRef.current &&
        ReactDOM.createPortal(
          <Grid container>
            <Grid item xs={4}>
              <FormControl className={styles.select}>
                <InputLabel htmlFor="user-role-filter">Filter</InputLabel>
                <Select
                  multiple
                  size="small"
                  value={filterValue}
                  onChange={onFilterChange}
                  data-testid="action-bar-select"
                  input={<OutlinedInput label="Filter" id="user-role-filter" />}
                >
                  {Object.entries(filters).map(([group, options]) => {
                    const menu = group
                      ? [<ListSubheader key={group}>{group}</ListSubheader>]
                      : [];

                    return [
                      ...menu,
                      ...options.map(({ label, value }) => (
                        <MenuItem
                          key={value}
                          value={value}
                          data-testid="action-bar-option"
                        >
                          {label}
                        </MenuItem>
                      ))
                    ];
                  })}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={8}>
              <TextField
                size="small"
                role="search"
                label="Search"
                variant="outlined"
                value={search}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  setSearch(e.target.value)
                }
                className={styles.search}
                id="outlined-search-input"
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <Search className={styles.icon} />
                    </InputAdornment>
                  )
                }}
                inputProps={{
                  'aria-label': 'Search for Staff'
                }}
              />
            </Grid>
          </Grid>,
          actionBarRef.current
        )}
      <CreateStaffModal
        isOpen={isCreateUserModalOpen}
        onCloseModal={onToggleCreateUserModal}
      />
      <span className={styles.fabLink}>
        <Fab
          color="primary"
          variant="extended"
          className={styles.fab}
          aria-label="Add new account"
          onClick={onToggleCreateUserModal}
        >
          <Add />
          Add new account
        </Fab>
      </span>

      <If condition={!isInitialLoading}>
        <DataTable
          ref={tableRef}
          className={styles.table}
          columns={columns}
          data={data?.pages.flat() || []}
          hasNextPage={hasNextPage}
          isLoading={isFetching}
          onLoadMore={fetchNextPage}
          onRowSelect={onUsersSelect}
          dndDisabled
          emptyMessage={
            debouncedSearch || roles.length || statuses.length
              ? EmptyStateMessages.Search
              : EmptyStateMessages.AccountPage
          }
        />
      </If>
      <UserStatusBar
        selectedUsers={selectedUsers}
        isUpdating={isRefetching}
        onSuccess={onSelectionActionSuccess}
      />
    </>
  );
};
