import React, { FC, useCallback, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Add } from '@mui/icons-material';
import { Breadcrumbs, Fab } from '@mui/material';
import { Layout } from 'components/Layout';
import { SimpleTable } from 'components/SimpleTable';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { EmptyStateMessages } from 'enums/EmptyStateMessages.enum';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { Routes } from 'enums/Routes.enum';
import { useRepertoires } from 'hooks/Task/useRepertoires';
import { CreateRepertoireModal } from 'views/Settings/CreateRepertoireModal';

import { columns } from './columns';

import styles from './SettingPage.module.scss';

export const SettingPage: FC = () => {
  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);

  const onToggleCreateModal = useCallback(() => {
    setIsCreateModalOpen((prevIsCreateModalOpen) => !prevIsCreateModalOpen);
  }, []);

  const {
    data: repertoiresData,
    isError: isRepertoireError,
    isLoading: isRepertoireLoading
  } = useRepertoires();

  const breadcrumbs = [
    <NavLink key="avail® Library" to={Routes.Library}>
      avail® Library
    </NavLink>,
    <div key="Settings">Settings</div>
  ];

  return (
    <Layout>
      <StatusSnackBar
        isError={isRepertoireError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />

      <div className={styles.container}>
        <Breadcrumbs
          separator="›"
          aria-label="setting breadcrumb"
          className={styles.breadcrumbs}
        >
          {breadcrumbs}
        </Breadcrumbs>
        <h3 className={styles.title}>Settings</h3>

        <div className={styles['table-box']}>
          <h4 className={styles['table-title']}>Manage repertoire</h4>

          <SimpleTable
            isLoading={isRepertoireLoading}
            data={repertoiresData || []}
            columns={columns}
            emptyMessage={EmptyStateMessages.Default}
          />
        </div>

        <Fab
          variant="extended"
          className={styles.fab}
          color="primary"
          aria-label="Create repertoire"
          onClick={onToggleCreateModal}
        >
          <Add />
          Create repertoire
        </Fab>
      </div>

      <CreateRepertoireModal
        isOpen={isCreateModalOpen}
        onCloseModal={onToggleCreateModal}
      />
    </Layout>
  );
};
