import { Association } from 'entities/Association.entity';
import { AssociationDTO } from 'entities/DTO/AssociationDTO.entity';
import { LearnerDTO } from 'entities/DTO/LearnerDTO.entity';
import { Learner } from 'entities/Learner.entity';
import { EntityStatus } from 'enums/EntityStatus.enum';
import * as queryString from 'query-string';

import { ApiBase } from '../ApiBase';

export type LearnersQuery = {
  sort: string[];
  limit?: number;
  offset?: number;
  search?: string;
  statusEq?: EntityStatus;
  statusNe?: EntityStatus;
  facilitatorIdsEq?: string;
  organizationIdEq?: string;
};

export type LearnerResponse = Omit<
  typeof Learner,
  'fullName' | 'formattedCreatedAt' | 'formattedLastSessionDate'
>;

class LearnerApi extends ApiBase {
  fetchLearners(learnersQuery: LearnersQuery) {
    const query = queryString.stringify(learnersQuery, {
      arrayFormat: 'comma'
    });

    return this.client.get<LearnerResponse[]>(
      `${process.env.REACT_APP_BASE_URL}/learners?${query}`,
      { baseURL: '' }
    );
  }

  createLearner(learnerCreateDTO: LearnerDTO) {
    return this.client.post<LearnerResponse>(
      '',
      LearnerDTO.serialize(learnerCreateDTO)
    );
  }

  changePassword(learnerId: Learner['id'], newPassword: string) {
    return this.client.post<null>(`/${learnerId}/change-password`, {
      newPassword
    });
  }

  createAssociation(
    learnerId: Learner['id'],
    associationCreateDTO: AssociationDTO
  ) {
    return this.client.put<Association>(
      `/${learnerId}/associations`,
      AssociationDTO.serialize(associationCreateDTO)
    );
  }
}

const instance = new LearnerApi('/learners');

export { instance as LearnerApi };
