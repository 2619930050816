export enum EntityStatus {
  All = 'all',
  Active = 'active',
  Archived = 'archived',
  Inactive = 'inactive',
  Transferred = 'transferred'
}

export const EntityStatusLabels = Object.freeze<Record<EntityStatus, string>>({
  [EntityStatus.All]: 'All',
  [EntityStatus.Active]: 'Active',
  [EntityStatus.Archived]: 'Archived',
  [EntityStatus.Inactive]: 'Inactive',
  [EntityStatus.Transferred]: 'Transferred'
});

export const entityStatusOptions = Object.values(EntityStatus).map((value) => ({
  value,
  label: EntityStatusLabels[value]
}));
