import React, { useContext } from 'react';

export type UserData = {
  email: string;
  password: string;
  rememberMe: boolean;
};

interface AuthContextData {
  isAuthenticated: boolean;
  isLoading: boolean;
  error?: string;
  logOut: () => void;
  logIn: (data: UserData) => void;
}

export const authContext = React.createContext<AuthContextData>({
  isAuthenticated: false,
  isLoading: false,
  error: '',
  logIn: () => {},
  logOut: () => {}
});

export const AuthContextProvider = authContext.Provider;

export const useAuthContext = (): AuthContextData => useContext(authContext);
