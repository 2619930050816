export enum OrganizationType {
  Company = 'company',
  Government = 'government',
  IndependentLivingServices = 'independent_living_services',
  NonProfit = 'non_profit',
  PublicSchool = 'public_school',
  PrivateSchool = 'private_school',
  Other = 'other'
}

export const OrganizationTypeLabels = Object.freeze<Record<string, string>>({
  [OrganizationType.Company]: 'Company',
  [OrganizationType.Government]: 'Government',
  [OrganizationType.IndependentLivingServices]: 'Independent Living Services',
  [OrganizationType.NonProfit]: 'Non Profit',
  [OrganizationType.PublicSchool]: 'Public School',
  [OrganizationType.PrivateSchool]: 'Private School',
  [OrganizationType.Other]: 'Other'
});
