import React, { useContext } from 'react';

interface ActivityContextData {
  stopActivity: (userLoggedOut: boolean) => void;
  startActivity: () => void;
  updateActivity: () => void;
  refreshActivity: () => void;
  overrideActivity: () => void;
}

export const activityContext = React.createContext<ActivityContextData>({
  stopActivity: () => {},
  startActivity: () => {},
  updateActivity: () => {},
  refreshActivity: () => {},
  overrideActivity: () => {}
});

export const ActivityContextProvider = activityContext.Provider;

export const useActivityContext = (): ActivityContextData =>
  useContext(activityContext);
