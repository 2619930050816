import { date, deserialize, serializable } from 'serializr';

export class Repertoire {
  @serializable
  id = '';

  @serializable(date())
  createdAt: Date = new Date();

  @serializable(date())
  updatedAt: Date = new Date();

  @serializable
  title = '';

  static deserialize(json: Object | string): Repertoire {
    return deserialize(Repertoire, json);
  }

  static deserializeAsList(list: Repertoire[]): Repertoire[] {
    return list.map(Repertoire.deserialize);
  }

  static deserializeAsMap(
    list: Repertoire[]
  ): Map<Repertoire['id'], Repertoire> {
    return list.reduce((acc, repertoire) => {
      acc.set(repertoire.id, Repertoire.deserialize(repertoire));

      return acc;
    }, new Map());
  }
}
