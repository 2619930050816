import { ApiError } from 'entities/ApiError.entity';
import { Asset } from 'entities/Asset.entity';
import { AssetsApi } from 'services/API/Assets/AssetsApi';

export const uploadAsset = async (assetData: FormData): Promise<Asset> => {
  try {
    const response = await AssetsApi.uploadAsset(assetData);

    return Asset.deserialize(response.data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};
