import { Dispatch, SetStateAction } from 'react';
import { DropResult } from 'react-beautiful-dnd';
import { moveTask, updateTaskDetails } from 'actions/Task/taskActions';
import { Task } from 'entities/Task.entity';
import { TaskColumn } from 'hooks/Task/useTasksContext';

import { reorderData } from './listsHelpers';

const ROW_PREFIX_LENGTH = 4;

type LearnerTask = Task & { tasks: Task[] };

interface ChangeOrderProps {
  draggableId: string;
  records: LearnerTask[];
  setRecords: Dispatch<SetStateAction<LearnerTask[]>>;
  updateLearnerTaskData: (newRecords: LearnerTask[]) => void;
}

type ChangeOrderInList = (
  props: ChangeOrderProps & { sourceIndex: number; destinationIndex: number }
) => Promise<Task | void>;

type ChangeOrderInFolder = (
  props: ChangeOrderProps &
    Pick<DropResult, 'source'> & { destinationIndex: number }
) => Promise<Task | void>;

type DragItemToFolder = (
  props: ChangeOrderProps & Pick<DropResult, 'combine'>
) => Promise<Task | void>;

type DragItemFromFolder = (
  props: ChangeOrderProps & Pick<DropResult, 'source'>
) => Promise<Task | void> | undefined;

export const changeOrderInList: ChangeOrderInList = ({
  draggableId,
  sourceIndex,
  destinationIndex,
  records,
  setRecords,
  updateLearnerTaskData
}) => {
  const newRecords = reorderData<LearnerTask>(
    records,
    sourceIndex,
    destinationIndex
  );

  setRecords(newRecords);
  return moveTask(draggableId, destinationIndex, null).then(() => {
    updateLearnerTaskData(newRecords);
  });
};

export const changeOrderInFolder: ChangeOrderInFolder = ({
  source,
  destinationIndex,
  draggableId,
  records,
  setRecords,
  updateLearnerTaskData
}) => {
  const folderId = source.droppableId.startsWith('row')
    ? source.droppableId.substr(ROW_PREFIX_LENGTH)
    : source.droppableId;
  const folder = records.find((item: LearnerTask) => item.id === folderId);

  const newFolderTasks = reorderData<Task>(
    folder?.tasks || [],
    source.index,
    destinationIndex
  );

  const newRecords = records.map((item: LearnerTask) =>
    item.id === folderId
      ? {
          ...item,
          imageUrl: item.imageUrl,
          isFolder: item.isFolder,
          tasks: newFolderTasks
        }
      : item
  );

  setRecords(newRecords);
  return moveTask(draggableId, destinationIndex, folderId).then(() => {
    updateLearnerTaskData(newRecords);
  });
};

export const dragItemToFolder: DragItemToFolder = ({
  combine,
  draggableId,
  records,
  setRecords,
  updateLearnerTaskData
}) => {
  const folderId = combine?.draggableId.substr(ROW_PREFIX_LENGTH);

  const movedItem = records.find((item: Task) => item.id === draggableId);
  const newRecords = records.reduce((acc: LearnerTask[], item: LearnerTask) => {
    if (item.id === draggableId) {
      return acc;
    }

    if (item && item.id === folderId) {
      acc.push({
        ...item,
        imageUrl: item.imageUrl,
        isFolder: item.isFolder,
        tasksAmount: (item.tasksAmount || 0) + 1,
        tasks: movedItem ? [...item.tasks, movedItem] : item.tasks
      });
      return acc;
    }

    acc.push(item);
    return acc;
  }, []);

  setRecords(newRecords);
  return updateTaskDetails(draggableId, {
    parentId: folderId || null
  }).then(() => {
    updateLearnerTaskData(newRecords);
  });
};

export const dragItemFromFolder: DragItemFromFolder = ({
  source,
  draggableId,
  records,
  setRecords,
  updateLearnerTaskData
}) => {
  const folderId = source.droppableId.substr(ROW_PREFIX_LENGTH);
  const folder = records.find((item: Task) => item.id === folderId);
  const folderIndex = records.findIndex((item: Task) => item.id === folderId);
  const movedItem = folder?.tasks.find((item: Task) => item.id === draggableId);

  if (!folder || !movedItem) {
    return;
  }

  const newRecords = records.reduce((acc: LearnerTask[], item: LearnerTask) => {
    if (item.id !== folder?.id) {
      acc.push(item);

      return acc;
    }

    const newFolder = {
      ...folder,
      tasksAmount: folder.tasksAmount ? folder.tasksAmount - 1 : 0,
      imageUrl: folder.imageUrl,
      isFolder: folder.isFolder,
      tasks: folder.tasks.filter((task: Task) => task.id !== draggableId)
    };

    acc.push(newFolder, {
      ...movedItem,
      imageUrl: movedItem.imageUrl,
      isFolder: movedItem.isFolder,
      tasks: []
    });

    return acc;
  }, []);

  setRecords(newRecords);
  return updateTaskDetails(draggableId, {
    parentId: null
  }).then(() => {
    moveTask(draggableId, folderIndex + 1, null);
    updateLearnerTaskData(newRecords);
  });
};

interface UpdateDraggedOrganization {
  records: TaskColumn[];
  draggableId: string;
  folderId?: string;
}

export const updateDraggedOrganizationItemToFolder = ({
  records,
  draggableId,
  folderId
}: UpdateDraggedOrganization): TaskColumn[] => {
  return records.reduce((acc: TaskColumn[], item: TaskColumn) => {
    if (item.id === draggableId) {
      return acc;
    }

    if (item.id === folderId) {
      acc.push({
        ...item,
        imageUrl: item.imageUrl,
        isFolder: item.isFolder,
        tasksAmount: (item.tasksAmount || 0) + 1
      });
      return acc;
    }

    acc.push(item);
    return acc;
  }, []);
};
