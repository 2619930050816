import { TaskStatus } from 'enums/TaskStatus.enum';
import { TaskType } from 'enums/TaskType.enum';
import { serializable, serialize } from 'serializr';

export class TaskDTO {
  @serializable
  type: TaskType = TaskType.Task;

  @serializable
  sharable = true;

  @serializable
  title = '';

  @serializable
  repertoireId = '';

  @serializable
  createdById = '';

  @serializable
  description = '';

  @serializable
  publishStatus: TaskStatus = TaskStatus.Draft;

  @serializable
  parentId?: string | null;

  @serializable
  assignedToId?: string | null;

  @serializable
  organizationId?: string | null;

  @serializable
  image?: string | null;

  static serialize(json: Object | string): TaskDTO {
    return serialize(TaskDTO, json);
  }
}
