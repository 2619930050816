export enum EmptyStateMessages {
  Default = 'Looks like this page has no information yet.',
  Associations = 'Looks like you don’t have associations yet.',
  Assessments = 'Looks like you don’t have assessments yet.',
  Content = 'Looks like you don’t have any content yet.',
  Activities = 'Looks like this page has no task activities yet.',
  Tasks = 'Looks like you don’t have any tasks yet.',
  Steps = 'No steps yet.',
  Search = 'No results are found. Please, refine your search.',
  TaskPage = `Looks like this page has no tasks yet. Create a new one by pressing 'Create new task' at the bottom right.`,
  AccountPage = `Looks like this page has no accounts yet. Create a new one by pressing ‘Add new account’ at the bottom right.`,
  OrganizationPage = `Looks like this page has no organizations yet. Create a new one by pressing 'Create new organization' at the bottom right.`
}
