import { Repertoire } from 'entities/Repertoire.entity';
import * as queryString from 'query-string';

import { ApiBase } from '../ApiBase';

export type RepertoiresQuery = {
  sort: string[];
  idIn?: Repertoire['id'][];
  limit?: number;
};

class RepertoireApi extends ApiBase {
  fetchRepertoires(repertoiresQuery: RepertoiresQuery) {
    const query = queryString.stringify(repertoiresQuery, {
      arrayFormat: 'comma'
    });

    return this.client.get<Repertoire[]>(`/repertoires?${query}`);
  }

  createRepertoire(repertoireTitle: string) {
    return this.client.post<Repertoire>('/repertoires', {
      title: repertoireTitle
    });
  }

  updateRepertoireDetails(
    repertoireId: Repertoire['id'],
    repertoireTitle: string
  ) {
    return this.client.patch<Repertoire>(`/repertoires/${repertoireId}`, {
      title: repertoireTitle
    });
  }
}

const instance = new RepertoireApi('');

export { instance as RepertoireApi };
