import React, { FC } from 'react';

interface Props {
  condition: boolean;
}

export const If: FC<Props> = ({ condition, children }) => {
  if (condition) {
    return <>{children}</>;
  }

  return null;
};
