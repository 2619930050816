import React, { FC } from 'react';
import { LoadingButton } from '@mui/lab';
import { CardMedia, Chip, Grid } from '@mui/material';
import imagePlaceholder from 'assets/big-image-placeholder.png';
import { If } from 'components/If';
import { LoadingOverlay } from 'components/LoadingOverlay';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { TaskBackHeader } from 'components/TaskBackHeader';
import { Task } from 'entities/Task.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { useRepertoires } from 'hooks/Task/useRepertoires';
import { useSharedLibraryContext } from 'hooks/Task/useSharedLibraryContext';
import { getEntityName } from 'utils/helpers/commonHelpers';
import { TaskPreview } from 'views/Task/TaskPreview';

import styles from './SharedTaskDetails.module.scss';

interface Props {
  task: Task;
  onAddToMyTasks?: () => void;
  onAddToSharedLibrary?: () => void;
}

export const SharedTaskDetails: FC<Props> = ({
  task,
  onAddToMyTasks,
  onAddToSharedLibrary
}) => {
  const {
    id,
    stepsAmount,
    imageUrl,
    title,
    description,
    isFolder,
    repertoireId
  } = task;
  const { onChangeActiveTask } = useSharedLibraryContext();

  const showActions = !!(onAddToMyTasks || onAddToSharedLibrary);

  const {
    data: repertoiresData,
    isError: isRepertoireError,
    isLoading: isRepertoireLoading
  } = useRepertoires();

  const repertoireName = getEntityName(repertoireId, repertoiresData);

  return (
    <>
      <StatusSnackBar
        isError={isRepertoireError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      <div className={styles['task-details']}>
        <LoadingOverlay loading={isRepertoireLoading}>
          <TaskBackHeader
            title={title}
            isFolder={isFolder}
            handleBack={() => onChangeActiveTask(null)}
          />
          <Grid container spacing={3} className={styles.content}>
            <Grid item sm={12} md={6} lg={5}>
              <CardMedia
                component="img"
                image={imageUrl || imagePlaceholder}
                alt={`${title} image`}
                className={styles['task-image']}
              />
            </Grid>

            <Grid item sm={12} md={6} lg={7}>
              <div className={styles['content-text']}>
                <Chip
                  className={styles.chip}
                  size="small"
                  label={repertoireName}
                />

                <If condition={!!description}>
                  <h5 className={styles['description-title']}>Description</h5>
                  <span className={styles.description}>{description}</span>
                </If>

                <If condition={showActions && !isFolder}>
                  <div className={styles.actions}>
                    <If condition={showActions}>
                      <LoadingButton
                        size="small"
                        color="secondary"
                        variant="contained"
                        disabled={!onAddToSharedLibrary}
                        onClick={onAddToSharedLibrary}
                      >
                        Add to Shared Library
                      </LoadingButton>
                      <LoadingButton
                        size="small"
                        color="secondary"
                        variant="contained"
                        disabled={!onAddToMyTasks}
                        onClick={onAddToMyTasks}
                      >
                        Add to My tasks
                      </LoadingButton>
                    </If>

                    <If condition={!isFolder}>
                      <TaskPreview
                        taskId={id}
                        taskName={title}
                        stepsAmount={stepsAmount}
                        buttonClassName={styles['preview-button']}
                      />
                    </If>
                  </div>
                </If>
              </div>
            </Grid>
          </Grid>
        </LoadingOverlay>
      </div>
    </>
  );
};
