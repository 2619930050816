import { AuthEvents } from 'enums/AuthEvents.enum';

export interface Observer {
  event: AuthEvents;
  update(): void;
}

interface Observable {
  subscribe(observer: Observer): void;
  unsubscribe(observer: Observer): void;
  publish(event: AuthEvents): void;
}

class AuthObservable implements Observable {
  private observers: Set<Observer> = new Set();

  subscribe(observer: Observer) {
    this.observers.add(observer);
  }

  unsubscribe(observer: Observer) {
    this.observers.delete(observer);
  }

  publish(event: AuthEvents) {
    this.observers.forEach((observer) => {
      if (event === observer.event) {
        observer.update();
      }
    });
  }
}

export const AuthPublisher = new AuthObservable();
