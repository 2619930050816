import React, { FC } from 'react';
import { useMutation } from 'react-query';
import { createTask } from 'actions/Task/taskActions';
import { Modal } from 'components/Modal';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Task } from 'entities/Task.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { TaskSort } from 'enums/TaskSort.enum';
import { TaskStatus } from 'enums/TaskStatus.enum';
import { TaskType } from 'enums/TaskType.enum';
import { useLoadAuthUserData } from 'hooks/Auth/useLoadAuthUserData';
import { queryClient } from 'index';
import { MAX_PAGE_LIMIT } from 'utils/constants';
import { FormData, TaskForm } from 'views/Task/TaskForm';

interface Options {
  createdById?: string;
  assignedToEq?: string;
  organizationIdIsNull?: boolean;
  organizationIdEq?: string | null;
  assignedToIdIsNull?: boolean;
  publishStatus?: TaskStatus;
}

interface Props {
  isOpen: boolean;
  onCloseModal: () => void;
  options?: Options;
  activeFolderId?: string;
}

export const CreateTaskModal: FC<Props> = ({
  isOpen,
  onCloseModal,
  options,
  activeFolderId
}) => {
  const { data: userData } = useLoadAuthUserData();

  const { isLoading, isError, isSuccess, error, mutateAsync } = useMutation<
    Task,
    ApiError,
    FormData
  >(
    (formData: FormData) =>
      createTask({
        title: formData.title,
        repertoireId: formData.repertoireId,
        parentId: formData.parentId === 'none' ? null : formData.parentId,
        description: formData.description || '',
        image: formData.image,
        type: TaskType.Task,
        sharable: false,
        publishStatus: options?.publishStatus || TaskStatus.Published,
        createdById: options?.createdById || userData!.id,
        organizationId: options?.organizationIdEq,
        assignedToId: options?.assignedToEq
      }),
    {
      onSuccess: async () => {
        await queryClient.invalidateQueries(queryKeys.tasksList);

        onCloseModal();
      }
    }
  );

  return (
    <>
      <StatusSnackBar
        isError={isError}
        isSuccess={isSuccess}
        successMessage="Task successfully created"
        errorMessage={error?.errorMessage || ErrorMessages.FailedPostRequest}
      />
      <Modal title="Create task" isOpen={isOpen} onCloseModal={onCloseModal}>
        <TaskForm
          onSubmit={mutateAsync}
          onCloseModal={onCloseModal}
          isLoading={isLoading}
          saveButtonText="Create task"
          taskQuery={{
            sort: [TaskSort.ByName],
            assignedToIdEq: options?.assignedToEq,
            createdByIdEq: options?.createdById,
            organizationIdEq: options?.organizationIdEq,
            organizationIdIsNull: options?.organizationIdIsNull,
            assignedToIdIsNull: options?.assignedToIdIsNull,
            limit: MAX_PAGE_LIMIT
          }}
          defaultValues={{
            parentId: activeFolderId
          }}
        />
      </Modal>
    </>
  );
};
