import React, { FC } from 'react';
import { UserScopes } from 'enums/UserScopes.enum';
import { useHasPermission } from 'services/Permissions';

interface Props {
  scopes: UserScopes[];
}

export const PermissionsGate: FC<Props> = ({ children, scopes }) => {
  const hasPermission = useHasPermission(scopes);

  return hasPermission ? <>{children}</> : null;
};
