import {
  format,
  intervalToDuration,
  minutesToMilliseconds,
  secondsToMilliseconds
} from 'date-fns';
import { ActivityGroup } from 'entities/ActivityGroup.entity';

import { checkForPlural } from './stringHelpers';

export const getMillisecondsFromDate = (time: number | Date): number => {
  const { minutes, seconds } = intervalToDuration({ start: 0, end: time });
  const millisecondsFromSeconds = seconds ? secondsToMilliseconds(seconds) : 0;
  const millisecondsFromMinutes = minutes ? minutesToMilliseconds(minutes) : 0;

  return millisecondsFromSeconds + millisecondsFromMinutes;
};

export const formatToMinutesAndSeconds = (
  time: number,
  formatString = "mm'm' ss's'"
): string => format(time, formatString);

export const formatDateNumberToString = (
  month: ActivityGroup['month'],
  year: ActivityGroup['year']
): string => {
  const date = new Date(year, month, 15);

  return format(date, 'MMMM, yyyy');
};

export const formatSecondsToDuration = (seconds: number): string =>
  new Date(seconds * 1000).toISOString().substr(11, 8);

export const formatSecondsToDays = (seconds: number): string => {
  const days = Math.floor(seconds / (3600 * 24));

  return days ? `${days} day${checkForPlural(days)} ` : '';
};
