import React, { FC, useMemo } from 'react';
import deleteIcon from 'assets/delete.svg';
import {
  ConfirmationScreen,
  ConfirmationScreenTheme
} from 'components/ConfirmationScreen';
import { Modal } from 'components/Modal';
import { Organization } from 'entities/Organization.entity';

interface Props {
  isOpen: boolean;
  organizations: Organization[];
  onDeactivateOrganizations: () => void;
  onCloseModal: () => void;
}

export const DeactivateOrganizationModal: FC<Props> = ({
  isOpen,
  organizations,
  onCloseModal,
  onDeactivateOrganizations
}) => {
  const deactivateOrganizationsTitle = useMemo(
    () =>
      organizations.length === 1
        ? `Are you sure you want to deactivate "${organizations[0].name}" organization?`
        : `Are you sure you want to deactivate ${organizations.length} organizations?`,
    [organizations]
  );

  return (
    <Modal isOpen={isOpen} onCloseModal={onCloseModal}>
      <ConfirmationScreen
        icon={deleteIcon}
        onSubmit={onDeactivateOrganizations}
        onCancel={onCloseModal}
        cancelButtonTitle="Cancel"
        submitButtonTitle="Deactivate"
        theme={ConfirmationScreenTheme.Error}
      >
        <p>{deactivateOrganizationsTitle}</p>
      </ConfirmationScreen>
    </Modal>
  );
};
