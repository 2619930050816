import React, { FC, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import deleteIcon from 'assets/delete.svg';
import {
  ConfirmationScreen,
  ConfirmationScreenTheme
} from 'components/ConfirmationScreen';
import { Modal } from 'components/Modal';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { Step } from 'entities/Step.entity';
import { Task } from 'entities/Task.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { useUpdateTaskStep } from 'hooks/Task/useUpdateTaskStep';

interface Props {
  isOpen: boolean;
  data: Pick<Step, 'id' | 'title'>;
  onUpdateStep: (step: Step) => void;
  onCloseModal: () => void;
}

export const DeactivateStepModal: FC<Props> = ({
  isOpen,
  data,
  onUpdateStep,
  onCloseModal
}) => {
  const { id: taskId } = useParams<{ id: Task['id'] }>();

  const { isLoading, isError, mutateAsync } = useUpdateTaskStep(
    (data: Step) => {
      onUpdateStep(data);
      onCloseModal();
    }
  );

  const onDeactivateStep = useCallback(
    async (id: string) => {
      await mutateAsync({
        taskId,
        id,
        step: {
          active: false
        }
      });
    },
    [taskId, mutateAsync]
  );

  const { title, id } = data;

  return (
    <>
      <StatusSnackBar
        isError={isError}
        errorMessage={ErrorMessages.FailedPostRequest}
      />
      <Modal isOpen={isOpen} onCloseModal={onCloseModal}>
        <ConfirmationScreen
          icon={deleteIcon}
          isLoading={isLoading}
          onCancel={onCloseModal}
          cancelButtonTitle="Cancel"
          submitButtonTitle="Deactivate"
          theme={ConfirmationScreenTheme.Error}
          onSubmit={() => onDeactivateStep(id)}
        >
          <p>
            Are you sure you want to deactivate step <b>{title}</b>?
          </p>
        </ConfirmationScreen>
      </Modal>
    </>
  );
};
