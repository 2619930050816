import { Repertoire } from 'entities/Repertoire.entity';
import { Task } from 'entities/Task.entity';

export const getEntityName = (
  id: string,
  entitiesList: (Task | Repertoire)[] = []
): string => {
  if (!entitiesList?.length) {
    return '';
  }

  const entitiesMap = entitiesList.reduce((acc, entity) => {
    acc.set(entity.id, entity);

    return acc;
  }, new Map());

  return entitiesMap!.get(id)?.title || '';
};
