import { date, deserialize, serializable } from 'serializr';

export class LearnerReport {
  @serializable
  id = '';

  @serializable(date())
  createdAt: Date = new Date();

  @serializable(date())
  updatedAt: Date = new Date();

  @serializable
  name = '';

  @serializable(date())
  lastActiveTime: Date | null = null;

  @serializable
  timeSpentOnAppSec = 0;

  @serializable
  numberOfSessions = 0;

  @serializable
  numberOfTasks = 0;

  @serializable
  numberOfTaskCompletions = 0;

  @serializable
  avgImprovementScorePercent = 0;

  @serializable
  numberOfMasteredTasks = 0;

  @serializable
  numberOfAssessments = 0;

  static deserialize(json: Object | string): LearnerReport {
    return deserialize(LearnerReport, json);
  }

  static deserializeAsList(list: LearnerReport[]): LearnerReport[] {
    return list.map(LearnerReport.deserialize);
  }
}
