import { ApiError } from 'entities/ApiError.entity';
import { AdminDTO } from 'entities/DTO/AdminDTO.entity';
import { OrganizationDTO } from 'entities/DTO/OrganizationDTO.entity';
import { UserDTO } from 'entities/DTO/UserDTO.entity';
import { Organization } from 'entities/Organization.entity';
import { User } from 'entities/User.entity';
import {
  OrganizationActivateResponse,
  OrganizationApi,
  OrganizationsQuery
} from 'services/API/Organization/OrganizationApi';

export const createOrganization = async (
  organizationCreateDTO: OrganizationDTO
): Promise<Organization> => {
  try {
    const response = await OrganizationApi.createOrganization(
      organizationCreateDTO
    );

    return Organization.deserialize(response.data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchOrganizations = async (
  query: OrganizationsQuery
): Promise<Organization[]> => {
  try {
    const { data } = await OrganizationApi.fetchOrganizations(query);

    return Organization.deserializeAsList(data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchUserOrganization = async (
  userId: User['id']
): Promise<Organization> => {
  try {
    const response = await OrganizationApi.fetchUserOrganization(userId);

    return Organization.deserialize(response.data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchOrganizationDetails = async (
  organizationId: Organization['id']
): Promise<Organization> => {
  try {
    const response = await OrganizationApi.fetchOrganizationDetails(
      organizationId
    );

    return Organization.deserialize(response.data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const fetchOrganizationBySlug = async (
  slugId: Organization['slugId']
): Promise<Organization> => {
  try {
    const response = await OrganizationApi.fetchOrganizationBySlug(slugId);

    return Organization.deserialize(response.data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const updateOrganizationDetails = async (
  organizationId: Organization['id'],
  organizationUpdateDTO: Partial<OrganizationDTO>
): Promise<Organization> => {
  try {
    const response = await OrganizationApi.updateOrganizationDetails(
      organizationId,
      organizationUpdateDTO
    );

    return Organization.deserialize(response.data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const changeOrganizationAdmin = async (
  organizationId: Organization['id'],
  existingUserId: User['id'],
  newAdmin: AdminDTO
): Promise<Organization> => {
  try {
    const response = await OrganizationApi.changeOrganizationAdmin(
      organizationId,
      existingUserId,
      newAdmin
    );

    return Organization.deserialize(response.data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const inviteUser = async (
  organizationId: Organization['id'],
  userCreateDTO: UserDTO
): Promise<User> => {
  try {
    const response = await OrganizationApi.inviteUser(
      organizationId,
      userCreateDTO
    );

    return User.deserialize(response.data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const activateOrganizations = async (
  organizationIds: Array<Organization['id']>
): Promise<OrganizationActivateResponse> => {
  try {
    const response = await OrganizationApi.activateOrganizations(
      organizationIds
    );

    return response.data;
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const deactivateOrganizations = async (
  organizationIds: Array<Organization['id']>
): Promise<OrganizationActivateResponse> => {
  try {
    const response = await OrganizationApi.deactivateOrganizations(
      organizationIds
    );

    return response.data;
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const requestMoreLicenses = async (
  organizationId: Organization['id']
): Promise<void> => {
  try {
    await OrganizationApi.requestMoreLicenses(organizationId);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};
