import { MutableRefObject, useEffect, useRef, useState } from 'react';

const MIN_CARD_WIDTH = 260;
const CARD_PADDING = 26;
const CONTAINER_RIGHT_PADDING = 36;

interface UseTaskWidth {
  taskBoxRef: MutableRefObject<HTMLDivElement | null>;
  taskWidth: number;
}

export const useTaskCardWidth = (): UseTaskWidth => {
  const taskBoxRef = useRef<null | HTMLDivElement>(null);
  const [taskWidth, setTaskWidth] = useState(0);

  useEffect(() => {
    const width = taskBoxRef.current?.clientWidth || 0;

    if (width === 0) {
      return;
    }

    const columnCount = Math.floor(width / (MIN_CARD_WIDTH + CARD_PADDING));
    setTaskWidth((width - CONTAINER_RIGHT_PADDING) / columnCount);
  }, [taskBoxRef]);

  return { taskBoxRef, taskWidth };
};
