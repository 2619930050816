import { ApiError } from 'entities/ApiError.entity';
import { Association } from 'entities/Association.entity';
import { AssociationDTO } from 'entities/DTO/AssociationDTO.entity';
import { LearnerDTO } from 'entities/DTO/LearnerDTO.entity';
import { Learner } from 'entities/Learner.entity';
import { LearnerApi, LearnersQuery } from 'services/API/Learner/LearnerApi';

export const fetchLearners = async (
  query: LearnersQuery
): Promise<Learner[]> => {
  try {
    const { data } = await LearnerApi.fetchLearners(query);

    return Learner.deserializeAsList(data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const createLearner = async (
  learnerCreateDTO: LearnerDTO
): Promise<Learner> => {
  try {
    const response = await LearnerApi.createLearner(learnerCreateDTO);

    return Learner.deserialize(response.data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const changeLearnerPassword = async (
  learnerId: Learner['id'],
  newPassword: string
): Promise<void> => {
  try {
    await LearnerApi.changePassword(learnerId, newPassword);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};

export const createAssociation = async (
  learnerId: Learner['id'],
  associationCreateDTO: AssociationDTO
): Promise<Association> => {
  try {
    const response = await LearnerApi.createAssociation(
      learnerId,
      associationCreateDTO
    );

    return Association.deserialize(response.data);
  } catch (e) {
    throw ApiError.deserializeFromCatch(e);
  }
};
