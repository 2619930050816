import React, { FC, useState } from 'react';
import { useMutation } from 'react-query';
import { generatePath, useHistory } from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import {
  Avatar,
  Button,
  FormControlLabel,
  IconButton,
  Switch
} from '@mui/material';
import { updateEmailNotifications } from 'actions/User/userActions';
import { If } from 'components/If';
import { InfoRow } from 'components/InfoRow';
import { Layout } from 'components/Layout';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { User } from 'entities/User.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { Routes } from 'enums/Routes.enum';
import { UserRoles } from 'enums/UserRoles.enum';
import { useLoadAuthUserData } from 'hooks/Auth/useLoadAuthUserData';
import { queryClient } from 'index';
import { AccountEditModal } from 'views/Account/AccountEditModal';
import { ChangePasswordModal } from 'views/Account/ChangePasswordModal';

import styles from './AccountPage.module.scss';

export const AccountPage: FC = () => {
  const history = useHistory();
  const { data, isLoading } = useLoadAuthUserData();
  const [isEditAccountModalOpen, setIsEditAccountModalOpen] = useState(false);
  const [isChangePasswordModalOpen, setIsChangePasswordModalOpen] =
    useState(false);
  const [isEmailNotificationsChanging, setIsEmailNotificationsChanging] =
    useState(false);

  const goToOrganization = () => {
    if (data?.organization) {
      history.push(
        generatePath(Routes.OrganizationInformation, {
          slugId: data.organization.slugId,
          slug: data.organization.id
        })
      );
    }
  };

  const {
    isLoading: isUpdateEmailNotificationLoading,
    isError: isUpdateEmailNotificationError,
    isSuccess: isUpdateEmailNotificationSuccess,
    error: updateEmailNotificationError,
    mutateAsync: onUpdateEmailNotifications
  } = useMutation<User, ApiError>(
    () =>
      updateEmailNotifications(
        data?.id || '',
        !data?.receiveEmailNotifications
      ),
    {
      onSuccess: async () => {
        setIsEmailNotificationsChanging(true);
        await queryClient.refetchQueries(queryKeys.authenticatedUserData);
        setIsEmailNotificationsChanging(false);
      }
    }
  );

  return (
    <Layout loading={isLoading}>
      <If condition={!!data?.id}>
        <StatusSnackBar
          isError={isUpdateEmailNotificationError}
          isSuccess={isUpdateEmailNotificationSuccess}
          errorMessage={
            updateEmailNotificationError?.errorMessage ||
            ErrorMessages.FailedPostRequest
          }
          successMessage="User Email notification setting successfully updated"
        />
        <div className={styles['account-page']}>
          <AccountEditModal
            isOpen={isEditAccountModalOpen}
            onCloseModal={() => setIsEditAccountModalOpen(false)}
          />
          <ChangePasswordModal
            isOpen={isChangePasswordModalOpen}
            onCloseModal={() => setIsChangePasswordModalOpen(false)}
          />
          <div className={styles.header}>
            <div className={styles['header-info']}>
              <If condition={data?.role !== UserRoles.SuperAdmin}>
                <IconButton
                  color="secondary"
                  data-testid="back-button"
                  onClick={goToOrganization}
                  className={styles['back-button']}
                  aria-label="Back to organization"
                >
                  <ArrowBackIcon />
                </IconButton>
              </If>
              <h1 className={styles.title}>Personal information</h1>
              <Button
                size="small"
                aria-label="Edit Personal Information"
                onClick={() => setIsEditAccountModalOpen(true)}
                startIcon={<ModeEditIcon />}
                color="secondary"
                variant="contained"
              >
                Edit
              </Button>
            </div>
            <span className={styles.subtitle}>
              Account created:
              <span
                className={styles.date}
              >{` on ${data?.formattedCreatedAt}`}</span>
            </span>
          </div>
          <InfoRow label="Profile picture">
            <Avatar src={data?.avatarUrl} alt={data?.fullName} />
          </InfoRow>
          <InfoRow label="Full name">
            <span className={styles['info-value']}>{data?.fullName}</span>
          </InfoRow>
          <InfoRow label="Email Address">
            <span className={styles.email}>{data?.email}</span>
            <If condition={data?.role !== UserRoles.SuperAdmin}>
              <FormControlLabel
                className={styles.switch}
                control={
                  <Switch
                    color="primary"
                    checked={data?.receiveEmailNotifications}
                    disabled={
                      isUpdateEmailNotificationLoading ||
                      isEmailNotificationsChanging
                    }
                    onChange={() => onUpdateEmailNotifications()}
                  />
                }
                labelPlacement="start"
                label="Email notifications"
              />
            </If>
          </InfoRow>
          <InfoRow label="Phone number">
            <span className={styles['info-value']}>{data?.phoneNumber}</span>
          </InfoRow>
          <InfoRow label="Username">
            <span className={styles['info-value']}>{data?.username}</span>
          </InfoRow>
          <InfoRow label="Password" className={styles['password-row']}>
            <Button
              size="small"
              color="secondary"
              variant="contained"
              onClick={() => setIsChangePasswordModalOpen(true)}
            >
              Change password
            </Button>
          </InfoRow>
        </div>
      </If>
    </Layout>
  );
};
