import { TaskSchedule } from 'entities/TaskScheduler.entity';
import { ScheduleRepeatType } from 'enums/ScheduleRepeatType.enum';
import { date, deserialize, object, serializable } from 'serializr';

export class Schedule {
  @serializable
  id = '';

  @serializable(date())
  stoppedAt: Date = new Date();

  @serializable(object(TaskSchedule))
  task: TaskSchedule = new TaskSchedule();

  @serializable(date())
  scheduledAt: Date = new Date();

  @serializable
  repeat: ScheduleRepeatType = ScheduleRepeatType.Never;

  @serializable
  notify = false;

  @serializable
  createdById = '';

  @serializable
  originalScheduleId = '';

  static deserialize(json: Object | string): Schedule {
    return deserialize(Schedule, json);
  }

  static deserializeAsList(list: Schedule[]): Schedule[] {
    return list.map(Schedule.deserialize);
  }
}
