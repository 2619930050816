import React, { useState } from 'react';
import { TaskAssignStatus } from 'enums/TaskAssignStatus.enum';
import { TaskSort } from 'enums/TaskSort.enum';
import { TaskStatus } from 'enums/TaskStatus.enum';
import { TaskColumn, TasksContextProvider } from 'hooks/Task/useTasksContext';

export const TasksProvider: React.FC = ({ children }) => {
  const [activeFolder, setActiveFolder] = useState<TaskColumn | null>(null);
  const [search, setSearch] = useState<string>('');
  const [status, setStatus] = useState<TaskStatus>(TaskStatus.All);
  const [assignStatus, setAssignStatus] = useState<TaskAssignStatus>(
    TaskAssignStatus.All
  );
  const [sort, setSort] = useState<TaskSort>(TaskSort.ByName);

  const onChangeActiveFolder = (folder: TaskColumn | null) => {
    setActiveFolder(folder);
  };

  return (
    <TasksContextProvider
      value={{
        search,
        status,
        assignStatus,
        sort,
        activeFolder,
        onChangeSearch: setSearch,
        onChangeStatus: setStatus,
        onChangeAssignStatus: setAssignStatus,
        onChangeSort: setSort,
        onChangeActiveFolder
      }}
    >
      {children}
    </TasksContextProvider>
  );
};
