import React from 'react';
import { generatePath } from 'react-router-dom';
import { Column, Row } from 'react-table';
import { Checkbox, Chip, Grid } from '@mui/material';
import cn from 'classnames';
import { IconCell } from 'components/IconCell';
import { Task } from 'entities/Task.entity';
import { Routes } from 'enums/Routes.enum';
import { TaskStatus, TaskStatusLabels } from 'enums/TaskStatus.enum';
import { TaskColumn } from 'hooks/Task/useTasksContext';
import { ActionMenu } from 'views/Library/ActionMenu';
import { FolderCell } from 'views/Library/FolderCell';

import styles from './LibraryPage.module.scss';

export const columns: Column<TaskColumn>[] = [
  {
    id: 'name',
    Header: 'Name',
    accessor: (task): TaskColumn => task,
    Cell: ({ value, row }: { value: TaskColumn; row: Row<Task> }) =>
      value.isFolder ? (
        <FolderCell data={value} row={row} />
      ) : (
        <Grid container>
          <Checkbox
            checked={row.isSelected}
            onChange={row.getToggleRowSelectedProps().onChange}
            inputProps={{
              'aria-label': 'Select task/folder'
            }}
          />
          <IconCell
            title={value.title}
            imageURL={value.imageUrl}
            navTo={generatePath(Routes.TaskDetails, {
              id: value.id
            })}
          />
        </Grid>
      ),
    width: 3,
    minWidth: 300
  },
  {
    id: 'repertoireName',
    Header: 'Repertoire',
    accessor: 'repertoireName',
    Cell: ({ value }) => (
      <Chip size="small" label={value} className={styles['primary-chip']} />
    ),
    width: 1,
    minWidth: 120
  },
  {
    id: 'sequenceId',
    Header: 'ID',
    accessor: 'sequenceId',
    width: 1,
    minWidth: 100
  },
  {
    id: 'publishStatus',
    Header: 'Publish Status',
    accessor: 'publishStatus',
    Cell: ({ value }) => (
      <Chip
        size="small"
        label={TaskStatusLabels[value]}
        className={cn(
          styles.chip,
          value === TaskStatus.Published && styles['primary-chip']
        )}
      />
    ),
    width: 1,
    minWidth: 100
  },
  {
    id: 'id',
    Header: '',
    accessor: (task): Task => task,
    Cell: ({ value }: { value: Task }) => <ActionMenu task={value} />,
    width: 1,
    minWidth: 100
  }
];
