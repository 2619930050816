import React, { FC, useState } from 'react';
import { DateRange, DateRangePicker, LocalizationProvider } from '@mui/lab';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import { Box, Button, TextField } from '@mui/material';
import { endOfDay } from 'date-fns';

import styles from './DateRangeSelector.module.scss';

interface Props {
  defaultRange: { from: Date | null; to: Date | null };
  onChange: (dateRange: { from: Date | null; to: Date | null }) => void;
}

export const DateRangeSelector: FC<Props> = ({ defaultRange, onChange }) => {
  const [value, setValue] = useState<DateRange<Date>>([
    defaultRange.from,
    defaultRange.to
  ]);

  const onChangeRange = () => {
    const [from, to] = value;
    if (to) {
      onChange({ from, to: endOfDay(to) });
    } else {
      onChange({ from, to });
    }
  };

  const onClear = () => {
    setValue([null, null]);
    onChange({ from: null, to: null });
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangePicker
        renderInput={(startProps, endProps) => (
          <>
            <TextField {...startProps} className={styles.input} />
            <Box sx={{ mx: 2 }}> - </Box>
            <TextField {...endProps} className={styles.input} />
          </>
        )}
        onChange={setValue}
        onClose={() => {
          onChangeRange();
        }}
        value={value}
      />
      <Button
        disabled={!value[0] && !value[1]}
        sx={{ mx: 2 }}
        size="small"
        title="Clear Date Range"
        onClick={onClear}
      >
        CLEAR
      </Button>
    </LocalizationProvider>
  );
};
