import React, { FC, useCallback, useState } from 'react';
import { generatePath, useParams } from 'react-router-dom';
import FlagIcon from '@mui/icons-material/Flag';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import { Button } from '@mui/material';
import imagePlaceholder from 'assets/image-placeholder.png';
import { EmptyState } from 'components/EmptyState';
import { If } from 'components/If';
import { InfoRow } from 'components/InfoRow';
import { AbsoluteSpinner } from 'components/LoadingOverlay';
import { PermissionsGate } from 'components/PermissionsGate';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { Organization } from 'entities/Organization.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { OrganizationTypeLabels } from 'enums/OrganizationType.enum';
import { Routes } from 'enums/Routes.enum';
import { UserRoles } from 'enums/UserRoles.enum';
import { UserScopes } from 'enums/UserScopes.enum';
import { useLoadAuthUserData } from 'hooks/Auth/useLoadAuthUserData';
import { useOrganizationAdmin } from 'hooks/Organization/useOrganizationAdmin';
import { ChangeOrganizationAdminModal } from 'views/Organizations/ChangeOrganizationAdminModal';
import { DetailsInformationHeader } from 'views/Organizations/DetailsInformationHeader';
import { EditDetailsModal } from 'views/Organizations/EditDetailsModal';
import { EditLicensesModal } from 'views/Organizations/EditLicensesModal';

import styles from './DetailsInformation.module.scss';

interface Props {
  organization: Organization;
}

export const DetailsInformation: FC<Props> = ({ organization }) => {
  const {
    isError,
    data: adminData,
    isFetching
  } = useOrganizationAdmin({
    slugId: organization.slugId,
    adminId: organization.adminId
  });

  const { data } = useLoadAuthUserData();
  const { slugId, slug } = useParams<{ slugId: string; slug: string }>();

  const [isEditDetailsModalOpen, setIsEditDetailsModalOpen] =
    useState<boolean>(false);
  const [isEditLicensesModalOpen, setIsEditLicensesModalOpen] =
    useState<boolean>(false);
  const [isChangeAdminModalOpen, setIsChangeAdminModalOpen] =
    useState<boolean>(false);

  const onToggleOrgDetailsModal = useCallback(() => {
    setIsEditDetailsModalOpen(
      (prevIsEditDetailsModalOpen) => !prevIsEditDetailsModalOpen
    );
  }, []);

  const onToggleOrgLicensesModal = useCallback(() => {
    setIsEditLicensesModalOpen(
      (prevIsEditLicensesModalOpen) => !prevIsEditLicensesModalOpen
    );
  }, []);

  const onToggleChangeAdminModal = useCallback(() => {
    setIsChangeAdminModalOpen(
      (prevIsChangeAdminModalOpen) => !prevIsChangeAdminModalOpen
    );
  }, []);

  const { formattedCreatedAt, imageUrl, name, address, type, description } =
    organization;

  if (!adminData) {
    return (
      <>
        <AbsoluteSpinner loading={isFetching} />
        <StatusSnackBar
          isError={isError}
          errorMessage={ErrorMessages.FailedGetRequest}
        />
        {!isFetching && <EmptyState />}
      </>
    );
  }

  return (
    <div className={styles['details-information']}>
      <StatusSnackBar
        isError={isError}
        errorMessage={ErrorMessages.FailedGetRequest}
      />
      <EditDetailsModal
        data={organization}
        isOpen={isEditDetailsModalOpen}
        onCloseModal={onToggleOrgDetailsModal}
      />
      <EditLicensesModal
        data={organization}
        isOpen={isEditLicensesModalOpen}
        onCloseModal={onToggleOrgLicensesModal}
      />
      <ChangeOrganizationAdminModal
        data={organization}
        adminData={adminData}
        isOpen={isChangeAdminModalOpen}
        onCloseModal={onToggleChangeAdminModal}
      />
      <div className={styles['additional-info']}>
        <span className={styles['date-title']}>
          Account created:
          <span className={styles.date}>{` on ${formattedCreatedAt}`}</span>
        </span>
        <If condition={data?.role !== UserRoles.Facilitator}>
          <Button
            size="small"
            color="secondary"
            variant="contained"
            target="_blank"
            href={generatePath(Routes.OrganizationReport, {
              slug,
              slugId
            })}
            startIcon={<FlagIcon />}
          >
            Generate report
          </Button>
        </If>
      </div>
      <DetailsInformationHeader
        data={organization}
        onOpenLicensesModal={onToggleOrgLicensesModal}
      />
      <main>
        <div className={styles['title-container']}>
          <h1 className={styles.title}>General information</h1>
          <PermissionsGate scopes={[UserScopes.EditOrganizationInfo]}>
            <Button
              size="small"
              aria-label="Edit Organization General Information"
              color="secondary"
              variant="contained"
              startIcon={<ModeEditIcon />}
              onClick={onToggleOrgDetailsModal}
            >
              Edit
            </Button>
          </PermissionsGate>
        </div>
        <InfoRow label="Organization photo">
          <img
            alt="placeholder"
            data-testid="image"
            className={styles.image}
            src={imageUrl || imagePlaceholder}
          />
        </InfoRow>
        <InfoRow label="Organization name">
          <span className={styles['info-value']}>{name}</span>
        </InfoRow>
        <InfoRow label="Address">
          <span className={styles['info-value']}>{address}</span>
        </InfoRow>
        <InfoRow label="Type of organization">
          <span className={styles['info-value']}>
            {OrganizationTypeLabels[type]}
          </span>
        </InfoRow>
        <InfoRow label="Description">
          <span className={styles['info-value']}>{description}</span>
        </InfoRow>
        <InfoRow label="Organization admin" className={styles['admin-row']}>
          <span className={styles['admin-span']}>
            <span>
              <span className={styles['info-value']}>
                {adminData!.fullName}
              </span>
              <span className={styles.email}>{adminData!.email}</span>
            </span>
            <PermissionsGate scopes={[UserScopes.ChangeOrganizationAdmin]}>
              <Button
                size="small"
                aria-label="Replace Organization admin"
                color="secondary"
                variant="contained"
                onClick={onToggleChangeAdminModal}
              >
                Replace
              </Button>
            </PermissionsGate>
          </span>
        </InfoRow>
      </main>
    </div>
  );
};
