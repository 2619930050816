import React, { FC } from 'react';
import { Cell, Pie, PieChart as PieChartComponent, Tooltip } from 'recharts';

import styles from 'scss/core.module.scss';

interface Props {
  colors: string[];
  data: {
    name: string;
    value: number;
  }[];
}

const defaultData = [
  {
    name: '',
    value: 100
  }
];

export const PieChart: FC<Props> = ({ data, colors }) => {
  const hasData = data.find(({ value }) => value);

  if (!hasData) {
    return (
      <PieChartComponent width={60} height={60}>
        <Pie
          data={defaultData}
          innerRadius={17}
          outerRadius={29}
          dataKey="value"
        >
          <Cell fill={styles.SoftGray} />
        </Pie>
      </PieChartComponent>
    );
  }

  return (
    <PieChartComponent width={60} height={60}>
      <Pie
        data={data}
        innerRadius={17}
        outerRadius={29}
        fill="#8884d8"
        dataKey="value"
      >
        {data.map((entry, index) => (
          <Cell
            key={`cell-${entry.name}`}
            fill={colors[index % colors.length]}
          />
        ))}
      </Pie>
      <Tooltip
        active
        offset={-40}
        position={{ x: 60 }}
        contentStyle={{
          padding: '0 5px',
          backgroundColor: 'rgba(255, 255, 255, 0.8)'
        }}
      />
    </PieChartComponent>
  );
};
