import React, { FC } from 'react';
import { useMutation } from 'react-query';
import { updateTaskDetails } from 'actions/Task/taskActions';
import { Modal } from 'components/Modal';
import { StatusSnackBar } from 'components/StatusSnackBar';
import { ApiError } from 'entities/ApiError.entity';
import { Task } from 'entities/Task.entity';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { queryKeys } from 'enums/QueryKeys.enum';
import { useMessagesContext } from 'hooks/Auth/useMessagesContext';
import { useTasksContext } from 'hooks/Task/useTasksContext';
import { queryClient } from 'index';
import { FolderForm, FormData } from 'views/Library/FolderForm';

interface Props {
  isOpen: boolean;
  onCloseModal: () => void;
  data: Pick<
    Task,
    'id' | 'title' | 'description' | 'imageUrl' | 'repertoireId' | 'image'
  >;
}

export const EditFolderModal: FC<Props> = ({ isOpen, onCloseModal, data }) => {
  const { showSnackBar } = useMessagesContext();
  const { activeFolder, onChangeActiveFolder } = useTasksContext();

  const { isLoading, isError, isSuccess, error, mutateAsync } = useMutation<
    Task,
    ApiError,
    FormData
  >(
    (formData: FormData) =>
      updateTaskDetails(data.id, {
        title: formData.title,
        repertoireId: formData.repertoireId,
        description: formData.description || '',
        image: formData.image !== data.imageUrl ? formData.image : undefined
      }),
    {
      onSuccess: async (updatedTask) => {
        showSnackBar({
          isSuccess: true,
          successMessage: 'Folder successfully updated'
        });

        if (activeFolder?.id === updatedTask.id) {
          onChangeActiveFolder(updatedTask);
        }

        await queryClient.invalidateQueries(queryKeys.tasksList);
        await queryClient.invalidateQueries(queryKeys.folders);

        onCloseModal();
      }
    }
  );

  return (
    <>
      <StatusSnackBar
        isError={isError}
        isSuccess={isSuccess}
        successMessage="Folder successfully updated"
        errorMessage={error?.errorMessage || ErrorMessages.FailedPostRequest}
      />
      <Modal title="Edit folder" isOpen={isOpen} onCloseModal={onCloseModal}>
        <FolderForm
          onSubmit={mutateAsync}
          onCloseModal={onCloseModal}
          isLoading={isLoading}
          defaultValues={data}
          saveButtonText="Save"
        />
      </Modal>
    </>
  );
};
