import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { LoadingButton } from '@mui/lab';
import { Button, Grid, TextField } from '@mui/material';
import { FileUploader } from 'components/FileUploader';
import { ErrorMessages } from 'enums/ErrorMessages.enum';
import { useLoadAuthUserData } from 'hooks/Auth/useLoadAuthUserData';
import { isEmailValid, isUsernameValid } from 'utils/helpers/validators';

import styles from './AccountEditForm.module.scss';

interface Props {
  isLoading: boolean;
  onCloseModal: () => void;
  onSubmit: (formData: FormData) => void;
}

export interface FormData {
  firstName: string;
  lastName: string;
  email: string;
  username: string;
  phoneNumber?: string;
  image?: string | null;
}

export const AccountEditForm: FC<Props> = ({
  isLoading,
  onSubmit,
  onCloseModal
}) => {
  const {
    firstName,
    lastName,
    email,
    username,
    phoneNumber,
    avatar,
    avatarUrl = ''
  } = useLoadAuthUserData().data!;

  const {
    control,
    handleSubmit,
    formState: { errors, isDirty }
  } = useForm<FormData>({
    defaultValues: {
      firstName,
      lastName,
      email,
      username,
      phoneNumber,
      image: avatarUrl
    },
    mode: 'onSubmit',
    reValidateMode: 'onChange',
    shouldUseNativeValidation: false
  });

  return (
    <form
      className={styles['account-edit-form']}
      onSubmit={handleSubmit((formData) => onSubmit(formData))}
    >
      <Controller
        control={control}
        name="image"
        render={({ field: { onChange } }) => {
          return (
            <FileUploader
              fileData={avatar}
              label="Profile picture"
              onChangeFile={onChange}
            />
          );
        }}
      />
      <Controller
        rules={{ required: true }}
        name="firstName"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="text"
            variant="outlined"
            label="First name"
            margin="normal"
            inputRef={field.ref}
            error={!!errors?.firstName}
            helperText={errors?.firstName && ErrorMessages.FailedRequiredField}
            id="outlined-first-name-input"
          />
        )}
      />
      <Controller
        rules={{ required: true }}
        name="lastName"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="text"
            variant="outlined"
            label="Last name"
            margin="normal"
            error={!!errors?.lastName}
            aria-invalid={!!errors?.lastName}
            helperText={errors?.lastName && ErrorMessages.FailedRequiredField}
            id="outlined-last-name-input"
          />
        )}
      />
      <Controller
        rules={{
          validate: (value) => {
            if (!value) {
              return ErrorMessages.FailedRequiredField;
            }
            if (!isEmailValid(value)) {
              return ErrorMessages.NotValidEmail;
            }

            return true;
          }
        }}
        name="email"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="text"
            variant="outlined"
            label="Email"
            margin="normal"
            error={!!errors?.email}
            aria-invalid={!!errors?.email}
            helperText={errors?.email?.message}
            id="outlined-email-input"
          />
        )}
      />
      <Controller
        rules={{
          validate: (value) => {
            if (!value) {
              return ErrorMessages.FailedRequiredField;
            }
            if (!isUsernameValid(value)) {
              return ErrorMessages.NotValidUsername;
            }

            return true;
          }
        }}
        name="username"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="text"
            variant="outlined"
            label="Username"
            margin="normal"
            error={!!errors?.username}
            id="outlined-username-input"
            aria-invalid={!!errors?.username}
            helperText={errors?.username?.message}
          />
        )}
      />
      <Controller
        name="phoneNumber"
        control={control}
        render={({ field }) => (
          <TextField
            {...field}
            type="text"
            variant="outlined"
            label="Phone number"
            margin="normal"
            id="outlined-phone-number-input"
          />
        )}
      />
      <div className={styles.footer}>
        <Grid container alignItems="stretch" spacing={2}>
          <Grid item xs={6}>
            <LoadingButton
              fullWidth
              type="submit"
              color="primary"
              variant="contained"
              disabled={!isDirty}
              loading={isLoading}
            >
              Save
            </LoadingButton>
          </Grid>
          <Grid item xs={6}>
            <Button
              fullWidth
              color="primary"
              variant="outlined"
              onClick={onCloseModal}
            >
              Cancel
            </Button>
          </Grid>
        </Grid>
      </div>
    </form>
  );
};
