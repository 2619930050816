import React from 'react';
import { Column, Row } from 'react-table';
import { Checkbox, Chip, Grid } from '@mui/material';
import cn from 'classnames';
import { IconCell } from 'components/IconCell';
import { Learner } from 'entities/Learner.entity';
import { EntityStatus } from 'enums/EntityStatus.enum';

import { EmailAddressVerificationChip } from '../EmailAddressVerificationChip';
import { ResendVerificationEmailButton } from '../ResendVerificationEmailButton';

import styles from './LearnersTable.module.scss';

type TitleProps = {
  fullName: string;
  avatarUrl?: string | null;
  username: string;
  id: string;
};

const emailAddressVerificationChipTextOverride = {
  missing: {
    label: 'Missing email address',
    title: 'Missing email address'
  },
  unverified: {
    label: 'Unverified email address',
    title: 'Unverified email address'
  }
};

const EmailAlert: React.FC<{
  shouldVerifyEmailAddress: boolean;
  learner: Learner;
}> = ({
  shouldVerifyEmailAddress,
  learner: { id, email, isEmailVerified }
}) => {
  const isUnverified = shouldVerifyEmailAddress && email && !isEmailVerified;
  const isMissing = shouldVerifyEmailAddress && !email;
  const isVerified = shouldVerifyEmailAddress && email && isEmailVerified;

  return (
    <>
      {isUnverified && (
        <span className={styles['email-status']}>
          <EmailAddressVerificationChip
            iconOnly
            chipType="unverified"
            textOverride={emailAddressVerificationChipTextOverride}
          />
          <p title={email}>{email}</p>
          <ResendVerificationEmailButton userId={id} />
        </span>
      )}
      {isMissing && (
        <span className={styles['email-status']}>
          <EmailAddressVerificationChip
            chipType="missing"
            textOverride={emailAddressVerificationChipTextOverride}
          />
        </span>
      )}
      {isVerified && (
        <span className={styles['email-status']}>
          <EmailAddressVerificationChip
            iconOnly
            chipType="verified"
            textOverride={emailAddressVerificationChipTextOverride}
          />
          <p title={email}>{email}</p>
        </span>
      )}
    </>
  );
};

export const getColumns = (
  shouldVerifyEmailAddress: boolean
): Column<Learner>[] => [
  {
    id: 'fullName',
    Header: 'Full name',
    accessor: (row): TitleProps => ({
      fullName: row.fullName,
      avatarUrl: row.avatarUrl,
      username: row.username,
      id: row.id
    }),
    Cell: ({ value, row }: { value: TitleProps; row: Row<Learner> }) => (
      <Grid container>
        <Checkbox
          checked={row.isSelected}
          onChange={row.getToggleRowSelectedProps().onChange}
          inputProps={{
            'aria-label': 'Select learner'
          }}
        />
        <IconCell
          rounded
          title={value.fullName}
          subtitle={value.username}
          imageURL={value.avatarUrl}
          hasAvatarPlaceholder={false}
          navTo={`learners/${value.id}`}
        />
      </Grid>
    ),
    width: 3,
    minWidth: shouldVerifyEmailAddress ? 70 : 300
  },
  {
    id: 'emailAlerts',
    Header: 'Email',
    width: shouldVerifyEmailAddress ? 120 : 0,
    minWidth: shouldVerifyEmailAddress ? 120 : 0,
    Cell: ({ row }: { row: Row<Learner> }): React.ReactNode => (
      <EmailAlert
        learner={row.original}
        shouldVerifyEmailAddress={shouldVerifyEmailAddress}
      />
    )
  },
  {
    id: 'formattedLastSessionDate',
    Header: 'Last activity',
    accessor: 'formattedLastSessionDate',
    width: 1,
    minWidth: shouldVerifyEmailAddress ? 80 : 100
  },
  {
    id: 'dateCreated',
    Header: 'Date created',
    accessor: 'formattedCreatedAt',
    width: 1,
    minWidth: shouldVerifyEmailAddress ? 80 : 130
  },
  {
    id: 'status',
    Header: 'Status',
    accessor: 'status',
    Cell: ({ value }) => (
      <Chip
        size="small"
        label={value}
        className={cn(
          styles.chip,
          value === EntityStatus.Active && styles.primary
        )}
      />
    ),
    width: 1,
    minWidth: shouldVerifyEmailAddress ? 50 : 100
  }
];
