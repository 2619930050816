import { ActivityGroup } from 'entities/ActivityGroup.entity';
import { UserActivityDTO } from 'entities/DTO/UserActivityDTO.entity';
import { User } from 'entities/User.entity';
import { UserActivity } from 'entities/UserActivity.entity';
import * as queryString from 'query-string';

import { ApiBase } from '../ApiBase';

export type UserActivitiesGroupQuery = {
  fromTime: string;
  userId?: User['id'];
  limit?: number;
  offset?: number;
};

export type UserActivitiesQuery = {
  endTimeGt: string;
  endTimeLt: string;
  userIdEq: User['id'];
  limit?: number;
  offset?: number;
  sort: string[];
};

class UserActivitiesApi extends ApiBase {
  fetchUserActivities(activitiesQuery: UserActivitiesQuery) {
    const query = queryString.stringify(activitiesQuery, {
      arrayFormat: 'comma'
    });

    return this.client.get<UserActivity[]>(`/user-activities?${query}`);
  }

  fetchUserActivitiesGroup(activitiesQuery: UserActivitiesGroupQuery) {
    const query = queryString.stringify(activitiesQuery, {
      arrayFormat: 'comma'
    });

    return this.client.get<ActivityGroup[]>(`/user-activities/group?${query}`);
  }

  startUserActivity() {
    return this.client.post<UserActivity>(`/user-activities/sessions/start`);
  }

  stopUserActivity(
    sessionToken: string,
    activityDTO?: Partial<UserActivityDTO>
  ) {
    return this.client.post<UserActivity>(
      `/user-activities/sessions/${sessionToken}/finish`,
      activityDTO
    );
  }

  overrideUserActivity(
    sessionToken: string,
    activityDTO: Partial<UserActivityDTO>
  ) {
    return this.client.post<UserActivity>(
      `/user-activities/sessions/${sessionToken}/override`,
      activityDTO
    );
  }

  updateUserActivity(sessionToken: string) {
    return this.client.post<UserActivity>(
      `/user-activities/sessions/${sessionToken}/update`
    );
  }
}

const instance = new UserActivitiesApi('');

export { instance as UserActivitiesApi };
