import { UserRoles } from 'enums/UserRoles.enum';
import { UserTabs } from 'enums/UserTabs.enum';

const SuperAdminTabs: UserTabs[] = [];
const AdminTabs: UserTabs[] = [
  UserTabs.PersonalInformation,
  UserTabs.TimeSpent,
  UserTabs.TaskLibrary,
  UserTabs.Assessments
];
const ManagerTabs: UserTabs[] = [
  UserTabs.PersonalInformation,
  UserTabs.TimeSpent,
  UserTabs.Associations,
  UserTabs.TaskLibrary,
  UserTabs.Assessments
];
const FacilitatorTabs: UserTabs[] = [
  UserTabs.PersonalInformation,
  UserTabs.TimeSpent,
  UserTabs.Associations,
  UserTabs.TaskLibrary,
  UserTabs.Assessments
];
const PassiveFacilitatorTabs: UserTabs[] = [
  UserTabs.PersonalInformation,
  UserTabs.TimeSpent,
  UserTabs.Associations,
  UserTabs.TaskLibrary,
  UserTabs.Assessments
];
const LearnerTabs: UserTabs[] = [
  UserTabs.PersonalInformation,
  UserTabs.TimeSpent,
  UserTabs.Associations,
  UserTabs.TaskLibrary,
  UserTabs.Schedule,
  UserTabs.Assessments
];

export const userTabs = {
  [UserRoles.SuperAdmin]: new Set(SuperAdminTabs),
  [UserRoles.Admin]: new Set(AdminTabs),
  [UserRoles.Manager]: new Set(ManagerTabs),
  [UserRoles.Facilitator]: new Set(FacilitatorTabs),
  [UserRoles.PassiveFacilitator]: new Set(PassiveFacilitatorTabs),
  [UserRoles.Learner]: new Set(LearnerTabs)
};

export const isTabVisible = (role: UserRoles, tab: UserTabs): boolean =>
  userTabs[role].has(tab);
